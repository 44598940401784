import {Pipe, PipeTransform} from '@angular/core';
import {TableFooterService} from '@app/core-module/services/table-footer.service';

@Pipe({
  name: 'tableMessages'
})
export class TableMessagesPipe implements PipeTransform {
  constructor(private tableFooterService: TableFooterService) {
  }

  transform(value: any[] | number, args?: any): any {
    return this.tableFooterService.getMessage(typeof value === 'number' ? value : value.length, args);
  }

}

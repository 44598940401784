<address>
  <a *ngIf="linkOnName" [routerLink]="linkOnName">
    <strong>{{ bank.bank_name }}</strong>
  </a>
  <strong *ngIf="!linkOnName">{{ bank.bank_name }}</strong><br/>
  <a *ngIf="bank.email && !bank.email.startsWith('autogenerated-')" [href]="'mailto:' + bank.email">
    <fa-icon [classes]="['fa-fw']" [icon]="['far', 'envelope']"></fa-icon>
    {{ bank.email }}</a>
  <br/>
  <fa-icon [classes]="['fa-fw']" icon="store-alt"></fa-icon>
  {{ bank.fi.name }}
  <br/><br/>
  <shared-wire-transfer-entity-address [entity]="bank"></shared-wire-transfer-entity-address>
</address>
<div class="list-group">
  <div *ngIf="bank.bank_number" class="list-group-item bg-theme">
    <span class="text-muted">{{ 'admin.wire-transfer-bank-details: ID#' | translate }}</span>
    <span class="float-right text-purple-ionfi">{{ bank.bank_number }}</span>
  </div>
  <div *ngIf="bank.bank_number_type" class="list-group-item bg-theme">
    <span class="text-muted">{{ 'admin.wire-transfer-bank-details: ID Type' | translate }}</span>
    <span class="float-right text-purple-ionfi">{{ bank.bank_number_type | entityNumberType }}</span>
  </div>
</div>

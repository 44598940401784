<div *ngIf="currentProfile | async as u">
<nav class='navbar navbar-expand-lg fixed-top' [ngClass]="u.user.isAdminFI ? 'admin' : 'user'">
  <a class="navbar-brand" [routerLink]="u.user.isAdminFI ? '/admin' : '/fi'">
    <img style="height: 27px" class="ml-2" [src]="logoPath"/>
  </a>
  <button class="navbar-toggler text-light" type="button" (click)="toggleSidebar()">
    <fa-icon [classes]="['fa-fw']" icon="bars"></fa-icon>
  </button>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown" ngbDropdown>
        <a *ngIf="selectedLang | async as s" href="javascript:void(0)" class="nav-link" ngbDropdownToggle>
          <img class='fa-fw' [src]="s.language && s.flag"/>
        </a>
        <div class="dropdown-menu-right" ngbDropdownMenu>
          <a *ngFor="let lang of getAvailableLanguages()" class="dropdown-item" href="javascript:void(0)" (click)="setLanguage(lang.value)">
            {{ lang.text }}
          </a>
        </div>
      </li>
      <li class="toggle-switch mx-3">
        <div>
          <input type="checkbox" (click)="this.themeService.toggle()" class="checkbox" [checked]="themeService.isDarkThemeSelected()" id="chk" />
          <label class="label" for="chk">
            <span class="moon">🌙</span>
            <span class="sun">🔆</span>
            <div class="ball"></div>
          </label>
        </div>
      </li>
      <div class="nav-item" ngbDropdown>
        <a class="nav-link clickable" ngbDropdownToggle id="auth-dropdown">
          <fa-icon [classes]="['fa-fw']" icon="user-tie"></fa-icon>
          <span>{{ getNameForBar(u) }}</span>
          <b class="caret"></b>
        </a>
        <div class="dropdown-menu-right" ngbDropdownMenu="auth-dropdown">
          <a class="dropdown-item clickable" [routerLink]="['/profile/home']" ngbDropdownItem>
            <fa-icon [classes]="['fa-fw']" icon="user-tie"></fa-icon> {{ 'shared.authentication: My Profile' | translate }}
          </a>
          <a class="dropdown-item clickable" (click)="logout()" ngbDropdownItem>
            <fa-icon [classes]="['fa-fw']" icon="sign-out-alt"></fa-icon> {{ 'shared.authentication: Log out' | translate }}
          </a>
        </div>
      </div>
    </ul>
  </div>
</nav>
</div>


import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WireTransferForm } from "@shared/models/wire-transfer-form";
import { Router } from "@angular/router";
import { Transaction } from "@shared/models/transaction";
import {getAccountLink} from "@app/shared-module/utils/account.utils";
import {FinancialInstitution} from "@shared/models/financial-institution";
import {Observable} from "rxjs";
import {CurrentBankState} from "@app/core-module/states/current.bank.state";
import {Account} from "@shared/models/account";

@Component({
  selector: "transactions-account-details-description",
  templateUrl: "./transactions-account-details-description.component.html",
  styleUrls: ["./transactions-account-details-description.component.scss"]
})
export class TransactionsAccountDetailsDescriptionComponent implements OnInit {

  @Input()
  transaction: Transaction;

  @Input()
  enableDescriptionWireFormLink = true;

  @Input()
  isSegregatedAccountTable: boolean = false;

  @Input()
  isIonfiTable: boolean = false;

  @Input()
  accountLink: string;

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter<void>();

  isAdmin: boolean;

  currentFI: Observable<FinancialInstitution>;
  constructor(private router: Router,
              private currentBankState: CurrentBankState) {
    this.currentFI = this.currentBankState.getCurrentBank();
  }

  ngOnInit() {
    this.isAdmin = this.router.url.includes("/admin");
  }

  getrouterLinkForWireTransferForm(wireTransferForm: WireTransferForm) {
    return wireTransferForm.directionType === "INCOMING" ? `/${this.isAdmin ? "admin/wires" : "fi/wires-dashboard"}/wire-transfer/incoming/${wireTransferForm.id}` : `/${this.isAdmin ? "admin/wires" : "fi/wires-dashboard"}/wire-transfer/outgoing/${wireTransferForm.id}`;
  }

  emitCloseModal() {
    this.closeModal.emit();
  }

  getAccountLink(transaction: Transaction): string{
    return getAccountLink(transaction, this.isAdmin, this.isSegregatedAccountTable, this.isIonfiTable, true);
  }
  canSeeAccountLink(account: Account, currentFi: FinancialInstitution) {
    return (this.isAdmin || account.fi_id === currentFi.id);
  }
}

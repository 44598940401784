import {RDC} from "../models/rdc";

interface FeeConfigurationItem {
    upTo: number;
    fee: number;
}

export interface FeeConfiguration {
    intervals: FeeConfigurationItem[];
    defaultFee: number;
}

function trimCheckAndParseAsFloat(number: string): number {
    if (number.indexOf(',') > -1) throw new Error('number with , is not valid');
    let result = parseFloat(number.trim());
    if (typeof (result) !== 'number') throw new Error('invalid number');
    return result;
}

function parseConfigurationItem(configurationItem: string): FeeConfigurationItem {
    let items = configurationItem.split(':');

    let left = items[0].trim();
    let right = items[1].trim();
    if (!left.startsWith('<=')) throw new Error('invalid string');
    return <FeeConfigurationItem>{
        upTo: trimCheckAndParseAsFloat(left.substr(2, left.length)),
        fee: trimCheckAndParseAsFloat(right)
    }
}

export function parseFeeConfiguration(configuration: string | number): FeeConfiguration {
    let intervals: FeeConfigurationItem[] = [];
    if (!configuration) {
        return <FeeConfiguration>{
            intervals: intervals,
            defaultFee: 0
        }
    }

    configuration = configuration + '';
    let volumeFeeIntervalsSplit = configuration.split(';');
    for (let i = 0; i < volumeFeeIntervalsSplit.length - 1; i++) {
        intervals.push(parseConfigurationItem(volumeFeeIntervalsSplit[i].trim()));
    }
    let defaultFee = trimCheckAndParseAsFloat(volumeFeeIntervalsSplit[volumeFeeIntervalsSplit.length - 1]);
    if (!defaultFee) throw new Error('invalid fee');
    intervals.sort(it => it.upTo);
    return <FeeConfiguration>{
        intervals: intervals,
        defaultFee: defaultFee
    }
}

export function getVolumeFeeForRdc(volumeFeeIntervals: string, rdc: RDC): number {
    let configuration = parseFeeConfiguration(volumeFeeIntervals);
    for (let interval of configuration.intervals.sort(it => it.upTo)) {
        if (rdc.check_amount <= interval.upTo) return interval.fee;
    }
    return configuration.defaultFee;
}
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {FinancialInstitution} from "@shared/models/financial-institution";

export class AbstractBankModalComponent {

  formGroup: FormGroup;

  protected initForm(initialData: FinancialInstitution) {
    this.formGroup = new FormGroup({
      name: new FormControl(initialData ? initialData.name : '', [
        Validators.required,
      ]),
      address: new FormControl(initialData ? initialData.address : ''),
      city: new FormControl(initialData ? initialData.city : ''),
      zip: new FormControl(initialData ? initialData.zip : ''),
      country: new FormControl(initialData ? initialData.country : ''),
      state: new FormControl(initialData ? initialData.state : ''),
      email: new FormControl(initialData ? initialData.email : '', [
        (c: AbstractControl) => {
          if (!c.value) return null;
          return Validators.email(c);
        }
      ]),
      website: new FormControl(initialData ? initialData.website : '', [
        Validators.pattern('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')
      ]),
    });
  }

  protected addFormValues(bank: FinancialInstitution) {
    bank.name = this.formGroup.value['name'];
    bank.address = this.formGroup.value['address'];
    bank.city = this.formGroup.value['city'];
    bank.zip = this.formGroup.value['zip'];
    bank.country = this.formGroup.value['country'];
    bank.state = this.formGroup.value['state'];
    bank.email = this.formGroup.value['email'];
    bank.website = this.formGroup.value['website'];
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {download} from '@app/shared-module/utils/utils';
import {Account} from '@shared/models/account';

@Component({
  selector: 'shared-wire-transfer-editing-form',
  templateUrl: './wire-transfer-editing-form.component.html',
  styleUrls: ['./wire-transfer-editing-form.component.scss']
})
export class WireTransferEditingFormComponent implements OnInit {

  @Input()
  form: FormGroup;

  @Input()
  selectedAccount: Account;

  @Input()
  fi_id?: number;

  @Input()
  isThirdPartyTransfer: boolean;

  @Input()
  isSegregateAccountTransfer: boolean;

  files: File[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  handleSelectedFiles(files: File[]) {
    this.files.push(...files);
    this.form.get('documents').patchValue(this.files);
  }

  removeFile(file: File) {
    this.files = this.files.filter(it => it !== file);
    this.form.get('documents').patchValue(this.files);
  }

  downloadFile(file: File) {
    download(file.name, file);
  }
}

import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "@app/core-module/services/authentication.service";
import {CurrentProfileState} from "@app/core-module/states/current-profile-state.service";
import {Profile} from "@shared/models/profile";
import {isToggled, toggleSidebar} from "@app/shared-module/utils/sitebar.utils";
import {Observable} from "rxjs";

@Component({
  selector: 'shared-sidebar-user',
  templateUrl: './sidebar-user.component.html',
  styleUrls: ['./sidebar-user.component.scss']
})
export class SidebarUserComponent implements OnInit {

  showMenu: string = '';

  public currentProfile: Observable<Profile>;

  constructor(private authenticationService: AuthenticationService,
              private currentProfileState: CurrentProfileState) {
    this.currentProfile = currentProfileState.getCurrentProfile();
  }

  ngOnInit() {
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  logout() {
    if (isToggled()) {
      toggleSidebar();
    }
    this.authenticationService.logout();
  }

}

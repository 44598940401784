<button class="btn btn-sm text-purple-ionfi edit-icon" (click)="open(content)">
  <span class="ico icon-pen" ngbTooltip="{{ 'shared.edit: Edit' | translate }}"></span>
</button>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'shared.update-user-modal: Update User' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]="formGroup" class="custom-select_arrow-ionfi" (validSubmit)="c()" role="form">
    <div class="modal-body">
      <div class="form-group">
        <label>{{'shared.update-user-modal: Email' | translate}}</label><span class="text-purple-ionfi">*</span>
        <input [disabled]="true" class="form-control" [value]="user.email">
      </div>
      <ng-container *ngIf="isIonfi">
        <div class="form-group custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="includeInMailingLists"
                 formControlName="includeInMailingLists">
          <label class="custom-control-label"
                 for="includeInMailingLists">{{ 'shared.update-user-modal: Include in Mailing Lists' | translate }}</label>
        </div>
        <div class="form-group custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="isPaymentSupervisor"
                 formControlName="isPaymentSupervisor">
          <label class="custom-control-label"
                 for="isPaymentSupervisor">{{ 'shared.update-user-modal: Payment Supervisor' | translate }}</label>
        </div>
      </ng-container>
      <div class="form-group">
        <label>{{'shared.update-user-modal: First Name' | translate}}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="firstName">
      </div>
      <div class="form-group .mb-10">
        <label>{{'shared.update-user-modal: Last Name' | translate}}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="lastName">
      </div>
      <div class="form-group form-group-date d-flex flex-column">
        <label>{{ 'shared.update-user-modal: Date of Birth' | translate }}</label>
        <mat-form-field floatLabel="never">
          <input formControlName="dateOfBirth" matInput [matDatepicker]="dateOfBirth"
                 placeholder="{{'shared.update-user-modal: Choose a date' | translate}}" [readonly]="true">
          <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirth></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-group">
        <label>{{ 'shared.update-user-modal: Office phone number' | translate }}</label>
        <input class="form-control" formControlName="officePhoneNumber">
      </div>
      <div class="form-group">
        <label>{{ 'shared.update-user-modal: Cellphone number' | translate }}</label>
        <input class="form-control" formControlName="cellphoneNumber">
      </div>
      <div class="form-group">
        <label>{{ 'shared.update-user-modal: Position in company' | translate }}</label>
        <input class="form-control" formControlName="positionInCompany">
      </div>
      <div class="form-group">
        <label>{{'shared.update-user-modal: Type' | translate}}</label><span class="text-purple-ionfi">*</span>
        <select class="form-control" type="select" formControlName="type">
          <option [ngValue]="null">{{ 'shared.update-user-modal: Select' | translate }}</option>
          <option *ngFor="let type of (types | async)" [ngValue]="type">{{ type.type }}</option>
        </select>
        <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
      </div>
      <div class="form-group">
        <label>{{ 'shared.update-user-modal: Scanner IP address' | translate }}</label>
        <input class="form-control" formControlName="scannerIp">
      </div>
      <div class="form-group">
        <label>{{ 'shared.update-user-modal: Scanner Port' | translate }}</label>
        <input class="form-control" type="number" formControlName="scannerPort">
      </div>
      <div class="form-group custom-control custom-checkbox" *ngIf="canChangeScanThroughBrowser">
        <input type="checkbox" class="custom-control-input" id="scanThroughBrowser"
               formControlName="scanThroughBrowser">
        <label class="custom-control-label"
               for="scanThroughBrowser">{{ 'shared.update-user-modal: Scan Through Browser' | translate }}</label>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" type="submit"
              class="btn btn-primary purple-button">{{ 'shared.update-user-modal: Update' | translate }}</button>
    </div>
  </form>
</ng-template>


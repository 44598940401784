export const RDC_ACTIVITY_STATUSES = [
    'Approved', 'Settlement Pending', 'X9 Batch', 'Confirmed', 'Settlement Rejected'
]

export const RDC_ACTIVITY_FI_USER_STATUSES = [
    "Pending", "Data Review", "X9 Batch", "Settlement Pending", "Approved", "Info Required", "Confirmed", "Rejected", "Settlement Rejected"
]

export const SCANNING_QUEUE_FI_USER_STATUSES = [
    'Not Processed', 'Excel Populated', 'Not Processed e-check'
]

export const RDC_COMPLIANCE_STATUSES = [
    'Data Review', 'Pending', 'Info Required', 'Approved', 'Rejected'
]

export const SCANNING_QUEUE_STATUSES = [
    'Not Processed', 'Excel Populated', 'Not Processed e-check'
]

export const OUTGOING_COMPLIANCE_WIRE_TRANSFER_FORM_STATUSES = [
    "Info Required",
    "Pending KYC",
    "Pending IDM",
    "Pending Hawk:AI"
]

export const OUTGOING_ACTIVITY_WIRE_TRANSFER_FORM_STATUSES = [
    "Pending Ionfi",
    "Processed",
    "In Batch Ready to Upload",
    "Uploaded to BCB",
    "Processed by BCB",
    "Rejected",
    "Discarded",
    "Pending Braid"
]

export const INCOMING_COMPLIANCE_WIRE_TRANSFER_FORM_STATUSES = [
    "Ultimate Beneficiary Required",
    "Pending KYC",
    "Pending IDM",
    "Info Required",
    "Pending Hawk:AI"
]

export const INCOMING_FI_USER_STATUSES = [
    "Ultimate Beneficiary Required",
    "Pending Approval from Ionfi",
    "Info Required",
    "Processed",
    "Rejected"
]

export const OUTGOING_FI_USER_STATUSES = [
    "Pending Second Approval",
    "Pending Approval from Ionfi",
    "Info Required",
    "Processed",
    "Rejected",
    "Discarded"
]

export const INCOMING_ACTIVITY_WIRE_TRANSFER_FORM_STATUSES = [
    "Created",
    "Pending Ionfi",
    "Processed",
    "Rejected"
]

export const PROFILE_WIRE_STATUSES = [
    "Pending",
    "Complete"
];
export type PROFILE_WIRE_STATUSES_TYPE = 'Pending' | 'Complete';

export const PROFILE_CONFIRMED_STATUSES = [
    "Confirmed",
    "Not Confirmed"
]
export type PROFILE_CONFIRMED_STATUSES_TYPE = "Confirmed" | "Not Confirmed";

export const PERSON_TYPES = [ "RDC_ONLY", "FULL_PROFILE", "NON_CUSTOMER"];
export type PERSON_TYPES_TYPE = "RDC_ONLY" | "FULL_PROFILE" | "NON_CUSTOMER";

export const ENTITY_TYPES = [ "LEGAL_ENTITY", "INDIVIDUAL"];
export type ENTITY_TYPES_TYPE = "LEGAL_ENTITY" | "INDIVIDUAL";

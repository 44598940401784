<ng-container *ngIf="form | async as formGroup">
<button class="btn btn-sm {{buttonType}}" (click)="open(content, formGroup)" ngbTooltip="{{tooltip}}">
  <fa-icon [classes]="['fa-fw']" icon="{{icon}}"></fa-icon>
</button>
<ng-template #content let-c="close" let-d="dismiss">
  <form [formGroup]="formGroup" (validSubmit)="c()">
    <div class="modal-header">
      <h4 class="modal-title">{{'shared.return-fee-modal: Reverse Transaction' | translate}} {{ transaction.identifier }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="comment">{{'shared.return-fee-modal: Comment' | translate}}:</label>
        <textarea class="form-control" rows="8" id="comment" formControlName="comment"></textarea>
      </div>
      <div class="form-group" *ngIf="reverseFeeTypes">
        <label for="comment">{{'shared.return-fee-modal: Reverse Fee Type' | translate}}:</label>
        <select class="form-control" type="select" formControlName="reverseFeeType">
          <option [ngValue]="null">{{'shared.return-fee-modal: Select' | translate}}</option>
          <option *ngFor="let feeType of (reverseFeeTypes | async)" [ngValue]="feeType.type">{{ feeType.type }}</option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-light" (click)="d()"><fa-icon [classes]="['fa-fw']" icon="times"></fa-icon>{{'shared.return-fee-modal: Cancel' | translate}}</button>
      <button type="submit" class="btn {{buttonType}}"><fa-icon [classes]="['fa-fw']" icon="{{icon}}"></fa-icon> {{ submitLabel }}</button>
    </div>
  </form>
</ng-template>
</ng-container>

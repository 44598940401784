import {FiType} from "./fi-type";
import {DbModel} from "./db.model";
import {BraidProduct} from './braid/product';
import {BraidProductDb} from './braid-product-db';

export class FinancialInstitution extends DbModel {

    name: string;
    fi_type_id: number;
    address: string;
    city: string;
    zip: string;
    country: string;
    state: string;
    email: string;
    website: string;
    tfa_mandatory: boolean;
    only_confirmed_customers: boolean;
    minimal_monthly_fee: number;
    can_create_segregate_account: boolean;

    //generated fields
    fiType: FiType;
    braidProducts: BraidProductDb[]
}

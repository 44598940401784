<address *ngIf="currentProfile | async as cu">
  <strong>
    <a *ngIf="linkOnName " [routerLink]="linkOnName">
      {{ person | personName }}
    </a>
    <a *ngIf="!linkOnName" [routerLink]="'/fi/kyc-dashboard/customers/profiles/' + person.kyc_profile_id">
      <span>{{ person | personName }}</span>
    </a>
  </strong>
  &nbsp;|&nbsp;&nbsp;{{person.person_type | personTypePipe}}&nbsp;&nbsp;|&nbsp;&nbsp;{{person.entityType | entityTypePipe}}
  <br/>
  <a *ngIf="person.email" [href]="'mailto:' + person.email">
    <fa-icon [classes]="['fa-fw']" [icon]="['far', 'envelope']"></fa-icon>
    {{ person.email }}</a>
  <div *ngIf="showFinantialInstitution">
    <br/>
    <fa-icon [classes]="['fa-fw']" icon="store-alt"></fa-icon>
    <a *ngIf="isAdmin" [routerLink]="['/admin/fi', person.fi.id]">{{ person.fi.name }}</a>
    <span *ngIf="!isAdmin">{{ person.fi.name }}</span>
  </div>
  <br/><br/>
  <shared-wire-transfer-entity-address [entity]="person"></shared-wire-transfer-entity-address>
</address>
<div class="list-group">
  <div *ngIf="person.id_number">
    <span class="text-dark-9-opacity weight-500">{{ 'shared.person-details: ID#' | translate }}</span>
    <span class="float-right text-dark-9-opacity">{{ person.id_number }}</span>
  </div>
  <div *ngIf="person.id_type_id">
    <span class="text-dark-9-opacity weight-500">{{ 'shared.person-details: ID Type' | translate }}</span>
    <span class="float-right text-dark-9-opacity">({{ person.id_type_id | idTypeCode | async }})
      {{ person.id_type_id | idTypeLabel | async }}</span>
  </div>
  <div *ngIf="person.date_of_birth">
    <span class="text-dark-9-opacity weight-500">{{ 'shared.person-details: Date of Birth' | translate }}</span>
    <span class="float-right text-dark-9-opacity">{{ person.date_of_birth | date }}</span>
  </div>
  <div *ngIf="person.country_of_birth">
    <span class="text-dark-9-opacity weight-500">{{ 'shared.person-details: Place of Birth' | translate }}</span>
    <span class="float-right text-dark-9-opacity">{{ person.country_of_birth | country | async }}</span>
  </div>
  <div *ngIf="person.residency">
    <span class="text-dark-9-opacity weight-500">{{ 'shared.person-details: Residency' | translate }}</span>
    <span class="float-right text-dark-9-opacity">{{ person.residency | country | async }}</span>
  </div>
  <div *ngIf="person.citizenship">
    <span class="text-dark-9-opacity weight-500">{{ 'shared.person-details: Citizenship' | translate }}</span>
    <span class="float-right text-dark-9-opacity">{{ person.citizenship | country | async }}</span>
  </div>
  <div *ngIf="person.date_of_incorporation">
    <span
      class="text-dark-9-opacity font-weight-bold">{{ 'shared.organisation-details: Date of Incorporation' | translate }}</span>
    <span class="float-right text-dark-9-opacity">{{ person.date_of_incorporation | date }}</span>
  </div>
  <div *ngIf="person.country_of_incorporation">
    <span
      class="text-dark-9-opacity font-weight-bold">{{ 'shared.organisation-details: Country of Incorporation' | translate }}</span>
    <span class="float-right text-dark-9-opacity">{{ person.country_of_incorporation | country | async }}</span>
  </div>
  <div *ngIf="person.org_type_id">
    <span
      class="text-dark-9-opacity font-weight-bold">{{ 'shared.person-details: Organization Type' | translate }}</span>
    <span class="float-right text-dark-9-opacity">{{ person.org_type_id | orgTypeName | async }}</span>
  </div>
  <div *ngIf="person.account_number_a_client">
    <span
      class="text-dark-9-opacity font-weight-bold">{{ 'shared.person-details: Account Number a Client' | translate }}</span>
    <span class="float-right text-dark-9-opacity">{{ person.account_number_a_client }}</span>
  </div>
  <div *ngIf="person.phone_number">
    <span
      class="text-dark-9-opacity font-weight-bold">{{ 'shared.person-details: Phone Number' | translate }}</span>
    <span class="float-right text-dark-9-opacity">{{ person.phone_number }}</span>
  </div>
  <div *ngIf="person.isFI">
    <span
      class="text-dark-9-opacity font-weight-bold">{{ 'shared.person-details: is FI' | translate }}</span>
    <span class="float-right text-dark-9-opacity">{{ person.isFI? 'Yes' : 'No' }}</span>
  </div>

  <div class="mb-4"></div>
  <div *ngIf="person.rdcs && person.rdcs.length > 0">
    <span class="text-dark-9-opacity font-weight-bold">{{ 'shared.person-details: RDCs where payee:' | translate }}</span>
    <span *ngFor="let rdc of person.rdcs" class="float-right text-dark-9-opacity">
        &nbsp;<a
      [routerLink]="isAdmin ? '/admin/rdc/rdc-activity/'+ rdc.id : '/fi/rdc-dashboard/rdc-activity/'+ rdc.id"> {{ rdc.check_number ? rdc.check_number : rdc.id }} </a>
      </span>
    <br>
  </div>
  <div *ngIf="person.wires_as_beneficiary && person.wires_as_beneficiary.length > 0">
    <span class="text-dark-9-opacity font-weight-bold">
      {{ 'shared.person-details: Wires where beneficiary:' | translate }}
    </span>
    <span *ngFor="let wire of person.wires_as_beneficiary" class="float-right text-dark-9-opacity">
        &nbsp;<a [routerLink]="getWiresPath(isAdmin, wire)"> {{ wire.id }} </a>
      </span>
  </div>
  <div *ngIf="person.wires_as_ultimate_beneficiary && person.wires_as_ultimate_beneficiary.length > 0">
    <span class="text-dark-9-opacity font-weight-bold">
      {{ 'shared.person-details: Wires where ultimate beneficiary:' | translate }}
    </span>
    <span *ngFor="let wire of person.wires_as_ultimate_beneficiary" class="float-right text-dark-9-opacity">
        &nbsp;<a [routerLink]="getWiresPath(isAdmin, wire)"> {{ wire.id }} </a>
      </span>
  </div>
  <div *ngIf="person.wires_as_originator && person.wires_as_originator.length > 0">
    <span class="text-dark-9-opacity font-weight-bold">
      {{ 'shared.person-details: Wires where originator:' | translate }}
    </span>
    <span *ngFor="let wire of person.wires_as_originator" class="float-right text-dark-9-opacity">
        &nbsp;<a [routerLink]="getWiresPath(isAdmin, wire)"> {{ wire.id }} </a>
      </span>
  </div>
</div>

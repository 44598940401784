<div [ngClass]="{'row justify-content-md-left' : fiFormInput }" class=" form-group" [formGroup]="formGroup" *ngIf="currenciesObservable | async as currencies">
  <label for="currency" [ngClass]="{'col-sm-3 text-dark-7-opacity col-form-label col-form-label-sm text-right' : fiFormInput }">
    <label>{{ labelText }} {{fiFormInput | json}}<span class="text-purple-ionfi">{{importantAsteriks}}</span></label>
  </label>
  <div [ngClass]="{'col-md-3' : fiFormInput }" class="custom-select_arrow-ionfi">
  <select class="form-control" [formControlName]="controlName" id="currency">
    <option *ngFor="let currencyData of currencies" [value]="currencyData.value">{{ currencyData.value }}
      - {{ currencyData.currencyName }}</option>
  </select>
  <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
  </div>
</div>

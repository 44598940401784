import {Injectable} from "@angular/core";
import {AdminApiService} from "@app/admin-module/services/admin.api.service";
import {RxUtilsService} from "@app/core-module/services/rx-utils.service";
import {Balance} from "@shared/models/balance";
import {Observable} from "rxjs";
import {distinctUntilChanged, map, merge} from "rxjs/operators";
import {Subject} from "rxjs";
import {AllAccountsState} from "./all-accounts.state";

@Injectable()
export class AllBalancesState {

  private readonly balances: Observable<Balance[]>;
  private readonly balancesWithKycProfileId: Observable<Balance[]>;
  private readonly balancesNoKycProfileId: Observable<Balance[]>;

  private reading: Observable<boolean>;
  private readingWithKycProfileId: Observable<boolean>;
  private readingNoKycProfileId: Observable<boolean>;

  private readBalances$ = new Subject();


  constructor(private adminApiService: AdminApiService,
              private allAccountsState: AllAccountsState,
              private rxUtilsService: RxUtilsService) {


    const initiator = this.allAccountsState.getAccounts().pipe(merge(this.readBalances$));
    this.balances = this.rxUtilsService.createGetStateValue(initiator, this.adminApiService.getAllBalances('all'),
      'Error while reading balances!', []);
    this.balancesWithKycProfileId = this.rxUtilsService.createGetStateValue(initiator, this.adminApiService.getAllBalances('yes'),
      'Error while reading balances!', []);
    this.balancesNoKycProfileId = this.rxUtilsService.createGetStateValue(initiator, this.adminApiService.getAllBalances('no'),
      'Error while reading balance!', []);
    this.reading = this.rxUtilsService.createReadingInfo(initiator, this.balances);
    this.readingWithKycProfileId = this.rxUtilsService.createReadingInfo(initiator, this.balancesWithKycProfileId);
    this.readingNoKycProfileId = this.rxUtilsService.createReadingInfo(initiator, this.balancesNoKycProfileId);
  }

  getBalances(): Observable<Balance[]> {
    return this.balances;
  }

  getIonfiBalances(): Observable<Balance[]> {
    return this.balancesNoKycProfileId.pipe(
      map(balances => balances.filter(it => (it.acc_type === 'Wire' && !it.mirror_transactions) || it.fi.fiType.type === 'Admin'))
    );
  }

  getFIBalances(): Observable<Balance[]> {
    return this.balancesNoKycProfileId.pipe(
      map(balances => balances.filter(it => !(it.acc_type === 'Wire' && !it.mirror_transactions) && it.fi.fiType.type === 'Bank'))
    );
  }

  getBalancesWithKycProfileId(): Observable<Balance[]> {
    return this.balancesWithKycProfileId;
  }

  getReadingBalances(): Observable<boolean> {
    return this.reading.pipe(distinctUntilChanged());
  }

  getReadingBalancesWithKycProfileId(): Observable<boolean> {
    return this.readingWithKycProfileId.pipe(distinctUntilChanged());
  }

  getReadingBalancesNoKycProfileId(): Observable<boolean> {
    return this.readingNoKycProfileId.pipe(distinctUntilChanged());
  }

  reload() {
    this.readBalances$.next(true);
  }
}

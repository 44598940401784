import {DbModel} from "./db.model";
import {FinancialInstitution} from "./financial-institution";
import {KycProfile} from "./kyc-profile";

export class WireTransferBank extends DbModel {
    bank_number_type: 'ABA_ROUTING_NUMBER' | 'DDA_NUMBER' | 'SWIFT_CODE' | 'SORT_CODE' | 'BANK_CODE' | 'OTHER';
    bank_number: string;
    bank_name: string;
    email: string;
    address: string;
    city: string;
    zip: string;
    state: string;
    country: string;
    kyc_profile_id: number;
    fi_id: number;
    master: boolean;

    //generated fields
    kycProfile: KycProfile;
    hasPendingWFs: number;
    fi: FinancialInstitution;
    numberOfRecords: number;
}

export class WireTransferBankWithAddressLines extends WireTransferBank {
    bank_address_line_1: string;
    bank_address_line_2: string;
    bank_address_line_3: string;
}

<shared-loading-bar [value]="progressBar" *ngIf="working"></shared-loading-bar>
<div>
  <form [formGroup]="formGroup">
    <div *ngIf="question.type_of_question === 'text'">
      <div formArrayName="main">
        <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
        <div *ngFor="let con of getFormArray('main')?.controls; let i = index" class="mb-3">
          <input class="form-control" type="text" formControlName="{{i}}">
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'textarea'">
      <div formArrayName="main">
        <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
        <div *ngFor="let con of getFormArray('main')?.controls; let i = index" class="mb-3">
          <textarea class="form-control" rows="5" type="text" formControlName="{{i}}"></textarea>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'amount'">
      <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
      <div *ngFor="let con of getFormArray('main')?.controls; let i = index">
        <div class="input-group mb-3" formArrayName="main">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="number" class="form-control" formControlName="{{i}}">
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'country'">
      <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
      <div *ngFor="let con of getFormArray('main')?.controls; let i = index">
        <shared-inputs-country [countryControlName]="i"
                               [formArrayName]="'main'"
                               [form]="formGroup"
                               [countryLabel]="null"></shared-inputs-country>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'select'">
      <div formArrayName="main">
        <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
        <select class="form-control" type="select" formControlName="{{0}}">
          <option [ngValue]="null">Select</option>
          <option *ngFor="let ans of question.offered_answers" [ngValue]="ans">{{ans}}</option>
        </select>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'radio'">
      <div formArrayName="main">
        <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
        <div *ngFor="let ans of question.offered_answers">
          <span>
            <input class="form-radio" type="radio" value="{{ans}}" formControlName="{{0}}">
            <label class="form-custom-label">{{ans}}</label>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'file'">
      <div>
        <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
        <shared-files-upload (uploadFiles)="fileUpload('main', $event)" customstyle="filedrop"></shared-files-upload>
        <div class="mt-4">
          <div *ngFor="let file of getFilesForUpload('main'); let i = index; last as last" [ngClass]="{'last-item-border-bottom': last}" class="d-flex justify-content-between">
            <span class="text-purple-ionfi text-14">{{ file.name }}</span>
            <button class="btn btn-sm edit-icon h5" (click)="removeFile('main', file)">
              <span class="ico icon-trashcan"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'check'">
      <div formArrayName="main">
        <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
        <div *ngFor="let ans of question.offered_answers; let i=index">
          <span>
            <input class="form-checkbox" type="checkbox" value="{{ans}}" formControlName="{{i}}">
            <label class="form-custom-label">{{ans}}</label>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'slider'">
      <div formArrayName="main">
        <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
        <span class="d-flex align-items-center">
          <label class="switch">
            <input type="checkbox" formControlName="{{0}}">
            <span class="slider round"></span>
          </label>
          <span class="ml-2"
                *ngIf="question.offered_answers[0]">{{question.offered_answers[0]}}</span>
        </span>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'contact'">
      <div formArrayName="main">
        <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
        <div *ngFor="let con of getFormArray('main')?.controls; let i = index" class="mb-3">
          <div formGroupName="{{i}}">
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <label class="input-group-text col-12">{{'shared.question: Type' | translate}}</label>
              </div>
              <select class="custom-select" formControlName="type">
                <option [ngValue]="null" selected disabled>{{'shared.question: Enter Type' | translate}}...</option>
                <option [ngValue]="'Financial Institution'">{{'shared.question: Financial Institution' | translate}}</option>
                <option [ngValue]="'Individual Person'">{{'shared.question: Individual Person' | translate}}</option>
                <option [ngValue]="'Non-Individual Person'">{{'shared.question: Non-Individual Person' | translate}}</option>
              </select>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question: Name' | translate}}</span>
              </div>
              <input type="text" class="form-control" formControlName="name">
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question: Email' | translate}}</span>
              </div>
              <input type="text" class="form-control" formControlName="email">
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question: Phone' | translate}}</span>
              </div>
              <input type="text" class="form-control" formControlName="phone">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'address'">
      <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
      <div *ngFor="let con of getFormArray('main')?.controls; let i = index" class="mb-3">
        <div formArrayName="main">
          <div formGroupName="{{i}}">
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question: Street' | translate}}</span>
              </div>
              <input type="text" class="form-control" formControlName="street">
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question: City' | translate}}</span>
              </div>
              <input type="text" class="form-control" formControlName="city">
            </div>
          </div>
        </div>
        <shared-inputs-country [countryControlName]="'country'"
                               [stateControlName]="'state'"
                               [form]="formGroup"
                               [formArrayName]="'main'"
                               [groupName]="i"
                               [showStateInput]="true"
                               [formGroupClass]="false">
        </shared-inputs-country>
        <div formArrayName="main">
          <div formGroupName="{{i}}">
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question: Zip' | translate}}</span>
              </div>
              <input type="text" class="form-control" formControlName="zip">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'date'">
      <div formArrayName="main">
        <label class="col-form-label">{{question.question}}<span *ngIf="question.mandatory">*</span></label>
        <div *ngFor="let con of getFormArray('main')?.controls; let i = index" class="mb-3">
          <mat-form-field floatLabel="never">
            <input class="form-control" matInput formControlName="{{i}}" [matDatepicker]="dateInput"
                   placeholder="{{'shared.question: Choose a date' | translate}}">
            <mat-datepicker-toggle matSuffix [for]="dateInput"></mat-datepicker-toggle>
            <mat-datepicker #dateInput disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'title'">
      <label class="font-weight-bold title-size">{{question.question}}</label>
    </div>
    <div *ngIf="question.type_of_question === 'label'">
      <label class="font-italic">{{question.question}}</label>
    </div>
    <div class="d-flex justify-content-end">
      <button *ngIf="question.allow_multiple_answers && getFormArray('main')?.controls.length > 1"
              class="btn btn-sm btn-light"
              (click)="removeLastAnswerForMain()">
        <fa-icon [classes]="['fa-fw']" icon="trash-alt"></fa-icon>
      </button>
      <button class="btn btn-light btn-sm" *ngIf="question.allow_multiple_answers" (click)="addAnswerForMain()">
        <fa-icon [classes]="['fa-fw']" icon="plus"></fa-icon>
      </button>
    </div>

    <div *ngFor="let sub of question.sub_questions">
      <div *ngIf="sub.type_of_question === 'text'">
        <div formArrayName="{{sub.id}}">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
          <div *ngFor="let con of getFormArray(sub.id)?.controls; let i = index" class="mb-3">
            <input class="form-control" type="text" formControlName="{{i}}">
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'textarea'">
        <div formArrayName="{{sub.id}}">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
          <div *ngFor="let con of getFormArray(sub.id)?.controls; let i = index" class="mb-3">
            <textarea class="form-control" rows="5" type="text"
                      formControlName="{{i}}"></textarea>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'amount'">
        <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
        <div *ngFor="let con of getFormArray(sub.id)?.controls; let i = index">
          <div class="input-group mb-3" formArrayName="{{sub.id}}">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input type="number" class="form-control" formControlName="{{i}}">
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'country'">
        <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
        <div *ngFor="let con of getFormArray(sub.id)?.controls; let i = index">
          <shared-inputs-country [formArrayName]="sub.id"
                                 [countryControlName]="i"
                                 [countryLabel]="null" [form]="formGroup"></shared-inputs-country>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'select'">
        <div formArrayName="{{sub.id}}">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
          <select class="form-control" type="select" formControlName="{{0}}">
            <option [ngValue]="null" selected>Select</option>
            <option *ngFor="let ans of sub.offered_answers" [ngValue]="ans">{{ans}}</option>
          </select>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'radio'">
        <div formArrayName="{{sub.id}}">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
          <div *ngFor="let ans of sub.offered_answers">
              <input class="form-radio" type="radio" value="{{ans}}" formControlName="{{0}}">
              <label class="form-custom-label">{{ans}}</label>
            </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'file'">
        <div>
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
          <shared-files-upload (uploadFiles)="fileUpload(sub.id, $event)" customstyle="filedrop"></shared-files-upload>
          <div class="mt-4">
            <div *ngFor="let file of getFilesForUpload(sub.id); let i = index; last as last" [ngClass]="{'last-item-border-bottom': last}" class="d-flex justify-content-between">
              <span class="text-purple-ionfi text-14">{{ file.name }}</span>
              <button class="btn btn-sm edit-icon h5" (click)="removeFile(sub.id, file)">
                <span class="ico icon-trashcan"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'check'">
        <div formArrayName="{{sub.id}}">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
          <div *ngFor="let ans of sub.offered_answers; let i=index">
              <span>
                <input class="form-checkbox" type="checkbox" value="{{ans}}"
                       formControlName="{{i}}">
                <label class="form-custom-label">{{ans}}</label>
              </span>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'slider'">
        <div formArrayName="{{sub.id}}">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
          <span class="d-flex align-items-center">
              <label class="switch">
                <input type="checkbox" formControlName="{{0}}">
                <span class="slider round"></span>
              </label>
              <span class="ml-2" *ngIf="sub.offered_answers[0]">{{sub.offered_answers[0]}}</span>
            </span>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'contact'">
        <div formArrayName="{{sub.id}}">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
          <div *ngFor="let con of getFormArray(sub.id)?.controls; let i = index" class="mb-3">
            <div formGroupName="{{i}}">
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <label class="input-group-text col-12">{{'shared.question: Type' | translate}}</label>
                </div>
                <select class="custom-select" formControlName="type">
                  <option [ngValue]="null" selected disabled>{{'shared.question: Enter Type' | translate}}...</option>
                  <option [ngValue]="'Financial Institution'">{{'shared.question: Financial Institution' | translate}}</option>
                  <option [ngValue]="'Individual Person'">{{'shared.question: Individual Person' | translate}}</option>
                  <option [ngValue]="'Non-Individual Person'">{{'shared.question: Non-Individual Person' | translate}}</option>
                </select>
              </div>
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <span class="input-group-text col-12">{{'shared.question: Name' | translate}}</span>
                </div>
                <input type="text" class="form-control" formControlName="name">
              </div>
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <span class="input-group-text col-12">{{'shared.question: Email' | translate}}</span>
                </div>
                <input type="text" class="form-control" formControlName="email">
              </div>
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <span class="input-group-text col-12">{{'shared.question: Phone' | translate}}</span>
                </div>
                <input type="text" class="form-control" formControlName="phone">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'address'">
        <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
        <div *ngFor="let con of getFormArray(sub.id)?.controls; let i = index" class="mb-3">
          <div formArrayName="{{sub.id}}">
            <div formGroupName="{{i}}">
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <span class="input-group-text col-12">{{'shared.question: Street' | translate}}</span>
                </div>
                <input type="text" class="form-control" formControlName="street">
              </div>
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <span class="input-group-text col-12">{{'shared.question: City' | translate}}</span>
                </div>
                <input type="text" class="form-control" formControlName="city">
              </div>
            </div>
          </div>
          <shared-inputs-country [countryControlName]="'country'"
                                 [stateControlName]="'state'"
                                 [form]="formGroup"
                                 [formArrayName]="sub.id"
                                 [groupName]="i"
                                 [showStateInput]="true"
                                 [formGroupClass]="false">
          </shared-inputs-country>
          <div formArrayName="{{sub.id}}">
            <div formGroupName="{{i}}">
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <span class="input-group-text col-12">{{'shared.question: Zip' | translate}}</span>
                </div>
                <input type="text" class="form-control" formControlName="zip">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'date'">
        <div formArrayName="{{sub.id}}">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}<span *ngIf="sub.mandatory">*</span></div>
          <div *ngFor="let con of getFormArray(sub.id)?.controls; let i = index" class="mb-3">
            <mat-form-field floatLabel="never">
              <input class="form-control" matInput formControlName="{{i}}" [matDatepicker]="dateInput"
                     placeholder="{{'shared.question: Choose a date' | translate}}">
              <mat-datepicker-toggle matSuffix [for]="dateInput"></mat-datepicker-toggle>
              <mat-datepicker #dateInput disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'title'">
        <label class="font-weight-bold title-size">{{sub.title}}</label>
      </div>
      <div *ngIf="sub.type_of_question === 'label'">
        <label class="font-italic font-weight-light">{{sub.title}}</label>
      </div>
      <div class="d-flex justify-content-end">
        <button *ngIf="sub.allow_multiple_answers && getFormArray(sub.id)?.controls.length > 1"
                class="btn btn-sm btn-light"
                (click)="removeLastForSub(sub)">
          <fa-icon [classes]="['fa-fw']" icon="trash-alt"></fa-icon>
        </button>
        <button class="btn btn-light btn-sm" *ngIf="sub.allow_multiple_answers" (click)="addAnswerForSub(sub)">
          <fa-icon [classes]="['fa-fw']" icon="plus"></fa-icon>
        </button>
      </div>
    </div>
  </form>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {defaultDebounceTime, download, trimTimestampFromFilename} from '@app/shared-module/utils/utils';
import {ApiService} from '@app/core-module/services/api.service';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, finalize, map} from 'rxjs/operators';
import {AnsweredQuestion} from '@shared/models/question-definition';
import {QuestionFormData} from '@app/shared-module/utils/question.utils';

@Component({
  selector: 'shared-questions-answered',
  templateUrl: './questions-answered.component.html',
  styleUrls: ['./questions-answered.component.scss']
})
export class QuestionsAnsweredComponent implements OnInit {

  @Input()
  questionsAnswered: Observable<AnsweredQuestion[]>;

  questionsAnsweredOrdered: Observable<AnsweredQuestion[]>;
  @Input()
  readingQuestionsAnswered: Observable<boolean>;
  @Output()
  updateAnswerEvent = new EventEmitter<{questionFormData: QuestionFormData, questionId: string}>();

  downloadingFile$ = new BehaviorSubject<boolean>(false);
  downloadingFile = this.downloadingFile$.asObservable().pipe(debounceTime(defaultDebounceTime));

  constructor(private apiService: ApiService,
              private alertsService: AlertsService) {
  }

  ngOnInit() {
    this.questionsAnsweredOrdered = this.questionsAnswered.pipe(
      map(it => it.sort())
    );
  }

  updateAnswer(questionFormData: QuestionFormData, questionId: string): void {
    this.updateAnswerEvent.emit({questionFormData, questionId});
  }

  downloadFile(filename: string) {
    this.downloadingFile$.next(true);
    this.apiService.getProfileAnswerFile(filename).pipe(
      finalize(() => this.downloadingFile$.next(false))
    ).subscribe(
      res => download(trimTimestampFromFilename(filename), res.body),
      error => this.alertsService.addAlert({type: 'danger', message: 'Error while downloading file'})
    );
  }

}

<button class="btn btn-success btn-sm rounded-btn ld-ext-right"
        type="button"
        (click)="open(content)">{{ 'fi.provide-more-info-answer-modal: Provide more info' | translate }}
</button>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'fi.provide-more-info-answer-modal: Provide more info' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <shared-files-upload (uploadFiles)="handleUploadedFiles($event.files)"></shared-files-upload>
    <div class="mt-4">
      <div *ngFor="let file of files; let i = index; last as last" [ngClass]="{'last-item-border-bottom': last}" class="d-flex justify-content-between">
        <span class="text-purple-ionfi text-14">{{ file.name }}</span>
        <button class="btn btn-sm edit-icon h5" (click)="removeFile(file)">
          <span class="ico icon-trashcan"></span>
        </button>
      </div>
    </div>
    <div class="mt-3">
      <label>{{ 'fi.provide-more-info-answer-modal: Comment' | translate }}</label>
      <textarea class="form-control"
                [(ngModel)]="comment"
                rows="5"
                type="text"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary ld-ext-right" [ngClass]="{'running': submitting}"
            type="button"
            (click)="submit()"
            [disabled]="files.length == 0 && !comment || submitting">
      {{ 'fi.provide-more-info-answer-modal: Submit' | translate }}
      <div class="ld ld-ring ld-spin"></div>
    </button>
  </div>

</ng-template>

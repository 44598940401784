import {Component, Input} from '@angular/core';
import {Balance} from '@shared/models/balance';
import {Angular5Csv} from 'angular5-csv/dist/Angular5-csv';
import {debounceTime, finalize, map, takeUntil} from 'rxjs/operators';
import {createFragment, defaultDebounceTime, download} from '@app/shared-module/utils/utils';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminApiService} from '@app/admin-module/services/admin.api.service';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {groupAndSumAvailableBalance, groupAndSumCurrentBalance} from '@app/shared-module/utils/balance.utils';
import {CurrencyPipe, Location} from '@angular/common';
import {Observable} from 'rxjs';
import {AllBalancesState} from '@app/admin-module/states/all.balances.state';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {PAGE_SIZE} from '@shared/utils/constants';
import {UpstreamBank} from '@shared/models/upstream-bank';
import {UpstreamBanksState} from '@app/admin-module/states/upstream-banks.state';
import {AbstractFilterableTableComponent} from '@app/shared-module/components/abstract.filterable.table.component';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'shared-accounts-activity-table',
  templateUrl: './accounts-activity-table.component.html',
  styleUrls: ['./accounts-activity-table.component.scss'],
  animations: [routerTransition()]
})
export class AccountsActivityTableComponent extends AbstractFilterableTableComponent {

  PAGE_SIZE = PAGE_SIZE;

  readingBalances: Observable<boolean>;

  @Input()
  balances: Balance[];
  filteredBalances: Balance[];

  @Input()
  isIonfi = false;

  @Input()
  isSegregated = false;

  @Input()
  showFilter = false;

  @Input()
  showUpstreamBankColumn = true;

  downloadingPdf = false;

  upstreamBanks: Observable<UpstreamBank[]>;

  filterFormGroup: FormGroup;

  constructor(private adminApiService: AdminApiService,
              private alertsService: AlertsService,
              private currencyPipe: CurrencyPipe,
              private allBalancesState: AllBalancesState,
              private upstreamBankState: UpstreamBanksState,
              protected activatedRoute: ActivatedRoute,
              protected location: Location,
              protected router: Router) {
    super(activatedRoute, router, location);

    this.filterFormGroup = new FormGroup({
      upstreamBankID: new FormControl(),
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.readingBalances = this.allBalancesState.getReadingBalances().pipe(debounceTime(defaultDebounceTime));
    for (const balance of this.balances) {
      balance['upstreamBankName'] = balance.upstreamBank ? balance.upstreamBank.name : '';
    }
    this.upstreamBanks = this.upstreamBankState.getUpstreamBanks();
    this.filteredBalances = this.balances;
    this.filterBalances();
  }

  sumAvailableBalance(balances: Balance[]) {
    return groupAndSumAvailableBalance(balances, this.currencyPipe);
  }

  sumCurrentBalance(balances: Balance[]) {
    return groupAndSumCurrentBalance(balances, this.currencyPipe);
  }

  downloadToCsv(balances: Balance[]) {
    const columns = [];
    columns.push('Number');
    columns.push('Name');
    columns.push('FI');
    columns.push('Type');
    columns.push('Upstream Bank');
    columns.push('Current Balance');
    columns.push('Available Balance');
    const json: any[] = [[columns]];
    for (const balance of balances) {
      const columnsData = [];
      columnsData.push(balance.acc_number);
      columnsData.push(balance.acc_name);
      columnsData.push(balance.fi_name);
      columnsData.push(balance.acc_type);
      columnsData.push(balance.upstreamBank ? balance.upstreamBank.name : '');
      columnsData.push(balance.current_balance);
      columnsData.push(balance.available_balance);
      json.push([columnsData]);
    }
    new Angular5Csv(json, 'Accounts Activity');
  }

  downloadToPdf(balances: Balance[]) {
    this.downloadingPdf = true;
    this.adminApiService.getAllBalancesPdf(balances).pipe(
      finalize(() => this.downloadingPdf = false)
    ).subscribe(res => {
      download('accounts-activity.pdf', res.body);
    }, error => {
      this.alertsService.addAlert({type: 'danger', message: 'PDF file could not be read'});
    });
  }

  resetForm() {
    this.resetFilter();
    this.onFilterFormChange();
  }

  onFilterFormChange() {
    this.filterBalances();
  }

  filterBalances() {
    if (!this.showFilter) {
      this.filteredBalances = this.balances;
    } else {
      this.filteredBalances = this.getFilteredFromInput(this.balances);
    }
  }

  getFormGroup(): FormGroup {
    return this.filterFormGroup;
  }

  protected getFilteredFromInput(balances: Balance[]): Balance[] {
    const upbId = this.getUpstreamBankID();
    return balances.filter(it => !upbId || upbId <= 0 || it.upstream_bank_id === upbId);
  }

  protected getUpstreamBankID() {
    const upbId = this.getFormGroup().value['upstreamBankID'];
    return upbId > 0 ? upbId : -1;
  }

  setUpdatesOnFormChange(): Observable<void> {
    return this.filterFormGroup.valueChanges.pipe(
      takeUntil(this.destroy$),
      map((formValues) => {
        this.checkFiltered();
        this.onFilterFormChange();
        const valuesCloned = {};
        Object.assign(valuesCloned, formValues);
        const fragment = createFragment(valuesCloned);
        this.addFragmentToTheUrl(fragment);
        this.saveFilterQueryInStorage(fragment);
      })
    );
  }
}

<button type="button"
        class="btn btn-sm edit-icon text-purple-ionfi float-right ml-2 mr-2"
        (click)="open(content)">
  <span class="ico icon-pen" ngbTooltip="{{'admin.account-addons: Edit' | translate}}"></span>
</button>
<ng-template #content let-c="close" let-d="dismiss">

  <div class="modal-header">
    <h4 class="modal-title">{{ 'shared.edit-question-answer-modal: Edit Answer' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true" class="text-theme">&times;</span>
    </button>
  </div>

  <div class="modal-body list-group list-group-flush">
    <div *ngFor="let item of questionAnswerItems; let i = index">
      <shared-question [question]="item.questionDefinition" [formGroup]="item.formGroup" [files]="item.files" [index]="i"></shared-question>
    </div>
    <button class="mt-3 btn btn-success btn-sm rounded-btn ld-ext-right" type="button" style="overflow: visible"
            [disabled]="!allFormGroupsValid() || working"
            [ngClass]="{'running': working}"
            (click)="submitAnswer()">
      {{ 'shared.question-block: Submit' | translate }}
      <div class="ld ld-ring ld-spin"></div>
    </button>
    <button *ngIf="getAnyQuestionDefinition().allow_to_duplicate"
            class="mt-3 btn btn-sm btn-light" style="overflow: visible"
            (click)="addAdditionalFormGroup()"
            ngbTooltip="{{'shared.question-block: Add another empty form' | translate}}">
      <fa-icon [classes]="['fa-fw']" icon="plus"></fa-icon>
    </button>
    <button *ngIf="getAnyQuestionDefinition().allow_to_duplicate && questionAnswerItems.length > 1"
            class="mt-3 btn btn-sm btn-light" style="overflow: visible"
            (click)="removeLastAdditionalFormGroup()"
            ngbTooltip="{{'shared.question-block: Remove last form' | translate}}">
      <fa-icon [classes]="['fa-fw']" icon="trash-alt"></fa-icon>
    </button>

  </div>
</ng-template>

<ngx-datatable
               [ngClass]="'bootstrap ' + tableClass"
               [reorderable]="false"
               [columnMode]="'force'"
               [scrollbarH]="enableScrollbar"
               [sorts]="[{prop: 'ordering_value',
                                    dir: 'desc'
                                }]"
               [rows]="transactions"
               [headerHeight]="50"
               [footerHeight]="50"
               [rowHeight]="'auto'"
               [virtualization]="false"
               [limit]="PAGE_SIZE"
               [externalPaging]="externalPaging"
               [externalSorting]="externalSorting"
               [count]="count"
               (page)="onPage.emit($event)"
               (sort)="onSort.emit($event)">
<!--virtualization=false must be set, otherwise the (page) event fires even on scroll-->
  <ngx-datatable-column name="{{'shared.transactions-table: Trans ID' | translate}}" prop="identifier"
                        [sortable]="false"
                        headerClass="pl-22"
                        cellClass="pl-22">
    <ng-template let-transaction="row" ngx-datatable-cell-template>
      {{ transaction.identifier }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.transactions-table: Date' | translate}}" prop="ordering_value" [sortable]="true">
    <ng-template let-transaction="row" ngx-datatable-cell-template>
      {{ transaction.date | date:'short' }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="isDisplayingMoreAccounts()" [width]="150" name="{{'shared.transactions-table: Account' | translate}}" prop="account.acc_number"
                        [sortable]="true">
    <ng-template let-transaction="row" ngx-datatable-cell-template *ngIf="currentFI | async as fi">
      <div *ngIf="canSeeAccountLink(transaction.account,fi)">
      <a [routerLink]="getAccountLink(transaction)">{{ transaction.account.acc_number }}</a>
        ({{ transaction.account.acc_name }})
      </div>
      <div *ngIf="!canSeeAccountLink(transaction.account,fi)">
        <span>{{ transaction.account.acc_number }}</span>
        ({{ transaction.account.acc_name }})
      </div>
      <div>
        <div class="pt-2"></div>
        <fa-icon *ngIf="transaction.account.upstreamBank" [classes]="['fa-fw']" class='icon-space margin-auto' icon="university"></fa-icon>
        <a *ngIf="transaction.account.upstreamBank && isAdmin" [routerLink]="['/admin/upstream-banks', transaction.account.upstream_bank_id]">{{transaction.account.upstreamBank.name}}</a>
        <span *ngIf="transaction.account.upstreamBank && !isAdmin">{{transaction.account.upstreamBank.name}}</span>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [width]="300" name="{{'shared.transactions-table: Description' | translate}}" prop="description"
                        [sortable]="false">
    <ng-template let-transaction="row" ngx-datatable-cell-template>
      <transactions-account-details-description [transaction]="transaction"
                                                [enableDescriptionWireFormLink]="enableDescriptionWireFormLink"
                                                (closeModal)="emitCloseModal()"
                                                [isSegregatedAccountTable]="isSegregatedAccountTable"
                                                [isIonfiTable]="isIonfiTable"
                                                [accountLink]="getAccountLink(transaction)"></transactions-account-details-description>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.transactions-table: Status' | translate}}" prop="status.status"
                        headerClass="text-center"
                        cellClass="d-flex justify-content-center">
    <ng-template let-transaction="row" ngx-datatable-cell-template>
<span class="type-design-circle"
      [ngStyle]="{'color' : transaction.status.text_color, 'background-color' : transaction.status.background_color}">{{ translateService.currentLang === 'en' ? transaction.status.status : transaction.status.status_es }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.transactions-table: Type' | translate}}" prop="type.type" [sortable]="false">
    <ng-template let-transaction="row" ngx-datatable-cell-template>
      {{ transaction.sub_type ? transaction.sub_type.type : transaction.type.type }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.transactions-table: Debit' | translate}}" prop="debit_amount"
                        headerClass="text-right"
                        cellClass=" d-flex justify-content-end" [sortable]="false">
    <ng-template let-transaction="row" ngx-datatable-cell-template>
      {{ transaction.debit_amount | currency:transaction.account.acc_currency }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.transactions-table: Credit' | translate}}" prop="credit_amount"
                        headerClass="text-right"
                        cellClass=" d-flex justify-content-end" [sortable]="false">
    <ng-template let-transaction="row" ngx-datatable-cell-template>
      {{ transaction.credit_amount | currency:transaction.account.acc_currency }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.transactions-table: Current Balance' | translate}}" prop="current_balance"
                        headerClass="text-right"
                        cellClass=" d-flex justify-content-end" [sortable]="false">
    <ng-template let-transaction="row" ngx-datatable-cell-template>
      {{ transaction.current_balance | currency:transaction.account.acc_currency }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.transactions-table: Available Balance' | translate}}" prop="available_balance"
                        headerClass="text-right pr-4"
                        cellClass=" d-flex justify-content-end pr-4" [sortable]="false">
    <ng-template let-transaction="row" ngx-datatable-cell-template>
      {{ transaction.available_balance | currency:transaction.account.acc_currency }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="showActionButtons" headerClass="text-right"
                        cellClass=" d-flex justify-content-end">
    <ng-template let-transaction="row" ngx-datatable-cell-template>
      <ng-container *ngIf="currentFI | async as fi">
        <ng-container
          *ngIf="!transaction.fee_returned && transaction.type.type === 'Fee' && transaction.account.fi_id === fi.id">
          <return-fee-modal [transaction]="transaction" [icon]="'backward'"
                            [tooltip]="'shared.transactions-table: Return Fee' | translate"
                            [submitLabel]="'shared.transactions-table: Return Fee' | translate"
                            [buttonType]="'btn-primary'"
                            (submitted)="returnFeeSubmit(transaction, $event)"></return-fee-modal>
        </ng-container>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

import {NavigationEnd, Router} from "@angular/router";

const pushRightClass: string = 'push-right';


export function isToggled(): boolean {
  const dom: Element = document.querySelector('body');
  return dom.classList.contains(pushRightClass);
}

export function toggleSidebar() {
  const dom: any = document.querySelector('body');
  dom.classList.toggle(pushRightClass);
}

export function adjustSidebar(router: Router) {
  router.events.subscribe(val => {
    if (val instanceof NavigationEnd && window.innerWidth <= 992 && isToggled()) {
      toggleSidebar();
    }
  });
}

<div class="row" [formGroup]="form">
  <div class="container-fluid">
    <div *ngIf="isSegregateAccountTransfer" class="card card-body form-group text-dark-9-opacity">
      <div class="card-header m-0 p-0">{{ 'shared.wire-transfer-editing-form: Segregated Account' | translate }}</div>
      <div class="row form-group justify-content-md-left">
        <label class="col-sm-3 col-form-label col-form-label-sm text-right text-dark-7-opacity  align-self-center font-weight-normal">{{ 'shared.wire-transfer-editing-form: Account number or name' | translate }}<span class="text-purple-ionfi">*</span></label>
        <div class="col-md-3">
          <input-wire-transfer-entity
            [headerTitle]="'shared.wire-transfer-editing-form: Segregated Account' | translate"
            entityType="person"
            [formGroup]="form"
            [selectedAccount]="selectedAccount"
            [fi_id]="fi_id"
            [disabled]="!isSegregateAccountTransfer"
            [entityName]="'originator_person'"
            [isSegregateAccountInput]="isSegregateAccountTransfer"></input-wire-transfer-entity>
        </div>
      </div>
    </div>

    <div class="card card-body form-group text-dark-9-opacity">
      <div class="m-0 p-0 card-header">{{ 'shared.wire-transfer-editing-form: Wire Transfer details' | translate }}</div>
      <div class="row form-group justify-content-md-left">
        <label for="type" class="col-sm-3 font-weight-normal  text-dark-7-opacity col-form-label col-form-label-sm text-right">
          {{ 'shared.wire-transfer-editing-form: Type' | translate }}<span class="text-purple-ionfi">*</span></label>
        <div class="col-md-3 custom-select_arrow-ionfi">
          <select type="select" class="form-control" formControlName="type" id="type">
            <option value="DOMESTIC">{{ 'shared.wire-transfer-editing-form: Domestic' | translate }}</option>
            <option value="INTERNATIONAL">{{ 'shared.wire-transfer-editing-form: International' | translate }}</option>
          </select>
          <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
        </div>
        <bfv-messages [ngStyle]="{'display':'none'}"></bfv-messages> <!-- Hide <bfv-messages> tag by 'Bootstrap' as the Angular already shows error messages. -->
      </div>


      <div class="row form-group justify-content-md-left">
        <label for="domestic_amount" class="col-sm-3 col-form-label text-dark-7-opacity col-form-label-sm text-right font-weight-normal">
          {{ 'shared.wire-transfer-editing-form: Domestic amount (USD)' | translate }}<span class="text-purple-ionfi">*</span></label>
        <div class="col-md-3 justify-content-between input-group">
              <div class="input-group-prepend">
                <div class="input-group-text px-3">$</div>
              </div>
              <input class="form-control purple" type="number" formControlName="domestic_amount"
                      id="domestic_amount">
            </div>
      </div>

      <input-currency *ngIf="form.get('type').value === 'INTERNATIONAL'" [labelText]="'shared.wire-transfer-editing-form: Currency' | translate"
                      [controlName]="'currency'"
                      [fiFormInput]="true"
                      [formGroup]="form"></input-currency>

      <div class="row form-group justify-content-md-left">
        <label class="col-sm-3 col-form-label col-form-label-sm text-right text-dark-7-opacity font-weight-normal">
          {{ 'shared.wire-transfer-editing-form: For the further benefit of' | translate }}<span class="text-purple-ionfi">*</span></label>
        <div class="col-md-3">
        <textarea class="form-control"
                  formControlName="benefit_description"
                  resizeable="true"
                  type="text"
                  rows="4"
                  cols="20"></textarea>
        </div>
      </div>

      <div class="row form-group justify-content-md-left">
        <label for="purpose-of-wire" class="col-sm-3 col-form-label text-dark-7-opacity col-form-label-sm text-right font-weight-normal">
          {{ 'shared.wire-transfer-editing-form: Purpose of the Wire' | translate }}<span class="text-purple-ionfi">*</span></label>
        <div class="col-md-3">
        <textarea class="form-control"
                  formControlName="wire_purpose"
                  resizeable="true"
                  rows="4"
                  cols="20"
                  type="text"
                  id="purpose-of-wire"></textarea>
        <small>({{ "shared.wire-transfer-editing-form: Your recipient won't see this; it's for our records only." | translate }})</small>

        </div>
      </div>

    </div>

    <div class="card card-body form-group text-dark-9-opacity">
      <div class="card-header m-0 p-0">{{ 'shared.wire-transfer-editing-form: Beneficiary Person' | translate }}</div>
        <div class="row form-group justify-content-md-left">
          <label class="col-sm-3 col-form-label col-form-label-sm text-right text-dark-7-opacity  align-self-center font-weight-normal">{{ 'shared.wire-transfer-editing-form: ID Number or Name' | translate }}<span class="text-purple-ionfi">*</span></label>
          <div class="col-md-3">
            <input-wire-transfer-entity
              [headerTitle]="'shared.wire-transfer-editing-form: Beneficiary Person' | translate"
              entityType="person"
              [formGroup]="form"
              [selectedAccount]="selectedAccount"
              [fi_id]="fi_id"
              [ddaNumberEntityName]="'beneficiary_person_dda'"
              [entityName]="'beneficiary_person'"></input-wire-transfer-entity>
          </div>
        </div>
    </div>

    <div *ngIf="!isSegregateAccountTransfer" class="card card-body form-group text-dark-9-opacity">
      <div class="card-header m-0 p-0">{{ 'shared.wire-transfer-editing-form: Originator Person' | translate }}</div>
      <div class="row form-group justify-content-md-left">
        <label class="col-sm-3 col-form-label col-form-label-sm text-right align-self-center text-dark-7-opacity font-weight-normal">{{ 'shared.wire-transfer-editing-form: ID Number or Name' | translate }}<span class="text-purple-ionfi">*</span></label>
        <div class="col-md-3">
          <input-wire-transfer-entity
            [headerTitle]="'shared.wire-transfer-editing-form: Originator Person' | translate"
            entityType="person"
            [formGroup]="form"
            [selectedAccount]="selectedAccount"
            [fi_id]="fi_id"
            [disabled]="!isThirdPartyTransfer"
            [onlyFiClients]="true"
            [ddaNumberEntityName]="isThirdPartyTransfer ? 'originator_person_dda' : null"
            [entityName]="'originator_person'"
            [disableInput]="!isThirdPartyTransfer"
            [disableInputMessage]="'shared.wire-transfer-editing-form: Please select an Account' | translate">
          </input-wire-transfer-entity>
        </div>
      </div>
    </div>

    <div class="card card-body form-group text-dark-9-opacity">
      <div class="m-0 p-0 card-header">{{ 'shared.wire-transfer-editing-form: Beneficiary Bank' | translate }}</div>
      <div class="row form-group justify-content-md-left">
        <label class="col-sm-3 col-form-label align-self-center col-form-label-sm text-right text-dark-7-opacity font-weight-normal">{{ 'shared.wire-transfer-editing-form: ID Number or Name' | translate }}<span class="text-purple-ionfi">*</span></label>
        <div class="col-md-3">
          <input-wire-transfer-entity
            [headerTitle]="'shared.wire-transfer-editing-form: Beneficiary Bank' | translate"
            entityType="bank"
            [formGroup]="form"
            [selectedAccount]="selectedAccount"
            [fi_id]="fi_id"
            [entityName]="'beneficiary_bank'"></input-wire-transfer-entity>
        </div>
      </div>
    </div>

    <div class="card card-body form-group text-dark-9-opacity">
      <div class="m-0 p-0 card-header">{{ 'shared.wire-transfer-editing-form: Supplemented info (optional)' | translate }}</div>
<!--      <div class="row justify-content-md-left">-->
<!--        <label class="col-sm-3 col-form-label align-self-center col-form-label-sm text-right text-dark-7-opacity font-weight-normal">{{ 'shared.wire-transfer-editing-form: Sender ID' | translate }}</label>-->
<!--        <div class="col-md-3">-->
<!--          <input-wire-transfer-entity-->
<!--            [headerTitle]="'shared.wire-transfer-editing-form: Sender ID' | translate"-->
<!--            entityType="bank"-->
<!--            [formGroup]="form"-->
<!--            [fi_id]="fi_id"-->
<!--            [entityName]="'sender_bank'"></input-wire-transfer-entity>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row justify-content-md-left">-->
<!--        <label class="col-sm-3 col-form-label col-form-label-sm align-self-center text-right text-dark-7-opacity font-weight-normal">{{ 'shared.wire-transfer-editing-form: Receiver Bank' | translate }}</label>-->
<!--        <div class="col-md-3">-->
<!--          <input-wire-transfer-entity-->
<!--            [headerTitle]="'shared.wire-transfer-editing-form: Receiver Bank' | translate"-->
<!--            entityType="bank"-->
<!--            [formGroup]="form"-->
<!--            [fi_id]="fi_id"-->
<!--            [entityName]="'receiver_bank'"></input-wire-transfer-entity>-->
<!--        </div>-->
<!--      </div>-->

      <div class="row justify-content-md-left">
        <label class="col-sm-3 col-form-label col-form-label-sm align-self-center text-right text-dark-7-opacity font-weight-normal">{{ 'shared.wire-transfer-editing-form: Intermediary Bank' | translate }}</label>
        <div class="col-md-3">
          <input-wire-transfer-entity
            [headerTitle]="'shared.wire-transfer-editing-form: Intermediary Bank' | translate"
            entityType="bank"
            [formGroup]="form"
            [selectedAccount]="selectedAccount"
            [fi_id]="fi_id"
            [entityName]="'intermediary_bank'"></input-wire-transfer-entity>
        </div>
      </div>

<!--      <div class="row justify-content-md-left">-->
<!--        <label class="col-sm-3 col-form-label col-form-label-sm align-self-center text-right text-dark-7-opacity font-weight-normal">{{ 'shared.wire-transfer-editing-form: Originator Bank' | translate }}</label>-->
<!--        <div class="col-md-3">-->
<!--          <input-wire-transfer-entity-->
<!--            [headerTitle]="'shared.wire-transfer-editing-form: Originator Bank' | translate"-->
<!--            entityType="bank"-->
<!--            [formGroup]="form"-->
<!--            [fi_id]="fi_id"-->
<!--            [entityName]="'originator_bank'"></input-wire-transfer-entity>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="card mt-3 form-group text-dark-9-opacity">
      <div class="card-header">{{ 'shared.wire-transfer-editing-form: Supportive Documentation' | translate }}</div>
      <div class="card-body">
        <div class="mt-4 text-muted" *ngIf="files.length > 0">
          <div *ngFor="let file of files; let i = index; last as last" [ngClass]="{'last-item-border-bottom': last}"
               class="d-flex justify-content-between">
            <span class="text-purple-ionfi text-14">{{ file.name }}</span>
            <button class="btn btn-sm edit-icon h5" (click)="removeFile(file)">
              <span class="ico icon-trashcan"></span>
            </button>
          </div>
        </div>
        <div class="mt-3">
          <shared-files-upload class="col-12 p-0"
                               (uploadFiles)="handleSelectedFiles($event.files)"></shared-files-upload>
        </div>
        <span *ngIf="files.length === 0 && !form.errors?.requiredDocuments" class="text-muted px-3">
          {{ 'shared.wire-transfer-editing-form: No documents uploaded' | translate }}
        </span>
        <span *ngIf="form.errors?.requiredDocuments" class="text-danger px-3">
          {{ 'shared.wire-transfer-editing-form: Supporting documents are required!' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>

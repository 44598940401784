import {User} from "./user";

export interface ContactAnswer {
  type: string;
  name: string;
  email: string;
  phone: string;
}

export interface AddressAnswer {
  street: string;
  city: string;
  country: string;
  state: string;
  zip: string;
}

export interface Answer {
  text_answer: string[];
  file_answer: string[];
  contact_answer: ContactAnswer[];
  address_answer: AddressAnswer[];
}

export class SubQuestion {
  id: string;
  title: string;
  type_of_question: string;
  offered_answers: string[];
  allow_multiple_answers: boolean;
  mandatory: boolean;
  answer: Answer;
}

export class QuestionDefinition {
  id: string;
  allow_to_duplicate: boolean;
  question: string;
  type_of_question: string;
  offered_answers: string[];
  allow_multiple_answers: boolean;
  mandatory: boolean;
  sub_questions: SubQuestion[];
  created_at: Date;
  answer: {
    text_answer: string[];
    file_answer: string[];
    contact_answer: ContactAnswer[],
    address_answer: AddressAnswer[]
  };
}

export class AnsweredQuestion {
  id: string;
  blocks: QuestionDefinition[];
  confirmed_by_user_id: { user_id: number, name: string, email: string };
  from_pending_question_id_string: string;
  // backend generated
  confirmed_by: User;
}

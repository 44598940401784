import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'activity'
})
export class ActivityPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, args?: any): any {
    switch (value) {
      case 'CREATED': return this.translateService.instant('shared.activity-pipe: Created');
      case 'SUBMITTED': return this.translateService.instant('shared.activity-pipe: Submitted');
      case 'DISCARDED': return this.translateService.instant('shared.activity-pipe: Discarded');
      case 'FIRST_IONFI_APPROVAL': return this.translateService.instant('shared.activity-pipe: Approved');
      case 'PROCESSED': return this.translateService.instant('shared.activity-pipe: Processed');
      case 'REJECTED': return this.translateService.instant('shared.activity-pipe: Rejected');
      case 'INCOMING_ASSIGNED': return this.translateService.instant('shared.activity-pipe: Assigned');
      case 'ACCEPTED_IDM': return this.translateService.instant('shared.activity-pipe: Accepted');
      case 'SUBMITTED_KYC': return this.translateService.instant('shared.activity-pipe: Submitted KYC');
      default: return value;
    }
  }

}

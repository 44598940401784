import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CountriesService} from '@app/core-module/services/countries.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {getStates} from '@app/shared-module/utils/utils';
import {Subscription, Observable} from 'rxjs';
import {Country_ISO} from "@shared/models/country_iso";

@Component({
  selector: 'shared-inputs-country',
  templateUrl: './inputs-country.component.html',
  styleUrls: ['./inputs-country.component.scss']
})
export class InputsCountryComponent implements OnInit, OnDestroy {

  @Input()
  countryControlName: string;

  @Input()
  inFilter: boolean;

  @Input()
  marginBottom: boolean;

  @Input()
  removeCardDesign: boolean;

  @Input()
  stateControlName: string;

  @Input()
  countryLabel: string;

  @Input()
  importantAsteriks: string;

  @Input()
  stateLabel: string;

  @Input()
  showStateInput: boolean;

  @Input()
  form: FormGroup;

  @Input()
  formArrayName: string;

  @Input()
  groupName: string;

  @Input()
  formGroupClass = true;

  countries: Observable<Country_ISO[]>;

  regions;

  countrySubscription: Subscription;

  constructor(private countriesService: CountriesService) {

    this.countries = this.countriesService.getCountries();
    this.regions = [];
  }

  ngOnInit() {

    if (this.countryControlName && this.stateControlName) {

      let countryControl: FormControl = null;
      let stateControl: FormControl = null;
      if (this.formArrayName) {
        if (this.groupName != null) {
          const array = this.form.controls[this.formArrayName] as FormArray;
          const group = array.controls[this.groupName] as FormGroup;
          countryControl = group.controls[this.countryControlName] as FormControl;
          stateControl = group.controls[this.stateControlName] as FormControl;
        } else {
          let array = this.form.controls[this.formArrayName] as FormArray;
          countryControl = array.controls[this.countryControlName] as FormControl;
          stateControl = array.controls[this.stateControlName] as FormControl;
        }
      } else {
        countryControl = this.form.controls[this.countryControlName] as FormControl;
        stateControl = this.form.controls[this.stateControlName] as FormControl;
      }

      this.regions = getStates(countryControl.value);

      this.countrySubscription = countryControl.valueChanges.subscribe(value => {
        this.regions = getStates(countryControl.value);
        if (value === 'USA' || value === 'MEX') {
          stateControl.setValidators(Validators.required);
        } else {
          stateControl.setValue(null);
          stateControl.clearValidators();
          stateControl.setErrors(null);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.countrySubscription) {
      this.countrySubscription.unsubscribe();
    }
  }

}

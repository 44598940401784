<shared-loading-bar
  *ngIf="(readingQuestionsAnswered | async) || (downloadingFile | async)"></shared-loading-bar>
<div *ngIf="questionsAnsweredOrdered | async as qa">
  <div *ngFor="let question of qa" class="mb-5">
    <div *ngFor="let block of question.blocks; let i = index" class="answer-group-block">
      <div class="answer-row group-title-row" *ngIf="question.blocks.length > 1 && i == 0">
        <span class="question-block text-muted" [ngClass]="{'title-size': block.type_of_question === 'title'}">
            {{ block.question }}
          </span>
        <shared-edit-question-answer-modal
          [answeredQuestion]="question"
          (answerQuestionEventEmitter)="updateAnswer($event, question.id)"></shared-edit-question-answer-modal>
      </div>
      <div class="answer-row title-row">
          <span class="question-block text-muted" [ngClass]="{'title-size': block.type_of_question === 'title'}">
            {{ block.question }}
            <span *ngIf="question.blocks.length > 1">({{ i + 1 }})</span>
          </span>
          <span class="answer-block float-right text-14">
            <shared-answer-view [question]="block" (downloadPart)="downloadFile($event)"></shared-answer-view>
          </span>
          <span *ngIf="question.blocks.length == 1">
            <shared-edit-question-answer-modal
              [answeredQuestion]="question"
              (answerQuestionEventEmitter)="updateAnswer($event, question.id)"></shared-edit-question-answer-modal>
          </span>
      </div>
      <div class="answer-row value-row" *ngFor="let subQuestion of block.sub_questions">
        <span class="question-block text-muted"
              [ngClass]="{'title-size': subQuestion.type_of_question === 'title'}">{{ subQuestion.title }}</span>
        <span class="answer-block float-right text-14">
            <shared-answer-view [question]="subQuestion" (downloadPart)="downloadFile($event)"></shared-answer-view>
          </span>
      </div>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'entityTypePipe'
})

export class EntityTypePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: string, args?: any): any {
    switch (value) {
      case 'LEGAL_ENTITY': return this.translateService.instant('shared.entity-type-pipe: Legal Entity');
      case 'INDIVIDUAL': return this.translateService.instant('shared.entity-type-pipe: Individual');
      default: return value;
    }
  }
}

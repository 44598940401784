import {RDC} from "./rdc";
import {DbModel} from "./db.model";
import {Account} from "./account";
import {TranStatus} from "./tran-status";
import {TranType} from "./tran-type";
import {WireTransferForm} from "./wire-transfer-form";
import {TranSubType} from "./tran-sub-type";

export class Transaction extends DbModel {
    identifier: string;
    acc_id: number;
    rdc_id: number;
    wire_transfer_form_id: number;
    reconcile_id: number;
    exists_in_previous_reconcile_report: boolean;
    debit_amount: number;
    current_balance: number;
    available_balance: number;
    ordering_index: number; // max allowed value is: MAX_ALLOWED_VALUE_OF_TRANSACTION_ORDERING_INDEX defined in constants.ts
    credit_amount: number;
    tran_type_id: number;
    tran_sub_type_id: number;
    tran_status_id: number;
    date: Date;
    description: string;
    originator_account_id: number;
    fee_returned: boolean;

    //generated
    ordering_value: number;
    rdc: RDC;
    account: Account;
    check_number: string;
    status: TranStatus;
    type: TranType;
    sub_type: TranSubType;
    originatorAccount: Account;
    wireTransferForm: WireTransferForm;
}

<div class="wrapper">

  <div class="table-wrapper">
    <div class="container-fluid" *ngIf="!hideHeader">
      <div class="row nav-menu justify-content-between table-header">
        <div class="component-body-header header-resp align-self-center">
          {{ title }}
        </div>
        <div class="col d-flex p-0 align-items-center" [ngClass]="[headerTopContentLeft ? 'justify-content-between' : 'justify-content-end']">
          <div class="px-2 py-3" [ngClass]="[headerTopContentLeft ? ' float-left' : ' float-right']">
            <ng-content select="[buttons]"></ng-content>
          </div>
          <div *ngIf="numElements >= numElementsLimit">
            <fa-icon *ngIf="limitMessageType === 'SHOWING_FIRST_N'" [classes]="['fa-fw']" icon="info-circle" [ngbTooltip]="'shared.table: Showing first {numElements} records only. Define more specific filter to see the other records.' | translate:{numElements: numElementsLimit}"></fa-icon>
            <fa-icon *ngIf="limitMessageType === 'SHOWING_LATEST_N'" [classes]="['fa-fw']" icon="info-circle" [ngbTooltip]="'shared.table: Showing latest {numElements} records only. Define more specific filter to see older records.' | translate:{numElements: numElementsLimit}"></fa-icon>
          </div>
          <div class="align-self-center align-self-center pl-4 text-12" *ngIf="showFilter">
            <span [ngClass]="{'text-purple-ionfi' : isFiltered}" class="ico icon-filter pointer"
                  (click)="toggleFilter()"></span>
            <span [ngClass]="{'green-indicator' : isFiltered}"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid p-0">
      <ng-content select="[ngx-table]"></ng-content>
    </div>
  </div>

  <div class="sidebar-wrapper" *ngIf="!filterClosed">
    <div class="sidebar-filter h-100">
      <div class="container custom-select_arrow-ionfi h-100 sidebar-filter-container">
        <div class="row">
          <h6 class="col align-self-center text-dark-9-opacity weight-500">
            {{ "shared.sidebar-filter: Filters" | translate }}
          </h6>
          <div class="col d-flex py-4 flex-row-reverse">
            <button class="close-filter-btn close-filter-btn mr-1 ml-3" (click)="toggleFilter()">x
            </button>
            <button (click)="resetFilter()"
                    class="reset-filter-btn btn btn-sm purple-button px-2 py-0 text-white">{{ 'shared.sidebar-filter: Reset' | translate }}</button>
          </div>
        </div>
        <ng-content class="label-color" select="[sidebar-filter]"></ng-content>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="stateActivities | async as a">
  <div class="card card-default mt-3" *ngIf="a.length">
    <div class="card-header text-white bg-success">
      <div *ngIf="headerText">{{ headerText }}</div>
      <small *ngIf="subheaderText">{{ subheaderText }}</small>
    </div>
    <div class="card-body">
      <shared-loading-bar *ngIf="reading | async"></shared-loading-bar>
      <ul class="timeline">
        <li *ngFor="let activity of a; let i = index" [ngClass]="{'timeline-inverted': isInvertedActivity(activity)}">
          <div class="timeline-badge {{getClassFromType(activity.activity_type || activity.type)}}">
            <fa-icon [classes]="['fa-fw']" [icon]="getIconFromType(activity.activity_type || activity.type)"></fa-icon>
          </div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <span
                *ngIf="activity.user && !activity.user.isAdministrator">{{ activity.user.first_name }} {{ activity.user.last_name }}</span>
              <h5 class="timeline-title">{{ (activity.activity_type || activity.type).description }}</h5>
              <p>
                <small class="text-muted">
                  <fa-icon [classes]="['fa-fw']" icon="clock"></fa-icon>
                  {{ activity.created_at | date: 'short' }}
                </small>
              </p>
            </div>
            <div class="timeline-body">
              <p [innerHTML]="format(activity.comment)"></p>
              <ul *ngIf="activity.documents_array && activity.documents_array.length > 0"
                  class="list-group list-group-flush mt-2">
                <li *ngFor="let document of activity.documents_array" class="list-group-item p-1">
                  <small class="clickable-span"
                         (click)="downloadDocument(activity, document.id, document.original_filename)">{{ document.original_filename }}</small>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      <div class="float-right">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-container>

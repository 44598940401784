import {Transaction} from "@shared/models/transaction";
import {Angular5Csv} from "angular5-csv/dist/Angular5-csv";
import {DatePipe} from "@angular/common";
import {Account} from '@shared/models/account';

export function downloadToCsv(transactions: Transaction[], filename: string, datePipe: DatePipe) {
  const json: any[] = [[
    'Transaction ID',
    'Date',
    'Description',
    'Originator Account',
    'Check#',
    'Status',
    'Type',
    'Upstream Bank',
    'Debit',
    'Credit',
    'Current Balance',
    'Available Balance']
  ];
  for (const transaction of transactions) {
    json.push([
      transaction.identifier,
      datePipe.transform(transaction.date, 'short'),
      transaction.description ? transaction.description : '',
      transaction.originatorAccount ? `${transaction.originatorAccount.acc_number} (${transaction.originatorAccount.acc_name})` : '',
      transaction.rdc ? transaction.rdc.check_number : '',
      transaction.status.status,
      transaction.type.type,
      transaction.account.upstreamBank ? transaction.account.upstreamBank.name : '',
      transaction.debit_amount,
      transaction.credit_amount,
      transaction.current_balance,
      transaction.available_balance]);
  }
  new Angular5Csv(json, filename);
}

export function getAccountLink(transaction: Transaction, isAdmin: boolean, isSegregatedAccountTable: boolean, isIonfiTable: boolean, isDescription: boolean = false): string {
  let link: string = '';
  if(isAdmin){
    if(isSegregatedAccountTable){
      link = '/admin/accounts/accounts-activity/segregated/';
    } else if (isIonfiTable){
      link = '/admin/accounts/accounts-activity/ionfi/';
    } else {
      link = '/admin/accounts/accounts-activity/fi/';
    }
  } else {
    if (isSegregatedAccountTable){
      link = '/fi/accounts-dashboard/segregated-accounts-activity/';
    } else {
      link = '/fi/accounts-dashboard/accounts-activity/';
    }
  }
  return link = link + (isDescription ? transaction.originatorAccount.id : transaction.account.id);
}

export function getAccountLinkFromAccount(
  account: Account,
  isAdmin: boolean,
  isSegregatedAccountTable: boolean,
  isIonfiTable: boolean) {
  let link = '';
  if (isAdmin) {
    if (isSegregatedAccountTable) {
      link = '/admin/accounts/accounts-activity/segregated/';
    } else if (isIonfiTable) {
      link = '/admin/accounts/accounts-activity/ionfi/';
    } else {
      link = '/admin/accounts/accounts-activity/fi/';
    }
  } else {
    if (isSegregatedAccountTable) {
      link = '/fi/accounts-dashboard/segregated-accounts-activity/';
    } else {
      link = '/fi/accounts-dashboard/accounts-activity/';
    }
  }
  return link = link + account.id;
}

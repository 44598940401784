<div *ngIf="documents.length > 0">
  <div *ngFor="let document of documents">
    <div class="d-flex justify-content-between">
        <span class="text-purple-ionfi clickable-span" (click)="downloadDocument(document.id, document.original_filename)">
          <fa-icon [classes]="['fa-fw']" icon="download"></fa-icon>
          {{ document.original_filename }}
        </span>
      <div>
      <span class="text-muted">
        ({{ "shared.display-and-upload-documents: Uploaded at" | translate }}:
        {{ document.created_at | date:'MMM d, y, h:mm a' }})
      </span>
      </div>
    </div>
  </div>
</div>

<div class="mt-4">
  <shared-files-upload class="mb-5 mt-5" *ngIf="showActionButtons"
                       (uploadFiles)="uploadedFiles($event.files)"></shared-files-upload>
</div>




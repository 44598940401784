<div *ngFor="let item of questionAnswerItems; let i = index">
  <shared-question [question]="item.questionDefinition" [formGroup]="item.formGroup" [files]="item.files" [index]="i"></shared-question>
</div>
<button class="mt-3 btn btn-success btn-sm rounded-btn ld-ext-right" type="button"
        [disabled]="!allFormGroupsValid() || working"
        [ngClass]="{'running': working}"
        (click)="submitAnswer()">
  {{ 'shared.question-block: Submit' | translate }}
  <div class="ld ld-ring ld-spin"></div>
</button>
<button *ngIf="question.allow_to_duplicate"
        class="mt-3 btn btn-sm btn-light"
        (click)="addAdditionalFormGroup()"
        ngbTooltip="{{'shared.question-block: Add another empty form' | translate}}">
  <fa-icon [classes]="['fa-fw']" icon="plus"></fa-icon>
</button>
<button *ngIf="question.allow_to_duplicate && questionAnswerItems.length > 1"
        class="mt-3 btn btn-sm btn-light"
        (click)="removeLastAdditionalFormGroup()"
        ngbTooltip="{{'shared.question-block: Remove last form' | translate}}">
  <fa-icon [classes]="['fa-fw']" icon="trash-alt"></fa-icon>
</button>

import {Injectable} from "@angular/core";
import {AdminApiService} from "@app/admin-module/services/admin.api.service";
import {RxUtilsService} from "@app/core-module/services/rx-utils.service";
import {CurrentProfileState} from "@app/core-module/states/current-profile-state.service";
import {Account} from "@shared/models/account";
import {Observable} from "rxjs";
import {distinctUntilChanged, filter, first, merge, switchMap} from "rxjs/operators";
import {Subject} from "rxjs";

@Injectable()
export class AllAccountsState {

  private accounts: Observable<Account[]>;
  private reading: Observable<boolean>;

  private readAccounts$ = new Subject<boolean>();


  constructor(private adminApiService: AdminApiService,
              private currentProfileState: CurrentProfileState,
              private rxUtilsService: RxUtilsService) {


    const initiator = this.currentProfileState.getCurrentProfileId().pipe(
      switchMap(() => this.currentProfileState.getCurrentProfile().pipe(first())),
      filter(it => it && it.user != null && it.user.isAdminFI),
      merge(this.readAccounts$));
    this.accounts = this.rxUtilsService.createGetStateValue(initiator, this.adminApiService.getAllAccounts(),
      'Error while reading accounts!', []);
    this.reading = this.rxUtilsService.createReadingInfo(initiator, this.accounts);
  }

  getAccounts(): Observable<Account[]> {
    return this.accounts;
  }

  /**
   * Returns Observable emitting only true of false, never null or an error.
   */
  getReadingAccounts(): Observable<boolean> {
    return this.reading.pipe(distinctUntilChanged());
  }

  reload() {
    this.readAccounts$.next(true);
  }
}

import {notEmpty} from "../utils/utils";

export class ScanningResult {

  public checkNumber: string;
  public checkAccountNumber: string;
  public checkRoutingAba: string;

  public front_jpg_filename: string;
  public front_tiff_filename: string;
  public back_jpg_filename: string;
  public back_tiff_filename: string;

  public isValid() {
    return notEmpty(this.checkNumber) && notEmpty(this.checkRoutingAba) && notEmpty(this.checkAccountNumber)
      && notEmpty(this.front_jpg_filename) && notEmpty(this.front_tiff_filename)
      && notEmpty(this.back_jpg_filename) && notEmpty(this.back_tiff_filename)
  }
}

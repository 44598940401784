<button type="button" *ngIf="initialDataBank" class="btn btn-sm edit-icon text-purple-ionfi" (click)="open(content)"
        [disabled]="disabled">
  <span class="ico icon-pen" ngbTooltip="{{'admin.account-addons: Edit' | translate}}"></span>
</button>
<button type="button" *ngIf="!initialDataBank" class="btn btn-sm px-3 py-1 bg-button-purple-ionfi" [ngClass]="btnClass" (click)="open(content)" [ngbTooltip]="tooltip">
  <span class="ico icon-people pr-1"></span>
  {{ label }}
</button>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 *ngIf="!initialDataBank" class="modal-title">{{ 'shared.add-wire-transfer-bank-modal: Add new Bank Profile' | translate }}</h4>
    <h4 *ngIf="initialDataBank" class="modal-title">{{ 'shared.add-wire-transfer-bank-modal: Update Bank Profile' | translate }}</h4>
  </div>
  <form [formGroup]="formGroup" (validSubmit)="c()" role="form" novalidate class="custom-select_arrow-ionfi">
    <div class="modal-body">
      <div class="form-group">
        <label>{{ 'shared.add-wire-transfer-bank-modal: Number Type' | translate }}</label><span class="text-purple-ionfi">*</span>
        <select class="form-control" type="select" formControlName="bank_number_type">
          <option *ngFor="let type of bankNumberTypes" [value]="type">{{ type | entityNumberType }}</option>
        </select>
        <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
      </div>
      <div class="form-group">
        <label>{{ 'shared.add-wire-transfer-bank-modal: Number' | translate }}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="bank_number">
        <small class="custom-validator"
               *ngIf="formGroup.errors?.accountNumber">{{ 'shared.add-wire-transfer-bank-modal: ABA Routing number should be 9 digits!' | translate }}</small>
      </div>
      <div class="form-group">
        <label>{{ 'shared.add-wire-transfer-bank-modal: Name' | translate }}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="bank_name">
      </div>
      <div class="form-group">
        <label>{{ 'shared.add-wire-transfer-bank-modal: Email' | translate }}</label>
        <input class="form-control" formControlName="email">
      </div>
      <div class="form-group">
        <label>{{ 'shared.add-wire-transfer-bank-modal: Address' | translate }}</label>
        <input class="form-control" formControlName="address">
      </div>
      <div class="form-group">
        <label>{{ 'shared.add-wire-transfer-bank-modal: City' | translate }}</label>
        <input class="form-control" formControlName="city">
      </div>
      <div class="form-group">
        <label>{{ 'shared.add-wire-transfer-bank-modal: Zip' | translate }}</label>
        <input class="form-control" formControlName="zip">
      </div>
      <shared-inputs-country [countryControlName]="'country'" [stateControlName]="'state'"
                             countryLabel="{{'shared.add-wire-transfer-bank-modal: Country' | translate}}"
                             stateLabel="{{'shared.add-wire-transfer-bank-modal: State' | translate}}"
                             [form]="formGroup"></shared-inputs-country>
    </div>
    <div class="modal-footer">
      <button type="button" aria-label="Close" (click)="d()" class="btn cancel-button">{{'shared.add-wire-transfer-bank-modal: Cancel' | translate}}</button>
      <button *ngIf="!initialDataBank" type="submit"
              class="btn purple-button">{{ 'shared.add-wire-transfer-bank-modal: Add' | translate }}</button>
      <button *ngIf="initialDataBank" type="submit"
              class="btn purple-button">{{ 'shared.add-wire-transfer-bank-modal: Update' | translate }}</button>
    </div>
  </form>
</ng-template>


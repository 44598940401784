<div class="d-flex my-3" *ngIf="balance | async as b">
  <div class="current-balance">
    <div class="card d-flex justify-content-between">
      <div class="row">
        <div class="col-4">
          <p class="balance-text-display">{{'fi.segregated-account-details: Current Balance' | translate}}</p>
          <p class="currency-design-display">{{ getCurrentBalance(b) }}</p>
        </div>
        <div *ngIf="braidBalance | async as braidAccountBalance" class="col-8">
          <div class="type-pending text-center" *ngIf="!checkBalance(braidAccountBalance.accountBalance, b.current_balance)">
            <span>
              {{
                'shared.account-details-balance: The Current Balance in Braid is {balance} which is not matching with the current balance in IONFI.' | translate: {balance: getBalanceString(braidAccountBalance.accountBalance, b.acc_currency)}
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="total-balance">
    <div class="card d-flex justify-content-between">
      <div class="row">
        <div class="col-4">
          <p class="balance-text-display">{{'fi.segregated-account-details: Available Balance' | translate}}</p>
          <p class="currency-design-display">{{ getAvailableBalance(b) }}</p>
        </div>
        <div *ngIf="braidBalance | async as braidAccountBalance" class="col-8">
          <div class="type-pending text-center" *ngIf="!checkBalance(braidAccountBalance.availableBalance, b.available_balance)">
            <span>
              {{
                'shared.account-details-balance: The Available Balance in Braid is {balance} which is not matching with the available balance in IONFI.' | translate: {balance: getBalanceString(braidAccountBalance.availableBalance, b.acc_currency)}
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {Pipe, PipeTransform} from '@angular/core';
import {getStates} from '@app/shared-module/utils/utils';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {

  constructor() {

  }

  transform(stateCode: string, country: string, type?: string): string {
    const found = getStates(country).find(it => it.abbreviation === stateCode);
    return found ? type === 'abbreviation' ? found.abbreviation : found.name : '';
  }
}

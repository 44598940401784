import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Person} from "@shared/models/person";
import {getPersonName} from "@shared/utils/utils";

@Pipe({
  name: 'personName'
})
export class PersonNamePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(person: Person, args?: any): any {
    return getPersonName(person);
  }

}

import {FinancialInstitution} from "./financial-institution";
import {DbModel} from "./db.model";
import {UsersType} from "./users-type";
import {Profile} from "./profile";

export class User extends DbModel {
    first_name: string;
    last_name: string;
    email: string;
    users_type_id: number;
    fi_id: number;
    scanner_ip: string;
    scanner_port: number;
    scan_through_browser: boolean;
    phone: string;
    date_of_birth: string;
    position_in_company: string;
    office_phone_number: string;
    cellphone_number: string;
    approval_limit: number; //should not be applied to INCOMING wires
    include_in_mailing_lists: boolean;
    is_payment_supervisor: boolean;
    deleted: boolean;

    //generated fields
    fi: FinancialInstitution;
    profile: Profile;
    userType: UsersType;
    isCustomer: boolean;
    isBankUser: boolean;
    isAdministrator: boolean;
    isOfficer: boolean;
    isAuditor: boolean;
    isAdminFI: boolean;

    // frontend generated fields
    full_name: string;
}

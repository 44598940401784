import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {first, map, merge, switchMap, takeUntil, tap} from 'rxjs/operators';
import {checkIsFiltered, createFragment} from '@app/shared-module/utils/utils';
import {FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {of} from 'rxjs';
import {getParamsFromFragment, isFilterTypeDate} from '@shared/utils/utils';
import {Subject} from 'rxjs';
import {UrlFragmentsParser} from '@app/shared-module/utils/url-fragments.parser';

@Injectable()
export abstract class AbstractFilterableTableComponent implements OnInit, OnDestroy {

  isFiltered = false;
  filtersFromUrlSet$: Observable<void>;
  destroy$ = new Subject<boolean>();

  protected constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected location: Location) {
  }

  ngOnInit() {
    this.filtersFromUrlSet$ = this.setFiltersFromURL$();
    this.filtersFromUrlSet$.pipe(
      tap(() => this.checkFiltered()),
      switchMap(() => this.setUpdatesOnFormChange())
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  protected addFragmentToTheUrl(fragment: string) {
    this.refreshUrlFromFragments(new UrlFragmentsParser(fragment).getValidFragment());
  }

  protected refreshUrlFromFragments(fullFragmentUrl: string) {
    let generatedURL = '';

    generatedURL = this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      fragment: fullFragmentUrl
    }).toString();
    this.location.go(generatedURL);
    return generatedURL;
  }

  resetFilter() {
    this.getFormGroup().reset();
    this.clearFragmentsFromSessionStorage();
    this.addFragmentToTheUrl(undefined);
  }

  protected checkFiltered() {
    this.isFiltered = checkIsFiltered(this.getFormGroup());
  }

  /**
   * Helper function for checkbox values.
   */
  protected getCheckedValuesInFormatForForm(allValues: string[], checkedValues: string[]): string[] {
    const result = [];
    for (const value of allValues) {
      result.push(checkedValues.includes(value));
    }
    return result;
  }

  /**
   * Helper function for checkbox values.
   */
  protected getCheckedValuesAsList(allValues: any[], checkBoxValues: boolean[]) {
    const result = [];
    for (let i = 0; i < allValues.length; i++) {
      if (checkBoxValues[i]) {
        result.push(allValues[i]);
      }
    }
    return result;
  }

  protected getValueObservable(controlName: string): Observable<string> {
    return of('').pipe(
      merge(this.filtersFromUrlSet$.pipe(map(() => this.getFormGroup().controls[controlName].value))),
      merge(this.getFormGroup().controls[controlName].valueChanges));
  }

  protected getBooleanListValueObservable(controlName: string): Observable<boolean[]> {
    return of([]).pipe(
      merge(this.filtersFromUrlSet$.pipe(map(() => this.getFormGroup().controls[controlName].value))),
      merge(this.getFormGroup().controls[controlName].valueChanges));
  }

  protected getNumberValueObservable(controlName: string): Observable<number> {
    return of(null).pipe(
      merge(this.filtersFromUrlSet$.pipe(map(() => this.getFormGroup().controls[controlName].value))),
      merge(this.getFormGroup().controls[controlName].valueChanges));
  }

  protected getDateValueObservable(controlName: string): Observable<Date> {
    return of(null).pipe(
      merge(this.filtersFromUrlSet$.pipe(map(() => this.getFormGroup().controls[controlName].value))),
      merge(this.getFormGroup().controls[controlName].valueChanges)
    );
  }

  protected setUpdatesOnFormChange(): Observable<void> {
    return this.getFormGroup().valueChanges.pipe(
      takeUntil(this.destroy$),
      tap(formValues => {
        this.checkFiltered();
        const fragment = createFragment(formValues);
        this.addFragmentToTheUrl(fragment);
        this.saveFilterQueryInStorage(fragment);
      })
    );
  }

  protected setFiltersFromURL$(): Observable<void> {
    return this.activatedRoute.fragment.pipe(
      first(),
      tap((fragmentFromRoute) => {
        const fragment: string = this.getFragmentFromURLOrSessionStorage(fragmentFromRoute);
        if (fragment) {
          const params = getParamsFromFragment(fragment);
          for (const param of params) {
            if (this.getFormGroup().controls[param.key]) {
              if (Object.keys(isFilterTypeDate).includes(param.key)) {
                this.getFormGroup().controls[param.key].patchValue(new Date(param.values[0]));
              } else if (param.values.length === 1 && !isNaN(Number(param.values[0]))) {
                this.getFormGroup().controls[param.key].patchValue(Number(param.values[0]));
              } else {
                this.getFormGroup().controls[param.key].patchValue(param.values.length === 1 ? param.values[0] : param.values);
              }
            }
          }
        }
      }),
      map(it => null)
    );
  }

  getURLWithoutFragments(): string {
    return this.router.url.split('#')[0];
  }

  getFragmentsFromSessionStorage(): string {
    return window.sessionStorage.getItem(this.getURLWithoutFragments());
  }

  saveFilterQueryInStorage(fragment: string): void {
    window.sessionStorage.setItem(this.getURLWithoutFragments(), new UrlFragmentsParser(fragment).getValidFragment());
  }

  clearFragmentsFromSessionStorage(): void {
    window.sessionStorage.removeItem(this.getURLWithoutFragments());
  }

  getFragmentFromURLOrSessionStorage(fragmentFromRoute: string): string {
    const fragments = fragmentFromRoute || this.getFragmentsFromSessionStorage();
    // workout url fragments and add them to url
    this.refreshUrlFromFragments(new UrlFragmentsParser(fragments).getValidFragment());
    return fragments;
  }

  abstract getFormGroup(): FormGroup;
}

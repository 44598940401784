import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {CountriesService} from '@app/core-module/services/countries.service';
import {QuestionDefinition} from '@shared/models/question-definition';
import {getStates} from '@app/shared-module/utils/utils';
import {Country_ISO} from '@shared/models/country_iso';

@Component({
  selector: 'shared-question-details-modal',
  templateUrl: './question-details-modal.component.html',
  styleUrls: ['./question-details-modal.component.scss']
})
export class QuestionDetailsModalComponent implements OnInit {

  @Input()
  question: QuestionDefinition;

  @Output()
  downloadPart = new EventEmitter<string>();

  countries: Observable<Country_ISO[]>;

  constructor(private modalService: NgbModal,
              private alertsService: AlertsService,
              private countriesService: CountriesService) {
  }

  ngOnInit() {
    this.countries = this.countriesService.getCountries();
  }

  getRegionsForCountry(countryCode: string) {
    return getStates(countryCode);
  }

  open(content) {
    this.modalService.open(content).result.then(() => {
    }, (reason) => {
    });
  }

  downloadFile(filename: string) {
    this.downloadPart.emit(filename);
  }

  trimTimestampFromFilename(filename: string): string {
    return filename.slice(filename.indexOf('-') + 1);
  }
}


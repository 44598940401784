import {Component, Input, OnInit} from '@angular/core';
import {Transaction} from '@shared/models/transaction';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'display-transactions-modal',
  templateUrl: './display-transactions-modal.component.html',
  styleUrls: ['./display-transactions-modal.component.scss']
})
export class DisplayTransactionsModalComponent implements OnInit {

  @Input()
  transactions: Transaction[];

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  open(content) {
    this.modalService.open(content, {size: 'xl' as 'lg'}).result.then(() => {
      // do nothing
    }, (reason) => {
      // do nothing
    });
  }

}

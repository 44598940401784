import {DbModel} from "./db.model";
import {User} from "./user";
import {Person} from "./person";
import {WireTransferBank} from "./wire-transfer-bank";
import {Account} from './account';

export class KycProfile extends DbModel {
    on_boarded_by_id: number;
    comment_box: string;
    braid_individual_id: number;
    braid_business_id: number;

    //generated
    on_boarded_by: User;
    persons: Person[];
    wireTransferBanks: WireTransferBank[];
    masterPerson: Person;
    segregateAccount: Account;
}

import {Component, Input} from "@angular/core";
import {Observable} from "rxjs";
import {Balance} from "@shared/models/balance";
import {CurrencyPipe} from '@angular/common';
import {BraidAccountBalance} from '@shared/models/braid/account';

@Component({
  selector: 'shared-account-details-balance',
  templateUrl: './account-details-balance.component.html',
  styleUrls: ['./account-details-balance.component.scss']
})
export class AccountDetailsBalance {
  @Input()
  balance: Observable<Balance>;
  @Input()
  braidBalance: Observable<BraidAccountBalance>;
  constructor(private currencyPipe: CurrencyPipe) {
  }
  getCurrentBalance(b: Balance) {
    if (b && b.current_balance && b.current_balance !== 0) {
      return this.currencyPipe.transform(b.current_balance, b.acc_currency);
    }
    return '-';
  }
  getAvailableBalance(b: Balance) {
    if (b && b.available_balance && b.available_balance !== 0) {
      return this.currencyPipe.transform(b.available_balance, b.acc_currency);
    }
    return '-';
  }
  getBalanceString(balance: string, currency: string = 'USD') {
    let balanceNumber = Number(balance);
    if (isNaN(balanceNumber)) {
      balanceNumber = 0;
    }
    return this.currencyPipe.transform(balanceNumber, currency);
  }
  checkBalance(balance: string, ionfiBalance: number) {
    if (!ionfiBalance) {
      ionfiBalance = 0;
    }
    const balanceNumber = Number(balance);
    if (isNaN(balanceNumber)) {
      return false;
    }
    return ionfiBalance === balanceNumber;
  }
}

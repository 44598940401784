import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnsweredQuestion, QuestionDefinition} from '@shared/models/question-definition';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {
  allFormGroupsValid,
  createFormGroupFromDefinition,
  createFormGroupFromDefinitionWithInitialData,
  parseFormDataForSubmit, QuestionAnswerItem, QuestionFormData
} from '@app/shared-module/utils/question.utils';
import {ApiService} from '@app/core-module/services/api.service';


@Component({
  selector: 'shared-edit-question-answer-modal',
  templateUrl: './edit-question-answer-modal.component.html',
  styleUrls: ['./edit-question-answer-modal.component.scss']
})
export class EditQuestionAnswerModalComponent implements OnInit {

  @Input()
  pendingQuestion: QuestionDefinition; // should be non-null if there are no answered question blocks

  @Input()
  answeredQuestion: AnsweredQuestion;

  @Input()
  working = false;

  @Output()
  answerQuestionEventEmitter = new EventEmitter<QuestionFormData>();

  questionAnswerItems: QuestionAnswerItem[];
  files: { sub_id: string, files: File[] }[][];
  openedModal: NgbModalRef;

  constructor(private modalService: NgbModal, private apiService: ApiService) {
  }

  ngOnInit() {
    this.questionAnswerItems = [];
    if (this.pendingQuestion) {
      this.questionAnswerItems.push({
        formGroup: createFormGroupFromDefinition(this.pendingQuestion),
        questionDefinition: this.pendingQuestion,
        files: []
    });
    } else if (this.answeredQuestion && this.answeredQuestion.blocks.length) {
      for (const block of this.answeredQuestion.blocks) {
        this.questionAnswerItems.push({
          formGroup: createFormGroupFromDefinitionWithInitialData(block),
          questionDefinition: block,
          files: []
        });
      }
    } else {
      throw new Error('invalid state');
    }
    this.files = [];
    this.files.push([]);
  }

  open(content) {
    this.openedModal = this.modalService.open(content);
    this.openedModal.result.then(() => {
    }, (reason) => {
    });
  }

  addAdditionalFormGroup() {
    const original = this.getAnyQuestionDefinition();
    const question = Object.assign({}, original);
    question.sub_questions = original.sub_questions.map(it => Object.assign({}, it));
    question.mandatory = false;
    question.sub_questions.forEach(it => {
      it.mandatory = false;
    });
    this.questionAnswerItems.push({
      formGroup: createFormGroupFromDefinition(question),
      questionDefinition: question,
      files: []
    });
  }

  removeLastAdditionalFormGroup() {
    this.questionAnswerItems.splice(this.questionAnswerItems.length - 1);
  }

  allFormGroupsValid() {
    return allFormGroupsValid(this.questionAnswerItems.map(it => it.formGroup));
  }

  submitAnswer() {
    const questionFormData = parseFormDataForSubmit(
      this.questionAnswerItems.map(it => it.formGroup),
      this.questionAnswerItems.map(it => it.files));
    this.openedModal.close();
    this.answerQuestionEventEmitter.emit(questionFormData);
  }

  getAnyQuestionDefinition() {
    return this.pendingQuestion ? this.pendingQuestion : this.answeredQuestion.blocks[0]; // any of the blocks has the same definition
  }
}

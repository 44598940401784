import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '@app/core-module/services/authentication.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {Profile} from '@shared/models/profile';
import {adjustSidebar, toggleSidebar} from '@app/shared-module/utils/sitebar.utils';
import {Observable} from 'rxjs';
import {LangInfo, LanguageService} from '@app/core-module/services/language.service';
import {getLogoPath} from '@app/shared-module/utils/utils';
import {ThemeService} from '@app/shared-module/theme-service/theme.service';


@Component({
  selector: 'shared-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  /**
   * Can be considered that is never null, as this component is displayed only if
   * currentProfile is not null
   */
  currentProfile: Observable<Profile>;

  showInvalidCredentialsMessage = false;
  showErrorOnLoggingMessage = false;
  logging = false;
  selectedLang: Observable<LangInfo>;
  flag: string;
  logoPath: string;

  constructor(private currentProfileState: CurrentProfileState,
              private authenticationService: AuthenticationService,
              private router: Router,
              private languageService: LanguageService,
              public themeService: ThemeService) {
    this.logoPath = getLogoPath();
    this.currentProfile = this.currentProfileState.getCurrentProfile();
    adjustSidebar(router);
  }

  ngOnInit() {
    this.selectedLang = this.languageService.getSelectedLanguage$();
  }

  getNameForBar(profile: Profile): string {
    if (profile.user && profile.user.first_name) {
      return profile.user.first_name + (profile.user.last_name ? ' ' + profile.user.last_name : '');
    }
    if (profile.selfRegisteredUser && profile.selfRegisteredUser.first_name) {
      return profile.selfRegisteredUser.first_name + (profile.selfRegisteredUser.last_name
        ? ' ' + profile.selfRegisteredUser.last_name
        : '');
    }
    return '';
  }

  login(email: string, password: string, tfaCode: string) {
    this.logging = true;
    this.showInvalidCredentialsMessage = false;
    this.showErrorOnLoggingMessage = false;
    this.authenticationService.login(email, password, tfaCode, null).subscribe(() => {
      this.logging = false;
    }, (error: HttpErrorResponse) => {
      if (error.status === 401) {
        this.showInvalidCredentialsMessage = true;
      } else {
        this.showErrorOnLoggingMessage = true;
      }
      this.logging = false;
    });
  }

  logout() {
    this.authenticationService.logout();
    // Navigate to 'Log in' page
    this.router.navigateByUrl('/auth/login');
  }

  toggleSidebar() {
    toggleSidebar();
  }

  setLanguage(langCode: string) {
    this.languageService.setLanguage(langCode);
  }

  getAvailableLanguages(): { text: string, value: string, img: string }[] {
    return this.languageService.getLanguages();
  }

}

export class DbModel {
    id: number | undefined;
    created_at: Date | undefined;
    updated_at: Date | undefined;
}

export function clearBeforeInsert(model: DbModel, dontResetDates = false) {
    model.id = undefined;
    if (!dontResetDates || !model.created_at) {
        model.created_at = new Date();
    }
    if (!dontResetDates || !model.updated_at) {
        model.updated_at = new Date();
    }
}

export function prepareDatesBeforeUpdate(model: DbModel) {
    model.created_at = undefined;
    model.updated_at = new Date();
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'newLineRegex'})
export class NewLineRegexPipe implements PipeTransform {
  transform(value: string, newLineRegExArray: string[] = ['\n']): string {
    if (!value) {
      return value;
    }
    newLineRegExArray.forEach(newLineRegEx => {
      value = value.replace(new RegExp(newLineRegEx, 'g'), '<br>');
    });
    return value;
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionDefinition} from '@shared/models/question-definition';
import {
  allFormGroupsValid,
  createFormGroupFromDefinition,
  parseFormDataForSubmit, QuestionAnswerItem, QuestionFormData,
} from '@app/shared-module/utils/question.utils';

@Component({
  selector: 'shared-question-block',
  templateUrl: './question-block.component.html',
  styleUrls: ['./question-block.component.scss']
})
export class QuestionBlockComponent implements OnInit {

  @Input()
  question: QuestionDefinition;

  @Output()
  answerQuestionEventEmitter = new EventEmitter<QuestionFormData>();

  questionAnswerItems: QuestionAnswerItem[];

  @Input()
  working = false;

  // not used anywhere
  @Input()
  progressBar = 0;

  constructor() {

  }

  ngOnInit() {
    this.questionAnswerItems = [];
    this.questionAnswerItems.push({
      formGroup: createFormGroupFromDefinition(this.question),
      questionDefinition: this.question,
      files: []
    });
  }

  addAdditionalFormGroup() {
    this.questionAnswerItems.push({
      formGroup: createFormGroupFromDefinition(this.question),
      questionDefinition: this.question,
      files: []
    });
  }

  removeLastAdditionalFormGroup() {
    this.questionAnswerItems.splice(this.questionAnswerItems.length - 1);
  }

  allFormGroupsValid() {
    return allFormGroupsValid(this.questionAnswerItems.map(it => it.formGroup));
  }

  submitAnswer() {
    const questionFormData = parseFormDataForSubmit(
      this.questionAnswerItems.map(it => it.formGroup),
      this.questionAnswerItems.map(it => it.files));
    this.answerQuestionEventEmitter.emit(questionFormData);
  }

}

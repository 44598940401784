<input class="hidden-input" multiple (change)="fileUpload( $event)" type="file" [id]="uploadId"/>
<ngx-file-drop [disabled]="disabled" (onFileDrop)="dropped($event)" dropZoneClassName="filedrop">
  <ng-template ngx-file-drop-content-tmp class="d-flex flex-row">
    <div class="ico icon-upload text-purple-ionfi h4"></div>
    <div class="h4 text-center font-weight-light">{{ 'shared.document-upload-modal: Drag & Drop' | translate }}</div>
    <div class="h6 text-center font-weight-light">{{ 'shared.document-upload-modal: Drop files or click here to upload' | translate }}</div>
    <button type="button" [disabled]="disabled" (click)="openFileChooseDialog()" class="bg-purple-ionfi-16 pointer border-4 px-4 py-2 border-0 text-purple-ionfi m-2">
      Upload
    </button>
  </ng-template>
</ngx-file-drop>

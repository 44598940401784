<div *ngIf="entity.address">
  {{ entity.address }}
</div>
<div>
  {{ constructZipCityStateString(entity.zip, entity.city, (entity.state | state:entity.country:'abbreviation')) }}
</div>
<div *ngIf="entity.country">
  {{entity.country | country | async}}
</div>
<div *ngIf="entity['address_line_1']">
  {{ entity['address_line_1'] }}
</div>
<div *ngIf="entity['bank_address_line_1']">
  {{ entity['bank_address_line_1'] }}
</div>
<div *ngIf="entity['address_line_2']">
  {{ entity['address_line_2'] }}
</div>
<div *ngIf="entity['bank_address_line_2']">
  {{ entity['bank_address_line_2'] }}
</div>
<div *ngIf="entity['address_line_3']">
  {{ entity['address_line_3'] }}
</div>
<div *ngIf="entity['bank_address_line_3']">
  {{ entity['bank_address_line_3'] }}
</div>

import {Component, Input, OnInit} from '@angular/core';
import {User} from '@shared/models/user';
import {Observable} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import {UsersType} from "@shared/models/users-type";
import {UserTypesState} from "@app/core-module/states/user-types.state";

@Component({
  selector: 'shared-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @Input()
  user: Observable<User>;

  @Input()
  connectToScanner: boolean = false;

  userTypes: Observable<UsersType[]>

  constructor(public translateService: TranslateService,
              private userTypesState: UserTypesState) {
  }

  ngOnInit() {
    this.userTypes = this.userTypesState.getUserTypes();
  }

}

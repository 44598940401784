import {Component, Input, OnInit} from '@angular/core';
import {WireTransferBank, WireTransferBankWithAddressLines} from '@shared/models/wire-transfer-bank';
import {Person, PersonWithAddressLines} from '@shared/models/person';
import {constructZipCityStateString} from '@shared/utils/addresses';

@Component({
  selector: 'shared-wire-transfer-entity-address',
  templateUrl: './wire-transfer-entity-address.component.html',
  styleUrls: ['./wire-transfer-entity-address.component.scss']
})
export class WireTransferEntityAddressComponent implements OnInit {

  @Input() entity: WireTransferBank | Person | WireTransferBankWithAddressLines | PersonWithAddressLines;

  constructor() {
  }

  ngOnInit() {
  }

  constructZipCityStateString(zip: string, city: string, state: string) {
    return constructZipCityStateString(zip, city, state);
  }
}

<div class="card mt-3" [ngClass]="{'h-100' : headerTitleOn}"
       id="collapseEntityDetails"
       [formGroup]="formGroup"
       [ngbCollapse]="isEntityCollapsed">
  <div class="card-header" *ngIf="headerTitleOn">{{ headerTitle }}</div>
    <div *ngIf="!wireTransferEntity && !disableInput" class="form-group card-body">
      <ng-template #rt let-r="result" let-t="term" >
        <div *ngIf="isSegregateAccountInput">
          <span *ngIf="r.account.acc_number">{{ r.account.acc_number }} </span> <span *ngIf="r.account.acc_name">( {{ r.account.acc_name }} )</span>
        </div>
        <div *ngIf="!isSegregateAccountInput">
          <span
            class="font-italic font-weight-light">{{ (r.id_type_id ? r.id_type.label : r.bank_number_type) | entityNumberType }}
            :</span>
          <span>{{ r.id_number ? r.id_number : r.bank_number }}</span>
          (
          <span *ngIf="!r.bank_name">{{ r | personName }}</span>
          <span *ngIf="r.bank_name">{{ r.bank_name }}</span>
          <span *ngIf="r.email">{{ r.email }}</span>
          )
        </div>
      </ng-template>
      <div class="input-group" *ngIf="!disableInput">
        <input class="form-control form-control-sm input-group-prepend border-right-0"
               [formControlName]="entityName"
               [ngbTypeahead]="search"
               [resultTemplate]="rt"
               [inputFormatter]="formatter"
               placeholder="{{isSegregateAccountInput ? ('shared.input-wire-transfer-entity:Account Number or Name...' | translate) :
                                                           ('shared.input-wire-transfer-entity:ID Number or Name...' | translate) }}"
               (selectItem)="selectedWireTransferEntity($event.item)"/>
        <div class="input-group-text input-group-append">
          <fa-icon [classes]="['fa-fw']" icon="search"></fa-icon>
        </div>
      </div>
    </div>
    <div *ngIf="wireTransferEntity" class="card-body">
      <div class="col">
        <div *ngIf="!disabled || !disableInput" class="float-right">
          <div class="d-flex justify-content-end">
            <div>
              <shared-add-person-modal *ngIf="wireTransferEntity && isPerson(wireTransferEntity) && !fi_id && !isSegregateAccountInput"
                                       [initialDataPerson]="wireTransferEntity"
                                       [allowedPersonTypes]="['FULL_PROFILE', 'NON_CUSTOMER']"
                                       (addPerson)="onPersonEdit($event)">
              </shared-add-person-modal>
              <button class="btn btn-light btn-sm" *ngIf="wireTransferEntity"
                      (click)="resetWireTransferEntity()">
                <fa-icon [classes]="['fa-fw']" icon="times"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="wireTransferEntity">
          <wire-transfer-entity-details [entityType]="entityType"
                                        [showCard]="false"
                                        [wireTransferEntity]="wireTransferEntity"
                                        [isSegregateAccountEntity]="isSegregateAccountInput"
                                        [showFundingAccountInfo]="isSegregateAccountInput"></wire-transfer-entity-details>
          <div class="form-group mt-2" *ngIf="ddaNumberEntityName">
            <b>{{ 'shared.input-wire-transfer-entity: DDA Number' | translate }}</b>
            <input class="form-control" [formControlName]="ddaNumberEntityName">
          </div>
        </div>
      </div>
    </div>
  <div *ngIf="disableInput && !wireTransferEntity" class="row justify-content-center pt-2">
    <h6 class="status-rejected">
      {{disableInputMessage}}
    </h6>
  </div>
</div>

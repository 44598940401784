import {Pipe, PipeTransform} from "@angular/core";
import {IdTypesState} from "@app/core-module/states/id-types.state";
import {first, map} from "rxjs/operators";
import {Observable} from "rxjs";

@Pipe({
  name: "idTypeCode"
})
export class IdTypeCodePipe implements PipeTransform {

  constructor(private idTypesState: IdTypesState) {

  }


  transform(id: number): Observable<string> {
    return this.idTypesState.getIdTypes().pipe(
      first(),
      map(idTypes => idTypes.find(it => it.id === id)),
      map(idType => idType ? idType.code : '')
    )
  }
}

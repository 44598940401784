import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerDocument} from '@shared/models/customer-document';

@Component({
  selector: 'shared-wire-transfer-supportive-documentation',
  templateUrl: './wire-transfer-supportive-documentation.component.html',
  styleUrls: ['./wire-transfer-supportive-documentation.component.scss']
})
export class WireTransferSupportiveDocumentationComponent implements OnInit {

  @Input()
  documents: CustomerDocument[];

  @Input()
  showActionButtons = true;

  @Output()
  reloadDocumentsEmitter = new EventEmitter();

  @Output()
  downloadDocumentEmitter = new EventEmitter<{
    documentId: number;
    originalFilename: string;
  }>();

  @Output()
  uploadFilesEmitter = new EventEmitter<File[]>();

  constructor() {
  }

  ngOnInit() {
  }

  downloadDocument(documentId: number, originalFilename: string) {
    this.downloadDocumentEmitter.emit({ documentId, originalFilename });
  }

  uploadedFiles(files: File[]) {
    this.uploadFilesEmitter.emit(files);
  }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription, Observable} from 'rxjs';
import {PaymentEntity} from '@shared/models/payment.entity';
import {debounceTime, flatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {WireTransferBank} from '@shared/models/wire-transfer-bank';
import {FormGroup} from '@angular/forms';
import {AdminApiService} from '@app/admin-module/services/admin.api.service';
import {ApiService} from '@app/core-module/services/api.service';
import {Person} from '@shared/models/person';
import {PersonsState} from '@app/fi-module/states/persons.state';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {TranslateService} from '@ngx-translate/core';
import {Account} from '@shared/models/account';

@Component({
  selector: 'input-wire-transfer-entity',
  templateUrl: './input-wire-transfer-entity.component.html',
  styleUrls: ['./input-wire-transfer-entity.component.scss']
})
export class InputWireTransferEntityComponent implements OnInit, OnDestroy {

  @Input()
  headerTitle: string;

  @Input()
  headerTitleOn = false;

  @Input()
  mandatory = true;

  @Input()
  entityType: 'person' | 'bank';

  @Input()
  formGroup: FormGroup;

  @Input()
  entityName: string;

  @Input()
  isCollapsible = false;

  @Input()
  fi_id?: number;

  @Input()
  disabled = false;

  @Input()
  disableInput = false;

  @Input()
  disableInputMessage: string;

  @Input()
  ddaNumberEntityName?: string;

  @Input()
  onlyFiClients = false;

  @Input()
  isSegregateAccountInput: boolean;

  @Input()
  selectedAccount: Account;

  private formValueChangesSubscription: Subscription;

  @Output()
  wireTransferEntityChanged:
    EventEmitter<{entityName: string, wireTransferEntity: Person | WireTransferBank | Account}> =
    new EventEmitter<{entityName: string, wireTransferEntity: Person | WireTransferBank | Account}>();

  isEntityCollapsed: boolean;
  wireTransferEntity: Person | WireTransferBank;

  search: (text$: Observable<string>) => Observable<PaymentEntity[]>;
  formatter: (x: WireTransferBank | Person) => string;

  constructor(private adminApiService: AdminApiService,
              private apiService: ApiService,
              private personsState: PersonsState,
              private alertsService: AlertsService,
              private translateService: TranslateService) {
    this.search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(200),
        flatMap(term => term === '' ? of([]) : this.searchForWireTransferEntity(term)),
        map(result => result.slice(0, 10))
      );
    this.formatter = (x: Person | WireTransferBank) => {
      return this.entityType === 'person' ? (x as Person).id_number : (x as WireTransferBank).bank_number;
    };
  }

  ngOnInit() {
    this.formValueChangesSubscription = this.formGroup.get(this.entityName).valueChanges.subscribe(value => {

      if (value && !value.id) {
        return; // this means the value is string value that the user has typed, it is not really entity that should be considered
      }
      this.wireTransferEntity = value;
    });
    this.wireTransferEntity = this.formGroup.get(this.entityName).value;
    this.isEntityCollapsed = this.isCollapsible;
  }

  ngOnDestroy() {
    this.formValueChangesSubscription.unsubscribe();
  }

  searchForWireTransferEntity(searchString: string): Observable<Person[] | WireTransferBank[]> {
    if (this.fi_id) {
      return this.entityType === 'person' ?
        this.adminApiService.getPersonsAllowedForWire(searchString, this.fi_id) :
        this.adminApiService.getWireTransferBankByQuery(searchString, this.fi_id);
    } else {
      return this.entityType === 'person' ?
        this.apiService.getPersonsAllowedForWire(searchString,
          {
            onlyFiClients: this.onlyFiClients,
            isSegregateAccountTransfer: this.isSegregateAccountInput,
            upstreamBankId: this.getUpstreamBankIdFromSelectedAccount()
          }) : this.apiService.getWireTransferBankByQuery(searchString);
    }
  }

  private getUpstreamBankIdFromSelectedAccount() {
    if (this.selectedAccount && this.selectedAccount.upstream_bank_id) {
      return this.selectedAccount.upstream_bank_id;
    }
    return 0;
  }

  resetWireTransferEntity() {
    this.wireTransferEntity = null;
    this.formGroup.get(this.entityName).patchValue(null);
    this.wireTransferEntityChanged.emit({entityName: this.entityName, wireTransferEntity: null});
  }

  selectedWireTransferEntity(value: WireTransferBank | Person) {
    this.formGroup.get(this.entityName).patchValue(value);
    if (this.isPerson(value)) {
      this.wireTransferEntity = <Person>value;
      this.wireTransferEntityChanged.emit({entityName: this.entityName, wireTransferEntity: value});
    } else if (value.hasOwnProperty('bank_number')) {
      this.wireTransferEntity = <WireTransferBank>value;
      this.wireTransferEntityChanged.emit({entityName: this.entityName, wireTransferEntity: value});
    }
  }
  onPersonEdit(person: Person) {
    this.wireTransferEntity = <Person>person;
    this.wireTransferEntityChanged.emit({entityName: this.entityName, wireTransferEntity: person});
    this.personsState.addPerson(person, {toDuplicate: true})
      .subscribe((result) => {
        this.formGroup.get(this.entityName).patchValue(result);
        this.alertsService.addAlert({
          type: 'success',
          message: this.translateService.instant('shared.wire-transfer-editing-form: Successfully edited Person')
        });
    }, (err) => {
      this.alertsService.addAlert({
        type: 'danger',
        message: this.translateService.instant('shared.wire-transfer-editing-form: Something went wrong with editing Person')
      });
    });
  }

  isPerson(value: WireTransferBank | Person): boolean {
    return value.hasOwnProperty('id_number');
  }
}

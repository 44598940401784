import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {TranslateService} from '@ngx-translate/core';
import {notRequiredEmailValidator, validateBankAccountNumber} from '@app/shared-module/utils/validators.utils';
import {WireTransferBank} from '@shared/models/wire-transfer-bank';
import {KycProfile} from "@shared/models/kyc-profile";

@Component({
  selector: 'add-wire-transfer-bank-modal',
  templateUrl: './add-wire-transfer-bank-modal.component.html',
  styleUrls: ['./add-wire-transfer-bank-modal.component.scss']
})
export class AddWireTransferBankModalComponent implements OnInit {

  @Input()
  disabled = false;

  @Input()
  btnClass: 'btn-primary' | 'btn-light' = 'btn-light';

  @Input()
  label: string;

  @Input()
  tooltip: 'Edit';

  @Input()
  initialDataBank?: WireTransferBank;

  @Input()
  profile?: KycProfile;

  @Output()
  public addWireTransferBank = new EventEmitter<WireTransferBank>();

  formGroup: FormGroup;
  bankNumberTypes: string[] = ['ABA_ROUTING_NUMBER', 'DDA_NUMBER', 'SWIFT_CODE', 'SORT_CODE', 'BANK_CODE', 'OTHER'];

  constructor(
    private modalService: NgbModal,
    private alertsService: AlertsService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      bank_number_type: new FormControl(this.initialDataBank ? this.initialDataBank.bank_number_type : 'ABA_ROUTING_NUMBER', Validators.required),
      bank_number: new FormControl(this.initialDataBank ? this.initialDataBank.bank_number : null, Validators.required),
      bank_name: new FormControl(this.initialDataBank ? this.initialDataBank.bank_name : null, Validators.required),
      email: new FormControl(this.initialDataBank ? this.initialDataBank.email : null, notRequiredEmailValidator),
      address: new FormControl(this.initialDataBank ? this.initialDataBank.address : null),
      city: new FormControl(this.initialDataBank ? this.initialDataBank.city : null),
      zip: new FormControl(this.initialDataBank ? this.initialDataBank.zip : null),
      state: new FormControl(this.initialDataBank ? this.initialDataBank.state : null),
      country: new FormControl(this.initialDataBank ? this.initialDataBank.country : null),
    }, {
      validators: validateBankAccountNumber()
    });
  }

  open(content) {
    this.modalService.open(content).result.then(() => {
      this.addWireTransferBank.emit(<WireTransferBank>{
        id: this.initialDataBank ? this.initialDataBank.id : null,
        ...this.formGroup.getRawValue()
      });
      if (!this.initialDataBank) {
        this.formGroup.reset();
      }
    }, (reason) => {
      // do nothing
    });
  }

}

import {DbModel} from "./db.model";
import {User} from "./user";
import {WireTransferFormActivityType} from "./wire-transfer-from-activity-type";
import {WireTransferForm} from "./wire-transfer-form";
import {CustomerDocument} from "./customer-document";

export class WireTransferFormActivity extends DbModel {
    comment: string;
    wire_transfer_form_id: number;
    activity_type_id: number;
    user_id: number;

    // Generated fields.
    activity_type: WireTransferFormActivityType;
    documents_array: CustomerDocument[];
    wireTransferForm: WireTransferForm;
    user: User;
}

import {Component, Input, OnInit} from '@angular/core';
import {ScannerInfoState} from '@app/fi-module/states/scanner-info-state.service';
import {User} from '@shared/models/user';
import {Observable} from 'rxjs';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {ApiService} from '@app/core-module/services/api.service';
import {LocalScanningCheckService} from '@app/fi-module/services/local-scanning-check.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'shared-scanner-info-card',
  templateUrl: './scanner-info-card.component.html',
  styleUrls: ['./scanner-info.component.scss']
})
export class ScannerInfoCardComponent implements OnInit {

  scannerInfo: Observable<any>;
  readingScannerInfo: Observable<boolean>;
  displayDetails = false;

  sendingEject = false;


  @Input()
  user: Observable<User>;

// TODO check this: seems like a duplicate with fi-scanner-info
  constructor(
    private scannerInfoState: ScannerInfoState,
    private apiService: ApiService,
    private localScanningCheckService: LocalScanningCheckService,
    private alertsService: AlertsService,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.scannerInfo = this.scannerInfoState.getScannerInfo();
    this.readingScannerInfo = this.scannerInfoState.getReading();
  }

  reconnect() {
    this.scannerInfoState.reload();
  }

  showDetails() {
    this.displayDetails = true;
  }

  eject(user: User) {
    this.sendingEject = true;
    let observable = null;
    if (user.scan_through_browser) {
      observable = this.localScanningCheckService.eject(user.scanner_ip, user.scanner_port);
    } else {
      observable = this.apiService.eject();
    }
    observable.subscribe(() => {
      this.sendingEject = false;
    }, err => {
      this.sendingEject = false;
      this.alertsService.addAlert({type: 'danger', message: this.translateService.instant('shared.scanner-info: Message could not be sent to the scanner')});
    });
  }

}

import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'shared-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit, OnDestroy {

  @Input() mimeType: string;
  @Input() buttonText: string;
  @Input() resetInput: Observable<boolean>;
  @Input() working = false;

  @Output() onFileChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownloadTemplate: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('inputFile') inputFile: ElementRef;
  subscription: Subscription;

  constructor() {
  }

  ngOnInit() {
    this.subscription = this.resetInput.subscribe(val => {
      if (val) {
        this.resetInputElement();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  fileChange(event: any) {
    const file = event.target.files[0];
    this.onFileChange.emit(file);
  }

  downloadTemplate() {
    this.onDownloadTemplate.emit();
  }

  resetInputElement() {
    this.inputFile.nativeElement.value = '';
  }

}

import {ApplicationRef, Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'shared-provide-more-info-answer-modal',
  templateUrl: './provide-more-info-answer-modal.component.html',
  styleUrls: ['./provide-more-info-answer-modal.component.scss'],
})
export class ProvideMoreInfoAnswerModalComponent implements OnInit {

  @Input()
  rdcId?: number;

  @Input()
  wireTransferFormId?: number;

  @Input()
  uploadDocumentApiCall: (
    id: number,
    formData: FormData
  ) => Observable<void>;

  @Output()
  reloadDocumentsEmitter = new EventEmitter();

  modalRef: NgbModalRef;
  files: File[] = [];
  comment: string;

  submitting = false;

  constructor(
    private applicationRef: ApplicationRef,
    private modalService: NgbModal,
    private alertsService: AlertsService) {
  }

  ngOnInit() {
  }

  open(content) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then(
      (result) => {
        if (result) {
          this.reloadDocumentsEmitter.emit();
        }
        this.files = [];
      },
      () => {
        this.files = [];
      }
    );
  }

  public handleUploadedFiles(files: File[]) {
    this.files.push(...files);
  }

  removeFile(file: File) {
    this.files = this.files.filter(it => it !== file);
  }

  submit() {
    if (this.uploadDocumentApiCall) {
      this.submitting = true;
      const formData = new FormData();
      for (const file of this.files) {
        formData.append('documents', file);
      }
      if ((this.rdcId || this.wireTransferFormId) && this.comment) {
        formData.append('comment', this.comment);
      }
      this.uploadDocumentApiCall(this.rdcId || this.wireTransferFormId, formData)
        .pipe(
          finalize(() => this.submitting = false)
        )
        .subscribe((res) => {
          this.modalRef.close(res);
        }, (error) => this.alertsService.addAlert({type: 'danger', message: 'Something went wrong'}));
    } else {
      this.modalRef.close();
    }
  }
}

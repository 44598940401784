import {Component, Input, OnInit} from '@angular/core';
import {Person} from '@shared/models/person';
import {Observable} from 'rxjs';
import {Profile} from '@shared/models/profile';
import {CurrentProfileState} from "@app/core-module/states/current-profile-state.service";
import {first} from "rxjs/operators";
import {WireTransferForm} from "@shared/models/wire-transfer-form";

@Component({
  selector: 'shared-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.scss']
})
export class PersonDetailsComponent implements OnInit {

  @Input()
  person: Person;

  currentProfile: Observable<Profile>;
  isAdmin: boolean;

  @Input()
  linkOnName: string;

  @Input()
  showFinantialInstitution = false;

  constructor(private currentProfileState: CurrentProfileState) {
  }

  ngOnInit() {
    this.currentProfile = this.currentProfileState.getCurrentProfile();
    this.currentProfile.pipe(
      first()
    ).subscribe((data) => {
      this.isAdmin = data.user.isAdminFI;
    })
  }

  //TODO if we allow merging of Persons from different FIs, then we should filter our the wires from different FIs when user is not admin
  getWiresPath(isAdmin: boolean, wire: WireTransferForm): string {
    let wiresPath = '';
    if(isAdmin){
      wiresPath += '/admin/wires/wire-transfer';
    } else {
      wiresPath += '/fi/wires-dashboard/wire-transfer';
    }
    if(wire.directionType == 'INCOMING'){
      wiresPath += '/incoming'
    } else {
      wiresPath += '/outgoing'
    }
    wiresPath += '/' + wire.id;
    return wiresPath;
  }
}

<div [ngClass]="{'form-group': formGroupClass && !removeCardDesign, 'd-flex w my-3' : removeCardDesign}" class="custom-select_arrow-ionfi" [formGroup]="form">
  <label for="country" *ngIf="countryLabel" [ngClass]="{'m-0 col-2 text-right align-self-center text-dark-7-opacity' : removeCardDesign}" class="m-0"><span *ngIf="!inFilter">{{countryLabel}}</span><h6 *ngIf="inFilter" class="weight-500 text-dark-9-opacity" [ngClass]="{ 'm-0' : marginBottom }">{{countryLabel}}</h6><span class="text-purple-ionfi">{{importantAsteriks}}</span></label>
  <div formArrayName="{{formArrayName}}" *ngIf="formArrayName">
    <div *ngIf="groupName != null" formGroupName="{{groupName}}">
      <div class="input-group mb-1">
        <div class="input-group-prepend col-3 p-0">
          <label class="input-group-text">{{countryControlName | titlecase}}</label>
        </div>
        <select id="country" class="custom-select" [ngClass]="removeCardDesign ? 'col-10' : 'col-12'" formControlName="{{countryControlName}}">
          <option [ngValue]="null">{{'shared.inputs-country: Select' | translate}}</option>
          <option *ngFor="let country of (countries | async | orderBy : 'Country')" [ngValue]="country['Alpha-3 code']">
            {{country['Country']}}
          </option>
        </select>
      </div>
    </div>
    <div class="align-x" *ngIf="groupName == null">
      <select id="country" class="form-control" type="select" formControlName="{{countryControlName}}">
        <option [ngValue]="null">{{'shared.inputs-country: Select' | translate}}</option>
        <option *ngFor="let country of (countries | async | orderBy : 'Country')" [ngValue]="country['Alpha-3 code']">
          {{country['Country']}}
        </option>
      </select>
    </div>
  </div>
  <div class="align-x" *ngIf="!formArrayName">
    <select id="country" class="form-control" type="select" formControlName="{{countryControlName}}">
      <option [ngValue]="null">{{'shared.inputs-country: Select' | translate}}</option>
      <option *ngFor="let country of (countries | async | orderBy : 'Country')" [ngValue]="country['Alpha-3 code']">
        {{country['Country']}}
      </option>
    </select>
    <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
  </div>
</div>
<div [ngClass]="{'form-group': formGroupClass && !removeCardDesign, 'd-flex w my-3' : removeCardDesign}" class="custom-select_arrow-ionfi" *ngIf="stateLabel || showStateInput" [formGroup]="form">
  <label for="state" class="text-14" [ngClass]="{'m-0 col-2 text-right align-self-center text-dark-7-opacity' : removeCardDesign}" *ngIf="stateLabel">{{stateLabel}}</label>
  <div *ngIf="formArrayName" formArrayName="{{formArrayName}}">
    <div *ngIf="groupName != null" formGroupName="{{groupName}}">
      <div class="input-group mb-1">
        <div class="input-group-prepend col-3 p-0">
          <label class="input-group-text">{{stateControlName | titlecase}}</label>
        </div>
        <select class="align-x" id="state" class="custom-select" [ngClass]="removeCardDesign ? 'col-10' : 'col-12'" formControlName="{{stateControlName}}">
          <option [ngValue]="null">{{'shared.inputs-country: Select' | translate}}</option>
          <option *ngFor="let region of regions | orderBy : 'name'" [ngValue]="region.abbreviation">{{region.name}}
          </option>
        </select>
      </div>
    </div>
    <div class="align-x" *ngIf="groupName == null">
      <select id="state" class="form-control" type="select" formControlName="{{stateControlName}}">
        <option [ngValue]="null">{{'shared.inputs-country: Select' | translate}}</option>
        <option *ngFor="let region of regions | orderBy : 'name'" [ngValue]="region.abbreviation">{{region.name}}
        </option>
      </select>
    </div>
  </div>
  <div class="align-x" *ngIf="!formArrayName">
    <select id="state" class="form-control" type="select" formControlName="{{stateControlName}}">
      <option [ngValue]="null">{{'shared.inputs-country: Select' | translate}}</option>
      <option *ngFor="let region of regions | orderBy : 'name'" [ngValue]="region.abbreviation">{{region.name}}</option>
    </select>
    <fa-icon [classes]="['fa-fw']" icon="angle-down"></fa-icon>
  </div>
</div>

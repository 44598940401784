import {DbModel} from "./db.model";
import {FinancialInstitution} from "./financial-institution";
import {UpstreamBank} from "./upstream-bank";
import {AccType} from './acc-type';
import {Braid_Counterparty} from './braid-counterparty';
import {KycProfile} from './kyc-profile';

export class Account extends DbModel {

    acc_name: string;
    acc_number: string;
    acc_currency: string;
    acc_type_id: number;
    fi_id: number;
    compliance_fee: number;
    compliance_fee_active: boolean;
    volume_fee: string;
    volume_fee_active: boolean;
    per_item_fee: number;
    per_item_fee_active: boolean;
    rejected_check_fee: number;
    rejected_check_fee_active: boolean;
    maintenance_fee: number;
    maintenance_fee_active: boolean;
    wire_form_fee_domestic: number;
    wire_form_fee_domestic_active: boolean;
    wire_form_fee_international: number;
    wire_form_fee_international_active: boolean;
    transfer_minimum: number;
    incoming_wire_form_fee: number;
    incoming_wire_form_fee_active: boolean;
    default: boolean;
    fee: boolean;
    threshold_kyc: number;
    threshold_mandatory_documents: number;
    mirror_transactions: boolean;
    hide_to_non_administrator_ionfi_users: boolean;
    kyc_profile_id: number;
    sigma_hidden: boolean;
    upstream_bank_id: number;
    hidden: boolean;
    braid_account_number: string;
    funding_account_id: number;

    //generated fields
    type: string;
    fi: FinancialInstitution;
    upstreamBank: UpstreamBank;
    kycProfile: KycProfile;
    accType: AccType;
    counterparties: Braid_Counterparty[];
    fundingAccount: Account;
}

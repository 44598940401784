import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WireTransferBank, WireTransferBankWithAddressLines} from '@shared/models/wire-transfer-bank';
import {Router} from '@angular/router';
import {Person, PersonWithAddressLines} from '@shared/models/person';
import {CustomerDocument} from '@shared/models/customer-document';

// export interface DownloadEventData {
//   kycProfileId: number;
//   document: CustomerDocument;
// }

@Component({
  selector: 'wire-transfer-entity-details',
  templateUrl: './wire-transfer-entity-details.component.html',
  styleUrls: ['./wire-transfer-entity-details.component.scss']
})
export class WireTransferEntityDetailsComponent implements OnInit {

  @Input() headerTitle: string;
  @Input() entityType: 'bank' | 'person';
  @Input() wireTransferEntity: WireTransferBank | Person;
  // @Input() documents: CustomerDocument[];
  @Input() transferMessageEntity: WireTransferBankWithAddressLines | PersonWithAddressLines;
  @Input() showCard = true;
  @Input() showConfirmed = true;
  @Input() isSegregateAccountEntity = false;
  @Input() isWireTransferFormDetails = false;
  @Input() showFundingAccountInfo = false;
  @Input() showSegregatedAccountInfo = false;

  // @Output()
  // onDownload = new EventEmitter<DownloadEventData>();

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.showConfirmed = this.entityType === 'person'
      && (this.wireTransferEntity && (this.wireTransferEntity instanceof Person)
      && (this.wireTransferEntity as Person).isFI ? false : this.showConfirmed);
  }

  getWFEntityUrl(): string {
    if (this.router.url.includes('/admin')) {
      return this.entityType === 'person' ? '/admin/kyc/profiles/' : '/admin/kyc/bank-profiles/';
    } else {
      return this.entityType === 'person' ? '/fi/kyc-dashboard/customers/profiles/' : '/fi/kyc-dashboard/customers/wire-transfer-banks/';
    }
  }

  getWFAccountLink(id: number): string {
    if (this.router.url.includes('/admin')) {
      return '/admin/accounts/accounts-activity/segregated/' + id;
    } else {
      return '/fi/accounts-dashboard/segregated-accounts-activity/' + id;
    }
  }

  // download(kycProfileId: number, document: CustomerDocument) {
  //   this.onDownload.next({kycProfileId: kycProfileId, document: document});
  // }
  isAdmin() {
    return this.router.url.includes('/admin/');
  }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {ApiService} from '@app/core-module/services/api.service';
import {ScannerInfo} from '@shared/models/scanner.info';
import {User} from '@shared/models/user';
import {Subscription, Observable} from 'rxjs';
import {ScannerInfoState} from '@app/fi-module/states/scanner-info-state.service';
import {LocalScanningCheckService} from '@app/fi-module/services/local-scanning-check.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'shared-scanner-info',
  templateUrl: './scanner-info.component.html',
  styleUrls: ['./scanner-info.component.scss']
})
export class ScannerInfoComponent implements OnInit, OnDestroy {

  @Output()
  scannerInfoOutput: EventEmitter<ScannerInfo> = new EventEmitter<ScannerInfo>();

  readingScannerInfo: Observable<boolean>;

  scannerInfo: Observable<ScannerInfo>;

  @Input()
  user: Observable<User>;
  @Input()
  disableActions: boolean;
  @Input()
  scanning: boolean;
  @Input()
  secondForm: boolean;
  @Input()
  thirdForm: boolean;
  @Input()
  fourthForm: boolean;
  @Input()
  scanned = false;
  @Input()
  fullWidth = false;


  @Input()
  batchComplete = true;

  subscription: Subscription;

  sendingEject = false;

  displayDetails = false;

  constructor(private scannerInfoState: ScannerInfoState,
              private apiService: ApiService,
              private localScanningCheckService: LocalScanningCheckService,
              private alertsService: AlertsService,
              private translateService: TranslateService) {
  }

  // TODO check this: I have no idea what this component does
  ngOnInit() {

    this.scannerInfo = this.scannerInfoState.getScannerInfo();

    this.subscription = this.scannerInfo.subscribe(this.scannerInfoOutput);
    this.readingScannerInfo = this.scannerInfoState.getReading();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  reconnect() {
    this.scannerInfoState.reload();
  }

  showDetails() {
    this.displayDetails = true;
  }

  eject(user: User) {
    this.sendingEject = true;
    let observable = null;
    if (user.scan_through_browser) {
      observable = this.localScanningCheckService.eject(user.scanner_ip, user.scanner_port);
    } else {
      observable = this.apiService.eject();
    }
    observable.subscribe(() => {
      this.sendingEject = false;
    }, err => {
      this.sendingEject = false;
      this.alertsService.addAlert({type: 'danger', message: this.translateService.instant('shared.scanner-info: Message could not be sent to the scanner')});
    });
  }

}

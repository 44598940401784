import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'personTypePipe'
})
export class PersonTypePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: string, args?: any): any {
    switch (value) {
      case 'RDC_ONLY': return this.translateService.instant('shared.person-type-pipe: RDC Only');
      case 'FULL_PROFILE': return this.translateService.instant('shared.person-type-pipe: Full Profile');
      case 'NON_CUSTOMER': return this.translateService.instant('shared.person-type-pipe: Non Customer');
      default: return value;
    }
  }

}

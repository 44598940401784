import {
  Component,
  EventEmitter,
  Input,
  Output
} from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { WireTransferFormStatusTypeConverted } from "@shared/models/wire-transfer-form-status-type";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "shared-wire-transfer-forms-filter",
  templateUrl: "./wire-transfer-forms-filter.component.html",
  styleUrls: ["./wire-transfer-forms-filter.component.scss"]
})

export class WireTransferFormsFilterComponent {

  @Input()
  formGroup: FormGroup;

  @Input()
  isIonfi: boolean;

  @Input()
  isIncoming: boolean;

  @Input()
  isComplianceQueue = false;

  @Input()
  isFiltered: boolean

  @Input()
  wfStatuses: Observable<WireTransferFormStatusTypeConverted[]>;

  @Output()
  onFormModelChanged = new EventEmitter()

  @Input()
  wireStatusFormArray: FormArray;

  constructor(private translateService: TranslateService) {
  }

  onModelChanged() {
    this.onFormModelChanged.next(true);
  }
}

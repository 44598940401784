import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerDocument} from '@shared/models/customer-document';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, finalize} from 'rxjs/operators';
import {defaultDebounceTime} from '@app/shared-module/utils/utils';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {ApiService} from '@app/core-module/services/api.service';
import {AdminApiService} from '@app/admin-module/services/admin.api.service';

@Component({
  selector: 'shared-display-and-upload-documents',
  templateUrl: './display-and-upload-documents.component.html',
  styleUrls: ['./display-and-upload-documents.component.scss'],
})
export class DisplayAndUploadDocumentsComponent implements OnInit {

  @Input()
  isAdmin: boolean;

  @Input()
  isAdminDocuments: boolean;

  @Input()
  profileId: number;

  @Input()
  documents: CustomerDocument[];

  @Input()
  showActionButtons = true;

  @Input()
  title: string;

  @Output()
  reloadDocumentsEmitter = new EventEmitter();

  @Output()
  downloadDocumentEmitter = new EventEmitter<{
    documentId: number;
    originalFilename: string;
  }>();


  deleting$ = new BehaviorSubject(false);
  deletingObservable = this.deleting$
    .asObservable()
    .pipe(debounceTime(defaultDebounceTime));

  constructor(private alertsService: AlertsService,
              private apiService: ApiService,
              private adminApiService: AdminApiService) {

  }

  ngOnInit() {
  }

  downloadDocument(documentId: number, originalFilename: string) {
    this.downloadDocumentEmitter.emit({documentId, originalFilename});
  }

  uploadedFiles(files: File[]) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('documents', file);
    }

    const postObservable = this.isAdmin ?
      this.isAdminDocuments ?
        this.adminApiService.postAdminDocuments(this.profileId, formData) :
        this.adminApiService.postCustomerDocuments(this.profileId, formData) :
      this.apiService.postCustomerDocuments(this.profileId, formData);
    postObservable.subscribe(() => {
      this.reloadDocumentsEmitter.next(true);
    }, error => {
      this.alertsService.addAlert({type: 'danger', message: 'Failed uploading file'});
    });
  }

  deleteDocument(documentId: number) {
    this.deleting$.next(true);
    const deleteObservable = this.isAdmin ? this.isAdminDocuments ?
        this.adminApiService.deleteAdminDocument(this.profileId, documentId) :
        this.adminApiService.deleteCustomerDocument(this.profileId, documentId) :
      this.apiService.deleteCustomerDocument(this.profileId, documentId);
    deleteObservable
      .pipe(finalize(() => this.deleting$.next(false)))
      .subscribe((document) => {
        this.reloadDocumentsEmitter.emit();
      });
  }
}

<div *ngIf="user | async as u">
  <h3>{{u.first_name}}<span *ngIf="u.last_name"> {{u.last_name}}</span></h3>
  <div *ngFor="let type of userTypes | async">
    <div *ngIf="type.id == u.users_type_id" class="pb-3">
      <h5>
          <span *ngIf="translateService.currentLang === 'en'" class="type-design-circle"
                [ngStyle]="{'color' : type.text_color, 'background-color' : type.background_color}">{{u.users_type_id | userType | async}}</span>
        <span *ngIf="translateService.currentLang === 'es'" class="type-design-circle"
              [ngStyle]="{'color' : type.text_color, 'background-color' : type.background_color}">{{type.type_es}}</span>
      </h5>
      <h5 *ngIf="u.is_payment_supervisor">{{'shared.user-profile: Payment Supervisor' | translate}}</h5>
    </div>
  </div>
  <div class="list-side-by-side">
    <dl>
      <dt *ngIf="u.date_of_birth">{{'shared.user-profile: Date of birth:' | translate}}</dt>
      <dd *ngIf="u.date_of_birth">{{u.date_of_birth}}</dd>
      <dt
        *ngIf="u.position_in_company">{{'shared.user-profile: Position in company:' | translate}}</dt>
      <dd *ngIf="u.position_in_company">{{u.position_in_company}}</dd>
      <dt
        *ngIf="u.office_phone_number">{{'shared.user-profile: Office phone number:' | translate}}</dt>
      <dd *ngIf="u.office_phone_number">
        <a [href]="'tel:' + u.office_phone_number">
          <fa-icon [classes]="['fa-fw']" [icon]="['fas', 'phone']"></fa-icon>
          {{u.office_phone_number}}
        </a>
      </dd>
      <dt *ngIf="u.cellphone_number">{{'shared.user-profile: Cellphone number:' | translate}}</dt>
      <dd *ngIf="u.cellphone_number"><a [href]="'tel:' + u.cellphone_number">
        <fa-icon [classes]="['fa-fw']" [icon]="['fas', 'phone']"></fa-icon>
        {{u.cellphone_number}}
      </a></dd>
      <dt>{{'shared.user-profile: Email' | translate}}</dt>
      <dd>
        <fa-icon class="text-purple-ionfi" [classes]="['fa-fw']" [icon]="['far', 'envelope']"></fa-icon>
        <a [href]="'mailto:' + u.email">{{u.email}}</a></dd>
    </dl>
  </div>

  <small *ngIf="u.include_in_mailing_lists">
    / {{'shared.user-profile: This user is included in the mailing list' | translate}}</small>
  <div *ngIf="u.scanner_ip">
    <hr/>
    <div><strong>{{'shared.user-profile: Scanner Info' | translate}}</strong></div>
    <br/>
    <div>{{'shared.user-profile: IP address:' | translate}} {{u.scanner_ip}}
      <span
        *ngIf="u.scan_through_browser">({{"shared.user-profile: Users' Local Network" | translate}}
        )</span>
      <span *ngIf="!u.scan_through_browser">({{"shared.user-profile: Over VPN Tunnel" | translate}}
        )</span>
    </div>
    <br/>
    <shared-scanner-info *ngIf="connectToScanner" [user]="user"></shared-scanner-info>
  </div>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {QuestionDefinition, SubQuestion} from '@shared/models/question-definition';
import {
  createAddressFormGroup, createContactFormGroup,
  createFormFieldNameForFile,
  QuestionFile
} from '@app/shared-module/utils/question.utils';

@Component({
  selector: 'shared-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  @Input()
  question: QuestionDefinition;

  @Output()
  updateQuestions = new EventEmitter<boolean>();

  @Input()
  formGroup: FormGroup;

  @Input()
  files: QuestionFile[];

  @Input()
  index: number;

  working = false;
  progressBar = 0;

  constructor() {
  }

  ngOnInit() {
  }

  getFormArray(id): FormArray {
    return this.formGroup.get(id) as FormArray;
  }

  addAnswerForMain() {
    const array = (this.formGroup.get('main') as FormArray);
    const mandatoryValidators = this.question.mandatory ? [Validators.required] : [];
    if (this.question.type_of_question === 'contact') {
      array.push(createContactFormGroup(null, null, null, null, mandatoryValidators));
    } else if (this.question.type_of_question === 'address') {
      array.push(createAddressFormGroup(null, null, null, null, null, mandatoryValidators));
    } else {
      array.push(new FormControl(null, mandatoryValidators));
    }
  }

  removeLastAnswerForMain() {
    const array = (this.formGroup.get('main') as FormArray);
    if (array.length > 1) {
      array.removeAt(array.length - 1);
    }
  }

  addAnswerForSub(subQuestion: SubQuestion) {
    const array = this.formGroup.get(String(subQuestion.id)) as FormArray;
    const mandatoryValidators = this.question.sub_questions.find(
      (sub: SubQuestion) => sub.id === subQuestion.id).mandatory ? [Validators.required] : [];
    if (subQuestion.type_of_question === 'contact') {
      array.push(createContactFormGroup(null, null, null, null, mandatoryValidators));
    } else if (subQuestion.type_of_question === 'address') {
      array.push(createAddressFormGroup(null, null, null, null, null, mandatoryValidators));
    } else {
      array.push(new FormControl(null, mandatoryValidators));
    }
  }

  removeLastForSub(subQuestion: SubQuestion) {
    const array = this.formGroup.get(String(subQuestion.id)) as FormArray;
    if (array.length > 1) {
      array.removeAt(array.length - 1);
    }
  }

  public fileUpload(sub_id, event: { files: File[] }) {
    const filesUploaded = event.files;
    const filesObjectForQuestion = this.files.find(value => value.sub_id === sub_id);
    if (!filesObjectForQuestion) {
      this.files.push({sub_id: sub_id, files: filesUploaded});
    } else {
      filesObjectForQuestion.files.push(...filesUploaded);
    }
    this.populateArrayWithFilenames(sub_id);
  }

  getFilesForUpload(sub_id): File[] {
    const filesObjectForQuestion = this.files.find(value => value.sub_id === sub_id);
    return filesObjectForQuestion ? filesObjectForQuestion.files : [];
  }

  removeFile(sub_id, fileToRemove: File) {
    const filesObjectForQuestion = this.files.find(value => value.sub_id === sub_id);
    filesObjectForQuestion.files = filesObjectForQuestion.files.filter((file) => file.name !== fileToRemove.name);
    this.populateArrayWithFilenames(sub_id);
  }

  populateArrayWithFilenames(sub_id) {
    const array = this.formGroup.get(sub_id) as FormArray;
    if (array.controls.length > 0) {
      array.removeAt(0); // Do this so the view can update (call the validator)
    }
    array.controls = [];
    const filesForSub = this.files.find(value => value.sub_id === sub_id);
    if (filesForSub && filesForSub.files && filesForSub.files.length > 0) {
      filesForSub.files.forEach((file) => {
        array.push(new FormControl(createFormFieldNameForFile(this.index, sub_id, file.name)));
      });
    }
  }
}

<button type="button" *ngIf="initialDataPerson" class="btn btn-sm text-purple-ionfi icon-table-actions mr-2" (click)="open(content)"
        [disabled]="disabled">
  <span class="ico icon-pen" ngbTooltip="{{'admin.account-addons: Edit' | translate}}"></span>
</button>
<button type="button" *ngIf="!initialDataPerson" class="btn btn-sm px-3 py-1 bg-button-purple-ionfi" (click)="open(content)">
  <span class="ico icon-people pr-1"></span>
  {{ 'shared.add-person-modal: Add new Profile' | translate }}
</button>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 *ngIf="!initialDataPerson" class="modal-title">{{ 'shared.add-person-modal: Add new Profile' | translate }}</h4>
    <h4 *ngIf="initialDataPerson" class="modal-title">{{ 'shared.add-person-modal: Update Profile' | translate }}</h4>
    <div *ngIf="(fi | async).can_create_segregate_account" class="d-flex justify-content-between border-right-0 border-left-0 rounded-0 bg-theme">
      <div class="pr-10">
        <p class="m-0 p-0">{{ 'shared.add-person-modal: Create Segregated Account' | translate }}</p>
      </div>
      <div>
        <label class="switch">
          <input type="checkbox" [checked]="createSegregateAccountToggle" (click)="toggleSegregateAccount()">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
  <form [formGroup]="formGroup" (validSubmit)="c()" role="form" novalidate class="custom-select_arrow-ionfi">
    <div class="modal-body">
      <div class="form-group">
        <label>{{ 'shared.add-person-modal: Type' | translate }}<span class="text-purple-ionfi">*</span></label>
        <select class="form-control" type="select" formControlName="personType">
          <option [ngValue]="null" selected>{{ 'shared.add-person-modal: Select' | translate }}</option>
          <option *ngFor="let type of person_type" [ngValue]="type">{{ type | personTypePipe }}</option>
        </select>
        <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
      </div>
      <div class="form-group" *ngIf="formGroup.controls['personType'].value == 'FULL_PROFILE'">
        <label>{{ 'shared.add-person-modal: Account Number a Client' | translate }}</label>
        <input class="form-control" formControlName="accountNumberAClient">
      </div>
      <div class="form-group">
        <label>{{ 'shared.add-person-modal: Entity Type' | translate }}</label><span class="text-purple-ionfi">*</span>
        <select class="form-control" type="select" formControlName="entityType">
          <option [ngValue]="null" selected>{{ 'shared.add-person-modal: Select' | translate }}</option>
          <option *ngFor="let type of entityType" [ngValue]="type">{{ type | entityTypePipe }}</option>
        </select>
        <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
      </div>

      <div class="form-group">
        <label>{{ 'shared.add-person-modal: ID#' | translate }}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="idNumber"/>
      </div>

      <div class="form-group">
        <label>{{ 'shared.add-person-modal: ID Type' | translate }}</label><span class="text-purple-ionfi">*</span>
        <select class="form-control" type="select" formControlName="idType">
          <option [ngValue]="null" selected>{{ 'shared.add-person-modal: Select' | translate }}</option>
          <option *ngFor="let type of (types | async)" [ngValue]="type.id">{{ type.code }}: {{ type.label }}</option>
        </select>
        <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
      </div>

      <div *ngIf="formGroup.controls['entityType'].value == 'INDIVIDUAL'">
        <hr>
        <div class="form-group-subheader">
          Individual
        </div>
        <div class="form-group">
          <label>{{ 'shared.add-person-modal: First Name' | translate }}</label><span class="text-purple-ionfi">*</span>
          <input class="form-control" formControlName="firstName">
        </div>
        <div class="form-group">
          <label>{{ 'shared.add-person-modal: Last Name' | translate }}</label><span class="text-purple-ionfi">*</span>
          <input class="form-control" formControlName="lastName">
        </div>
        <div class="form-group form-group-date d-flex flex-column mb-0">
          <label>{{ 'shared.add-person-modal: Date of Birth' | translate }}<span *ngIf="formGroup.controls['personType'].value !== 'NON_CUSTOMER'" class="text-purple-ionfi">*</span></label>
          <mat-form-field floatLabel="never">
            <input formControlName="dateOfBirth" matInput [matDatepicker]="dateOfBirth"
                   placeholder="{{'shared.add-person-modal: Choose a date' | translate}}" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
            <mat-datepicker #dateOfBirth></mat-datepicker>
          </mat-form-field>
        </div>
        <shared-inputs-country [countryControlName]="'countryOfBirth'"
                               countryLabel="{{'shared.add-person-modal: Country of Birth' | translate }}"
                               [form]="formGroup"></shared-inputs-country>
        <shared-inputs-country [countryControlName]="'residency'"
                               countryLabel="{{'shared.add-person-modal: Residency' | translate }}"
                               [importantAsteriks]="formGroup.controls['personType'].value !== 'NON_CUSTOMER' ? '*' : ''"
                               [form]="formGroup"></shared-inputs-country>
        <shared-inputs-country [countryControlName]="'citizenship'"
                               countryLabel="{{'shared.add-person-modal: Citizenship' | translate }}"
                               [importantAsteriks]="formGroup.controls['personType'].value !== 'NON_CUSTOMER' ? '*' : ''"
                               [form]="formGroup"></shared-inputs-country>
      </div>

      <div *ngIf="formGroup.controls['entityType'].value == 'LEGAL_ENTITY'">
        <hr>
        <div class="form-group-subheader">
          Legal Entity
        </div>
        <div class="form-group">
          <label>{{ 'shared.add-person-modal: Name' | translate }}</label><span class="text-purple-ionfi">*</span>
          <input class="form-control" formControlName="name">
        </div>
        <div class="form-group">
          <label>{{ 'shared.add-person-modal: Organization Type' | translate }}</label><span *ngIf="formGroup.controls['personType'].value !== 'NON_CUSTOMER'" class="text-purple-ionfi">*</span>
          <select class="form-control" type="select" formControlName="orgType">
            <option [ngValue]="null">{{ 'shared.add-person-modal: Select' | translate }}</option>
            <option *ngFor="let type of (orgTypes | async)" [ngValue]="type.id">{{ type.type }}</option>
          </select>
          <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
        </div>

        <div class="form-group form-group-date d-flex flex-column mb-0">
          <label>{{ 'shared.add-organisation-modal: Date of Incorporation' | translate }}<span *ngIf="formGroup.controls['personType'].value !== 'NON_CUSTOMER'" class="text-purple-ionfi">*</span></label>
          <mat-form-field floatLabel="never" >
            <input formControlName="dateOfIncorporation" matInput [matDatepicker]="dateOfIncorporation"
                   placeholder="{{'shared.add-person-modal: Choose a date' | translate}}" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="dateOfIncorporation"></mat-datepicker-toggle>
            <mat-datepicker #dateOfIncorporation disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>

        <shared-inputs-country [countryControlName]="'countryOfIncorporation'"
                               countryLabel="{{'shared.add-person-modal: Country of Incorporation' | translate}}"
                               [importantAsteriks]="formGroup.controls['personType'].value !== 'NON_CUSTOMER' ? '*' : ''"
                               [form]="formGroup"></shared-inputs-country>
      </div>
      <div class="form-group">
        <a target="_blank" *ngIf="rdcToDisplayImage"
           [href]="imagesDomain + '/scanimage/' + rdcToDisplayImage.front_jpg_filename">
          <img *ngIf="rdcToDisplayImage" class="img-fluid"
               [src]="imagesDomain + '/scanimage/' + rdcToDisplayImage.front_jpg_filename"/>
        </a>
      </div>

      <div class="form-group">
        <label>{{ 'shared.add-person-modal: Email' | translate }}</label>
        <input class="form-control" formControlName="email">
      </div>
      <div class="form-group">
        <label>{{ 'shared.add-person-modal: Phone Number' | translate }}</label>
        <input class="form-control" formControlName="phoneNumber">
      </div>
      <div class="form-group">
        <label>{{ 'shared.add-person-modal: Address' | translate }}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="address">
      </div>
      <div class="form-group">
        <label>{{ 'shared.add-person-modal: City' | translate }}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="city">
      </div>
      <div class="form-group">
        <label>{{ 'shared.add-person-modal: ZIP' | translate }}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="zip">
      </div>
      <shared-inputs-country [countryControlName]="'country'" [stateControlName]="'state'"
                             countryLabel="{{'shared.add-person-modal: Country' | translate}}"
                             stateLabel="{{'shared.add-person-modal: State' | translate}}"
                             [form]="formGroup"></shared-inputs-country>
    </div>
    <div class="modal-footer">
      <button type="button" aria-label="Close" (click)="d()" class="btn cancel-button">{{ 'shared.update-bank-modal: Cancel' | translate }}</button>
      <button *ngIf="!initialDataPerson" type="submit"
              class="btn purple-button">{{ 'shared.add-person-modal: Add' | translate }}</button>
      <button *ngIf="initialDataPerson" type="submit"
              class="btn purple-button">{{ 'shared.add-person-modal: Update' | translate }}</button>
    </div>
  </form>
</ng-template>


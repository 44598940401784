<button class="btn bg-purple-ionfi btn-sm" (click)="open(content)" [ngbTooltip]="'shared.question-details-modal: Preview' | translate">
  <fa-icon [classes]="['fa-fw']" icon="info"></fa-icon>
</button>
<ng-template #content let-c="close" let-d="dismiss">

  <div class="modal-header">
    <h4 class="modal-title">{{'shared.question-details-modal: Question Preview' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true" class="text-theme">&times;</span>
    </button>
  </div>

  <div class="modal-body list-group list-group-flush custom-select_arrow-ionfi">

    <div *ngIf="question.type_of_question === 'text'">
      <div class="form-group">
        <label class="col-form-label">{{question.question}}</label>
        <input *ngIf="!question.answer || !question.answer.text_answer || question.answer.text_answer.length === 0"
               class="form-control" type="text" [disabled]="true">
        <div *ngIf="question.answer && question.answer.text_answer && question.answer.text_answer.length > 0">
          <input *ngFor="let answer of question.answer.text_answer" class="form-control mb-3" type="text"
                 [value]="answer"
                 [disabled]="true">
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'textarea'">
      <div class="form-group">
        <label class="col-form-label">{{question.question}}</label>
        <textarea *ngIf="!question.answer || !question.answer.text_answer || question.answer.text_answer.length === 0"
                  class="form-control" rows="5" type="text"
                  [disabled]="true"></textarea>
        <div *ngIf="question.answer && question.answer.text_answer && question.answer.text_answer.length > 0">
            <textarea *ngFor="let answer of question.answer.text_answer" class="form-control mb-3" rows="5" type="text"
                      [disabled]="true"
                      [value]="answer"></textarea>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'country'">
      <div class="form-group">
        <label class="col-form-label">{{question.question}}</label>
        <select *ngIf="!question.answer || !question.answer.text_answer || question.answer.text_answer.length === 0"
                class="form-control" type="select">
          <option [ngValue]="null" [selected]="true" [disabled]="true">{{'shared.question-details-modal: Select' | translate}}</option>
          <option *ngFor="let country of (countries | async | orderBy : 'Country')" [ngValue]="country['Alpha-3 code']"
                  [disabled]="true">
            {{country['Country']}}
          </option>
        </select>
        <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
        <div *ngIf="question.answer && question.answer.text_answer && question.answer.text_answer.length > 0">
          <select *ngFor="let answer of question.answer.text_answer" class="form-control mb-3" type="select">
            <option [ngValue]="null" [disabled]="true">{{'shared.question-details-modal: Select' | translate}}</option>
            <option *ngFor="let country of (countries | async | orderBy : 'Country')" [ngValue]="country['Alpha-3 code']"
                    [selected]="answer === country['Alpha-3 code']" [disabled]="true">
              {{country['Country']}}
            </option>
          </select>
          <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'amount'">
      <label class="col-form-label">{{question.question}}</label>
      <div class="input-group mb-3"
           *ngIf="!question.answer || !question.answer.text_answer || question.answer.text_answer.length === 0">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input type="number" class="form-control" [disabled]="true">
      </div>
      <div *ngIf="question.answer && question.answer.text_answer && question.answer.text_answer.length > 0">
        <div class="input-group mb-3" *ngFor="let answer of question.answer.text_answer">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="number" class="form-control" [value]="answer" [disabled]="true">
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'select'">
      <div class="form-group">
        <label class="col-form-label">{{question.question}}</label>
        <select *ngIf="!question.answer || !question.answer.text_answer || question.answer.text_answer.length === 0"
                class="form-control" type="select">
          <option [ngValue]="null" [selected]="true" [disabled]="true">{{'shared.question-details-modal: Select' | translate}}</option>
          <option *ngFor="let ans of question.offered_answers" [ngValue]="ans" [disabled]="true">{{ans}}</option>
        </select>
        <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
        <div *ngIf="question.answer && question.answer.text_answer && question.answer.text_answer.length > 0">
          <select *ngFor="let answer of question.answer.text_answer" class="form-control mb-3" type="select">
            <option [ngValue]="null" [disabled]="true">{{'shared.question-details-modal: Select' | translate}}</option>
            <option *ngFor="let ans of question.offered_answers" [ngValue]="ans" [selected]="answer === ans"
                    [disabled]="true">{{ans}}
            </option>
          </select>
          <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'radio'">
      <div class="form-group">
        <label class="col-form-label">{{question.question}}</label>
        <div *ngFor="let ans of question.offered_answers">
            <div>
              <input class="form-radio" type="radio" value="{{ans}}"
                     [checked]="question.answer && question.answer.text_answer && question.answer.text_answer[0] === ans"
                     [disabled]="true">
              <label class="form-custom-label">{{ans}}</label>
            </div>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'file'">
      <div class="form-group">
        <label class="col-form-label">{{question.question}}</label>
        <div *ngIf="!question.answer || !question.answer.file_answer || question.answer.file_answer.length === 0">
          <shared-files-upload customstyle="filedrop" [disabled]="true"></shared-files-upload>
        </div>
        <div *ngIf="question.answer && question.answer.file_answer && question.answer.file_answer.length > 0">
            <span *ngFor="let part of question.answer.file_answer; let i = index">
              <div>
              <a href="javascript:void(0)" (click)="downloadFile(part)">
                <fa-icon [classes]="['fa-fw']" icon="download"></fa-icon>
                {{trimTimestampFromFilename(part)}}
              </a>
              </div>
            </span>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'check'">
      <div class="form-group">
        <label class="col-form-label">{{question.question}}</label>
        <div *ngFor="let ans of question.offered_answers; let i=index">
            <span>
              <input class="form-checkbox" type="checkbox" value="{{ans}}"
                     [disabled]="true"
                     [checked]="question.answer && question.answer.text_answer && question.answer.text_answer.indexOf(ans) !== -1">
              <label class="form-custom-label">{{ans}}</label>
            </span>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'slider'">
      <div class="form-group">
        <label class="col-form-label">{{question.question}}</label>
        <span class="d-flex align-items-center">
            <label class="switch">
              <input type="checkbox" [disabled]="true"
                     [checked]="question.answer && question.answer.text_answer && question.answer.text_answer[0] === 'true'">
              <span class="slider round"></span>
            </label>
            <span class="ml-2">{{question.offered_answers[0]}}</span>
          </span>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'contact'">
      <div class="form-group">
        <label class="col-form-label">{{question.question}}</label>
        <div
          *ngIf="!question.answer || !question.answer.contact_answer || question.answer.contact_answer.length === 0">
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <span class="input-group-text col-12">{{'shared.question-details-modal: Type' | translate}}</span>
            </div>
            <input type="text" class="form-control" disabled>
          </div>
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <span class="input-group-text col-12">{{'shared.question-details-modal: Name' | translate}}</span>
            </div>
            <input type="text" class="form-control" disabled>
          </div>
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <span class="input-group-text col-12">{{'shared.question-details-modal: Email' | translate}}</span>
            </div>
            <input type="text" class="form-control" disabled>
          </div>
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <span class="input-group-text col-12">{{'shared.question-details-modal: Phone' | translate}}</span>
            </div>
            <input type="text" class="form-control" disabled>
          </div>
        </div>
        <div *ngIf="question.answer && question.answer.contact_answer && question.answer.contact_answer.length > 0">
          <div *ngFor="let answer of question.answer.contact_answer" class="mb-3">
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Type' | translate}}</span>
              </div>
              <input type="text" class="form-control" [value]="answer.type" disabled>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Name' | translate}}</span>
              </div>
              <input type="text" class="form-control" [value]="answer.name" disabled>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Email' | translate}}</span>
              </div>
              <input type="text" class="form-control" [value]="answer.email" disabled>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Phone' | translate}}</span>
              </div>
              <input type="text" class="form-control" [value]="answer.phone" disabled>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'address'">
      <div class="form-group">
        <label class="col-form-label">{{question.question}}</label>
        <div
          *ngIf="!question.answer || !question.answer.address_answer || question.answer.address_answer.length === 0">
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <span class="input-group-text col-12">{{'shared.question-details-modal: Street' | translate}}</span>
            </div>
            <input type="text" class="form-control" disabled>
          </div>
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <span class="input-group-text col-12">{{'shared.question-details-modal: City' | translate}}</span>
            </div>
            <input type="text" class="form-control" disabled>
          </div>
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <label class="input-group-text col-12">{{'shared.question-details-modal: Country' | translate}}</label>
            </div>
            <select class="custom-select">
              <option [ngValue]="null" selected>{{'shared.question-details-modal: Select' | translate}}</option>
              <option *ngFor="let country of (countries | async | orderBy : 'Country')" [ngValue]="country['Alpha-3 code']"
                      [disabled]="true">
                {{country['Country']}}
              </option>
            </select>
            <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
          </div>
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <label class="input-group-text col-12">{{'shared.question-details-modal: State' | translate}}</label>
            </div>
            <select class="custom-select">
              <option [ngValue]="null" selected disabled>{{'shared.question-details-modal: Select' | translate}}</option>
            </select>
            <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
          </div>
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <span class="input-group-text col-12">{{'shared.question-details-modal: Zip' | translate}}</span>
            </div>
            <input type="text" class="form-control" disabled>
          </div>
        </div>
        <div *ngIf="question.answer && question.answer.address_answer && question.answer.address_answer.length > 0">
          <div *ngFor="let answer of question.answer.address_answer" class="mb-3">
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Street' | translate}}</span>
              </div>
              <input type="text" class="form-control" [value]="answer.street" disabled>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: City' | translate}}</span>
              </div>
              <input type="text" class="form-control" [value]="answer.city" disabled>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <label class="input-group-text col-12">{{'shared.question-details-modal: Country' | translate}}</label>
              </div>
              <select class="custom-select">
                <option [ngValue]="null">{{'shared.question-details-modal: Select' | translate}}</option>
                <option *ngFor="let country of (countries | async | orderBy : 'Country')"
                        [selected]="answer.country === country['Alpha-3 code']" [ngValue]="country['Alpha-3 code']"
                        [disabled]="true">
                  {{country['Country']}}
                </option>
              </select>
              <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <label class="input-group-text col-12">{{'shared.question-details-modal: State' | translate}}</label>
              </div>
              <select class="custom-select">
                <option [ngValue]="null" disabled>{{'shared.question-details-modal: Select' | translate}}</option>
                <option *ngFor="let region of getRegionsForCountry(answer.country) | orderBy : 'name'"
                        [selected]="answer.state === region.abbreviation"
                        [ngValue]="region.abbreviation"
                        [disabled]="true">
                  {{region.name}}
                </option>
              </select>
              <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Zip' | translate}}</span>
              </div>
              <input type="text" class="form-control" [value]="answer.zip" disabled>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'date'">
      <div class="form-group">
        <label class="col-form-label">{{question.question}}</label>
        <input *ngIf="!question.answer || !question.answer.text_answer || question.answer.text_answer.length === 0"
               class="form-control" type="date" [disabled]="true">
        <div *ngIf="question.answer && question.answer.text_answer && question.answer.text_answer.length > 0">
          <input *ngFor="let answer of question.answer.text_answer" class="form-control mb-3" type="date"
                 [value]="answer"
                 [disabled]="true">
        </div>
      </div>
    </div>
    <div *ngIf="question.type_of_question === 'title'">
      <label class="font-weight-bold title-size">{{question.question}}</label>
    </div>
    <div *ngIf="question.type_of_question === 'label'">
      <label class="font-italic">{{question.question}}</label>
    </div>
    <div *ngFor="let sub of question.sub_questions; let i = index">
      <div *ngIf="sub.type_of_question === 'text'">
        <div class="form-group">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}</div>
          <input *ngIf="!sub.answer || !sub.answer.text_answer || sub.answer.text_answer.length === 0"
                 class="form-control" type="text" [disabled]="true">
          <div *ngIf="sub.answer && sub.answer.text_answer && sub.answer.text_answer.length > 0">
            <input *ngFor="let answer of sub.answer.text_answer" class="form-control mb-3" type="text"
                   [value]="answer"
                   [disabled]="true">
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'textarea'">
        <div class="form-group">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}</div>
          <textarea
            *ngIf="!sub.answer || !sub.answer.text_answer || sub.answer.text_answer.length === 0"
            class="form-control" rows="5" type="text"
            [disabled]="true"></textarea>
          <div *ngIf="sub.answer && sub.answer.text_answer && sub.answer.text_answer.length > 0">
            <textarea *ngFor="let answer of sub.answer.text_answer" class="form-control mb-3" rows="5" type="text"
                      [disabled]="true"
                      [value]="answer"></textarea>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'country'">
        <label class="text-muted" *ngIf="sub.title">{{sub.title}}</label>
        <select *ngIf="!sub.answer || !sub.answer.text_answer || sub.answer.text_answer.length === 0"
                class="form-control" type="select">
          <option [ngValue]="null" [selected]="true" [disabled]="true">{{'shared.question-details-modal: Select' | translate }}</option>
          <option *ngFor="let country of (countries | async | orderBy : 'Country')" [ngValue]="country['Alpha-3 code']"
                  [disabled]="true">
            {{country['Country']}}
          </option>
        </select>
        <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
        <div *ngIf="sub.answer && sub.answer.text_answer && sub.answer.text_answer.length > 0">
          <select *ngFor="let answer of sub.answer.text_answer" class="form-control mb-3" type="select">
            <option [ngValue]="null" [disabled]="true">{{'shared.question-details-modal: Select' | translate}}</option>
            <option *ngFor="let country of (countries | async | orderBy : 'Country')" [ngValue]="country['Alpha-3 code']"
                    [selected]="answer === country['Alpha-3 code']" [disabled]="true">
              {{country['Country']}}
            </option>
          </select>
          <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'amount'">
        <div class="text-muted" *ngIf="sub.title">{{sub.title}}</div>
        <div class="input-group mb-3"
             *ngIf="!sub.answer || !sub.answer.text_answer || sub.answer.text_answer.length === 0">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="number" class="form-control" [disabled]="true">
        </div>
        <div *ngIf="sub.answer && sub.answer.text_answer && sub.answer.text_answer.length > 0">
          <div class="input-group mb-3" *ngFor="let answer of sub.answer.text_answer">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input type="number" class="form-control" [value]="answer" [disabled]="true">
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'select'">
        <div class="form-group">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}</div>
          <select
            *ngIf="!sub.answer || !sub.answer.text_answer || sub.answer.text_answer.length === 0"
            class="form-control" type="select">
            <option [ngValue]="null" [selected]="true" [disabled]="true">{{'shared.question-details-modal: Select' | translate}}</option>
            <option *ngFor="let ans of sub.offered_answers" [ngValue]="ans" [disabled]="true">{{ans}}</option>
          </select>
          <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
          <div *ngIf="sub.answer && sub.answer.text_answer && sub.answer.text_answer.length > 0">
            <select *ngFor="let answer of sub.answer.text_answer" class="form-control mb-3" type="select">
              <option [ngValue]="null" [disabled]="true">{{'shared.question-details-modal: Select' | translate}}</option>
              <option *ngFor="let ans of sub.offered_answers" [ngValue]="ans" [selected]="answer === ans"
                      [disabled]="true">{{ans}}
              </option>
            </select>
            <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'radio'">
        <div class="form-group">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}</div>
          <div *ngFor="let ans of sub.offered_answers">
              <input class="form-radio" type="radio" value="{{ans}}"
                     [checked]="sub.answer && sub.answer.text_answer && sub.answer.text_answer[0] === ans"
                     [disabled]="true">
              <label class="form-custom-label">{{ans}}</label>
            </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'file'">
        <div class="form-group">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}</div>
          <div *ngIf="!sub.answer || !sub.answer.file_answer || sub.answer.file_answer.length === 0">
            <shared-files-upload customstyle="filedrop" [disabled]="true"></shared-files-upload>
          </div>
          <div *ngIf="sub.answer && sub.answer.file_answer && sub.answer.file_answer.length > 0">
              <span *ngFor="let part of sub.answer.file_answer; let i = index">
                <div>
                <a href="javascript:void(0)" (click)="downloadFile(part)">
                <fa-icon [classes]="['fa-fw']" icon="download"></fa-icon>
                {{trimTimestampFromFilename(part)}}
                </a>
                </div>
              </span>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'check'">
        <div class="form-group">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}</div>
          <div *ngFor="let ans of sub.offered_answers; let i=index">
              <span>
                <input class="form-checkbox" type="checkbox"
                       [disabled]="true"
                       [checked]="sub.answer && sub.answer.text_answer && sub.answer.text_answer.indexOf(ans) !== -1">
                       value="{{ans}}">
                <label class="form-custom-label">{{ans}}</label>
              </span>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'slider'">
        <div class="form-group">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}</div>
          <span class="d-flex align-items-center">
            <label class="switch">
              <input type="checkbox" [disabled]="true"
                     [checked]="sub.answer && sub.answer.text_answer && sub.answer.text_answer[0] === 'true'">
              <span class="slider round"></span>
            </label>
            <span class="ml-2">{{sub.offered_answers[0]}}</span>
          </span>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'contact'">
        <div class="text-muted" *ngIf="sub.title">{{sub.title}}</div>
        <div
          *ngIf="!sub.answer || !sub.answer.contact_answer || sub.answer.contact_answer.length === 0">
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <span class="input-group-text col-12">{{'shared.question-details-modal: Type' | translate}}</span>
            </div>
            <input type="text" class="form-control" disabled>
          </div>
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <span class="input-group-text col-12">{{'shared.question-details-modal: Name' | translate}}</span>
            </div>
            <input type="text" class="form-control" disabled>
          </div>
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <span class="input-group-text col-12">{{'shared.question-details-modal: Email' | translate}}</span>
            </div>
            <input type="text" class="form-control" disabled>
          </div>
          <div class="input-group mb-1">
            <div class="input-group-prepend col-3 p-0">
              <span class="input-group-text col-12">{{'shared.question-details-modal: Phone' | translate}}</span>
            </div>
            <input type="text" class="form-control" disabled>
          </div>
        </div>
        <div *ngIf="sub.answer && sub.answer.contact_answer && sub.answer.contact_answer.length > 0">
          <div *ngFor="let answer of sub.answer.contact_answer" class="mb-3">
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Type' | translate}}</span>
              </div>
              <input type="text" class="form-control" [value]="answer.type" disabled>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Name' | translate}}</span>
              </div>
              <input type="text" class="form-control" [value]="answer.name" disabled>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Email' | translate}}</span>
              </div>
              <input type="text" class="form-control" [value]="answer.email" disabled>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Phone' | translate}}</span>
              </div>
              <input type="text" class="form-control" [value]="answer.phone" disabled>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'address'">
        <div class="form-group">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}</div>
          <div
            *ngIf="!sub.answer || !sub.answer.address_answer || sub.answer.address_answer.length === 0">
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Street' | translate}}</span>
              </div>
              <input type="text" class="form-control" disabled>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: City' | translate}}</span>
              </div>
              <input type="text" class="form-control" disabled>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <label class="input-group-text col-12">{{'shared.question-details-modal: Country' | translate}}</label>
              </div>
              <select class="custom-select">
                <option [ngValue]="null" selected>{{'shared.question-details-modal: Select' | translate}}</option>
                <option *ngFor="let country of (countries | async | orderBy : 'Country')" [ngValue]="country['Alpha-3 code']"
                        [disabled]="true">
                  {{country['Country']}}
                </option>
              </select>
              <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <label class="input-group-text col-12">{{'shared.question-details-modal: State' | translate}}</label>
              </div>
              <select class="custom-select">
                <option [ngValue]="null" selected disabled>{{'shared.question-details-modal: Select' | translate}}</option>
              </select>
              <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend col-3 p-0">
                <span class="input-group-text col-12">{{'shared.question-details-modal: Zip' | translate}}</span>
              </div>
              <input type="text" class="form-control" disabled>
            </div>
          </div>
          <div *ngIf="sub.answer && sub.answer.address_answer && sub.answer.address_answer.length > 0">
            <div *ngFor="let answer of sub.answer.address_answer" class="mb-3">
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <span class="input-group-text col-12">{{'shared.question-details-modal: Street' | translate}}</span>
                </div>
                <input type="text" class="form-control" [value]="answer.street" disabled>
              </div>
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <span class="input-group-text col-12">{{'shared.question-details-modal: City' | translate}}</span>
                </div>
                <input type="text" class="form-control" [value]="answer.city" disabled>
              </div>
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <label class="input-group-text col-12">{{'shared.question-details-modal: Country' | translate}}</label>
                </div>
                <select class="custom-select">
                  <option [ngValue]="null">{{'shared.question-details-modal: Select' | translate}}</option>
                  <option *ngFor="let country of (countries | async | orderBy : 'Country')"
                          [selected]="answer.country === country.cca3" [ngValue]="country['Alpha-3 code']"
                          [disabled]="true">
                    {{country['Country']}}
                  </option>
                </select>
                <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
              </div>
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <label class="input-group-text col-12">{{'shared.question-details-modal: State' | translate}}</label>
                </div>
                <select class="custom-select">
                  <option [ngValue]="null" disabled>{{'shared.question-details-modal: Select' | translate}}</option>
                  <option *ngFor="let region of getRegionsForCountry(answer.country) | orderBy : 'name'"
                          [selected]="answer.state === region.abbreviation"
                          [ngValue]="region.abbreviation"
                          [disabled]="true">
                    {{region.name}}
                  </option>
                </select>
                <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
              </div>
              <div class="input-group mb-1">
                <div class="input-group-prepend col-3 p-0">
                  <span class="input-group-text col-12">{{'shared.question-details-modal: Zip' | translate}}</span>
                </div>
                <input type="text" class="form-control" [value]="answer.zip" disabled>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'date'">
        <div class="form-group">
          <div class="text-muted" *ngIf="sub.title">{{sub.title}}</div>
          <input *ngIf="!sub.answer || !sub.answer.text_answer || sub.answer.text_answer.length === 0"
                 class="form-control" type="date" [disabled]="true">
          <div *ngIf="sub.answer && sub.answer.contact_answer && sub.answer.text_answer.length > 0">
            <input *ngFor="let answer of sub.answer.text_answer" class="form-control mb-3" type="date"
                   [value]="answer"
                   [disabled]="true">
          </div>
        </div>
      </div>
      <div *ngIf="sub.type_of_question === 'title'">
        <label class="font-weight-bold title-size">{{sub.title}}</label>
      </div>
      <div *ngIf="sub.type_of_question === 'label'">
        <label class="font-italic font-weight-light">{{sub.title}}</label>
      </div>
    </div>
  </div>
</ng-template>


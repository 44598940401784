import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Profile} from '@shared/models/profile';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {WireTransferBank} from '@shared/models/wire-transfer-bank';

@Component({
  selector: 'shared-wire-transfer-bank-details',
  templateUrl: './wire-transfer-bank-details.component.html',
  styleUrls: ['./wire-transfer-bank-details.component.scss']
})
export class WireTransferBankDetailsComponent implements OnInit {

  @Input()
  bank: WireTransferBank;

  currentProfile: Observable<Profile>;

  @Input()
  linkOnName: string;

  @Input()
  showFinantialInstitution = false;

  constructor(private currentProfileState: CurrentProfileState) {
  }

  ngOnInit() {
    this.currentProfile = this.currentProfileState.getCurrentProfile();
  }

}

<button class="btn btn-sm btn-secondary ld-ext-right"
        type="button"
        (click)="open(content)">
  <fa-icon [classes]="['fa-fw']" icon="chart-line"></fa-icon>
  {{ 'shared.display-transactions-modal: Show transactions' | translate }}
</button>
<ng-template #content let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'shared.display-transactions-modal: Wire Transfer Transactions' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <transactions-table [transactions]="transactions"
                        [enableDescriptionWireFormLink]="false"
                        (closeModal)="d()"></transactions-table>
  </div>
</ng-template>

import {Injectable} from '@angular/core';
import {AdminApiService} from '@app/admin-module/services/admin.api.service';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {Deposit} from '@shared/models/non-db/deposit';
import {Fee} from '@shared/models/non-db/fee';
import {TransferBetweenAccounts} from '@shared/models/non-db/transfer-between-accounts';
import {Withdrawal} from '@shared/models/non-db/withdrawal';
import {Observable} from 'rxjs';
import {filter, first, map, switchMap, tap} from 'rxjs/operators';
import {AllBalancesState} from './all.balances.state';
import {TranSubType} from '@shared/models/tran-sub-type';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {ReturnFee} from '@shared/models/non-db/return-fee';
import {Transaction} from '@shared/models/transaction';

@Injectable()
export class AdminTransferState {

  feeSubTypes: Observable<TranSubType[]>;
  readingFeeSubTypes: Observable<boolean>;

  constructor(private adminApiService: AdminApiService,
              private alertService: AlertsService,
              private allBalancesState: AllBalancesState,
              private currentProfileState: CurrentProfileState,
              private rxUtilsService: RxUtilsService) {
    const initiator = this.currentProfileState.getCurrentProfileId().pipe(
      switchMap(() => this.currentProfileState.getCurrentProfile().pipe((first()))),
      filter(it => it != null && it.user != null && it.user.isAdminFI),
    );
    this.feeSubTypes = this.rxUtilsService.createGetStateValue(initiator, this.adminApiService.getFeeSubTypes(),
      'Error while reading fee sub types', []);
    this.readingFeeSubTypes = this.rxUtilsService.createReadingInfo(initiator, this.feeSubTypes);
  }

  getFeeSubTypes(): Observable<TranSubType[]> {
    return this.feeSubTypes.pipe(
      map(feeSubTypes => feeSubTypes.filter(it => it.parent_type === 'Fee'))
    );
  }

  getReverseFeeSubTypes(): Observable<TranSubType[]> {
    return this.feeSubTypes.pipe(
      map(feeSubTypes => feeSubTypes.filter(it => it.parent_type === 'Reverse Fee'))
    );
  }

  getReadingFeeSubTypes(): Observable<boolean> {
    return this.readingFeeSubTypes;
  }

  createDeposit(deposit: Deposit): Observable<Deposit> {
    return this.adminApiService.createDeposit(deposit).pipe(
      tap(() => {
        this.allBalancesState.reload();
      })
    );
  }

  createWithdrawal(withdrawal: Withdrawal): Observable<Withdrawal> {
    return this.adminApiService.createWithdrawal(withdrawal).pipe(
      tap(() => {
        this.allBalancesState.reload();
      })
    );
  }

  createFee(fee: Fee): Observable<Fee> {
    return this.adminApiService.createFee(fee).pipe(
      tap(() => {
        this.allBalancesState.reload();
      })
    );
  }

  createReturnFee(transaction: Transaction, returnFee: ReturnFee): Observable<Fee> {
    return this.adminApiService.createReturnFee(transaction, returnFee).pipe(
      tap(() => {
        this.allBalancesState.reload();
      })
    );
  }

  transfer(transferBetweenAccounts: TransferBetweenAccounts): Observable<TransferBetweenAccounts> {
    return this.adminApiService.createTransferBetweenAccounts(transferBetweenAccounts).pipe(
      tap(() => {
        this.allBalancesState.reload();
      })
    );
  }
}

<div>
  <div *ngIf="scannerInfo | async as s">
    <div *ngIf="!(readingScannerInfo | async)">
      <div>
        <h6>{{ 'fi.edit-check: Scanner Info' | translate }}</h6>
        <div *ngIf="s.isConnected()"
             class="d-flex flex-row align-items-center justify-content-between pb-1 text-success">
          <div>
            <fa-icon [classes]="['fa-fw']" icon="lock"></fa-icon>
            {{'shared.scanner-info: Connected' | translate}}
          </div>
          <button class="btn btn-sm btn-link"
                  (click)="reconnect()">
            {{'shared.scanner-info: Retry' | translate}}
          </button>
        </div>
        <div *ngIf="user | async as u">
          <div *ngIf="!u.scan_through_browser && !s.isConnected()"
               class="d-flex flex-row align-items-center justify-content-between pb-1 text-danger">
            <div>
              <fa-icon [classes]="['fa-fw']" icon="lock-open"></fa-icon>
              {{'shared.scanner-info: Connection failed' | translate}}
            </div>
            <button class="btn btn-sm btn-link"
                    (click)="reconnect()">{{'shared.scanner-info: Retry' | translate}}</button>
          </div>
          <div *ngIf="u.scan_through_browser && !s.isConnected()"
               class="d-flex flex-row align-items-center justify-content-between pb-1 text-warning">
            <div>
              <fa-icon [classes]="['fa-fw']" icon="lock-open"></fa-icon>
              {{'shared.scanner-info: Connection pending' | translate}}
            </div>
            <div class="small text-muted">
              <div>
                {{'shared.scanner-info: Try to access the scanners\' page on this' | translate}}
                <a [href]="'https://' + u.scanner_ip + ':' + u.scanner_port"
                   target="_blank">{{'shared.scanner-info: link' | translate}}</a>
                {{'shared.scanner-info: and allow the browser to use the scanner and click' | translate}} <a
                [routerLink]="null" (click)="reconnect()">{{'shared.scanner-info: retry' | translate}}</a>.
                {{'shared.scanner-info: If you don\'t get anything on the link either the IP address is wrong or the scanner is offline.' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayDetails" class="list-group">
        <div *ngIf="s.Vendor" class="list-group-item">
          <span class="text-muted">{{'shared.scanner-info: Vendor' | translate}}</span>
          <span class="float-right">{{s.Vendor}}</span>
        </div>
        <div *ngIf="s.Product" class="list-group-item">
          <span class="text-muted">{{'shared.scanner-info: Product' | translate}}</span>
          <span class="float-right">{{s.Product}}</span>
        </div>
        <div *ngIf="s.Release" class="list-group-item">
          <span class="text-muted">{{'shared.scanner-info: Release' | translate}}</span>
          <span class="float-right">{{s.Release}}</span>
        </div>
        <div class="list-group-item">
          <span class="text-muted">{{'shared.scanner-info: Batch scanning available?' | translate}}</span>
          <span class="float-right">{{s.batchAvailable ? ' Yes' : ' No'}}</span>
        </div>
      </div>
      <div *ngIf="!displayDetails && s.isConnected()" class="list-group">
        <div class="btn btn-sm btn-info"
             (click)="showDetails()">{{'shared.scanner-info: Show details' | translate}}</div>
      </div>
      <div *ngIf="s.isConnected()">
        <small>{{'shared.scanner-info: Document jammed?' | translate}}</small>
        <button class="btn btn-sm btn-link ld-ext-right" *ngIf="user | async as u" (click)="eject(u)"
                [ngClass]="{'running': sendingEject}" [disabled]="sendingEject">
          {{'shared.scanner-info: Fix' | translate}}
          <div class="ld ld-ring ld-spin"></div>
        </button>
      </div>
    </div>
    <p *ngIf="readingScannerInfo | async">{{'shared.scanner-info: Connecting...' | translate}}
      <shared-loading-bar></shared-loading-bar>
    </p>
  </div>
</div>

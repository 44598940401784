import { Pipe, PipeTransform } from '@angular/core';
import {Person} from '@shared/models/person';
import {getPersonName} from '@shared/utils/utils';
import {Observable} from 'rxjs';
import {ApiService} from '@app/core-module/services/api.service';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'masterPersonName'
})
export class MasterPersonNamePipe implements PipeTransform {

  constructor(private apiService: ApiService) {
  }

  transform(person: Person, args?: any): Observable<string> {
    return this.apiService.getKycProfile(person.kyc_profile_id).pipe(
      map(it => getPersonName(it.masterPerson))
    );
  }

}

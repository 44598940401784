import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RDC } from "@shared/models/rdc";
import { environment } from "@env/environment";
import { Router } from "@angular/router";
import { PAGE_SIZE } from "@shared/utils/constants";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "shared-processing-rdcs-table",
  templateUrl: "./processing-rdcs-table.component.html",
  styleUrls: ["./processing-rdcs-table.component.scss"]
})
export class ProcessingRdcsTableComponent {

  PAGE_SIZE = PAGE_SIZE;

  @Input() filteredRDCs: RDC[];
  @Input() showActionButtons: boolean;
  @Input() numberOfRecords: number;
  @Input() formGroup: FormGroup;
  @Input() showStatusColumn: boolean;
  @Output() deleteRDC = new EventEmitter<RDC>();

  imagesDomain: string;
  url: string;
  isAdmin: boolean;

  constructor(private router: Router) {
    this.imagesDomain = environment.domain;
    this.url = this.router.url;
    this.isAdmin = this.url.includes("/admin");
  }

  delete(rdc: RDC) {
    this.deleteRDC.next(rdc);
  }

}

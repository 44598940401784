import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {RdcActivity} from '@shared/models/rdc-activity';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {TranslateService} from '@ngx-translate/core';
import {download, newLinesToBrTags} from '@app/shared-module/utils/utils';
import {RdcActivityType} from '@shared/models/rdc-activity-type';
import {ApiService} from '@app/core-module/services/api.service';
import {WireTransferFormActivity} from '@shared/models/wire-transfer-form-activity';
import {WireTransferFormActivityType} from '@shared/models/wire-transfer-from-activity-type';

@Component({
  selector: 'state-timeline',
  templateUrl: './state-timeline.component.html',
  styleUrls: ['./state-timeline.component.scss']
})
export class StateTimelineComponent implements OnInit {

  @Input()
  stateActivities: Observable<RdcActivity[] | WireTransferFormActivity[]>;
  @Input()
  headerText?: string;
  @Input()
  subheaderText?: string;

  reading: Observable<boolean>;

  reload$ = new BehaviorSubject(true);

  constructor(private apiService: ApiService,
              private rxUtilsService: RxUtilsService,
              private alertsService: AlertsService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
  }

  isInvertedActivity(activity: RdcActivity | WireTransferFormActivity) {
    const activityType = (activity as RdcActivity).type || (activity as WireTransferFormActivity).activity_type;
    return activityType.code === 'INFO_UPLOADED' || activityType.code === 'APPROVED_ULTIMATE_BENEFICIARY' || activityType.code === 'REJECTED_ULTIMATE_BENEFICIARY';
  }

  downloadDocument(activity: RdcActivity | WireTransferFormActivity, documentId: number, originalFilename: string) {
    const action = activity && (activity instanceof WireTransferFormActivity) && activity.wire_transfer_form_id ? this.apiService.downloadWireTransferMoreInfoRequiredDocument(activity.id, documentId) : this.apiService.downloadRDCMoreInfoRequiredDocument(activity.id, documentId);
    action.subscribe(
        (res) => {
          download(originalFilename, res.body);
        },
        (error) =>
          this.alertsService.addAlert({
            type: 'danger',
            message: this.translateService.instant(
              'shared.activity-timeline: Something went wrong while downloading the document')
          })
      );
  }

  getClassFromType(type: RdcActivityType | WireTransferFormActivityType): string {
    if (type.code === 'INFO_REQUESTED') {
      return 'secondary';
    }
    if (type.code === 'INFO_UPLOADED' || type.code === 'APPROVED_ULTIMATE_BENEFICIARY') {
      return 'success';
    }
    if (type.code === 'REJECTED_ULTIMATE_BENEFICIARY') {
      return 'danger';
    }
    if (type.code === 'SUBMITTED_ULTIMATE_BENEFICIARY') {
      return 'info';
    }
  }

  getIconFromType(type: RdcActivityType | WireTransferFormActivityType): string {
    if (type.code === 'INFO_REQUESTED' || type.code === 'INFO_UPLOADED') {
      return 'passport';
    }
    if (type.code === 'APPROVED_ULTIMATE_BENEFICIARY') {
      return 'check';
    }
    if (type.code === 'REJECTED_ULTIMATE_BENEFICIARY') {
      return 'times';
    }
    if (type.code === 'SUBMITTED_ULTIMATE_BENEFICIARY') {
      return 'user';
    }
  }

  format(comment: string) {
    return newLinesToBrTags(comment);
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime, map} from 'rxjs/operators';
import {defaultDebounceTime} from '@app/shared-module/utils/utils';
import {Transaction} from '@shared/models/transaction';
import {TranSubType} from '@shared/models/tran-sub-type';
import {AdminTransferState} from '@app/admin-module/states/admin.transfer.state';

@Component({
  selector: 'return-fee-modal',
  templateUrl: './return-fee-modal.component.html',
  styleUrls: ['./return-fee-modal.component.scss']
})
export class ReturnFeeModalComponent implements OnInit {

  @Input()
  transaction: Transaction;
  @Input()
  tooltip: string;
  @Input()
  icon: string;
  @Input()
  submitLabel: string;
  @Input()
  buttonType: string;

  @Output()
  submitted = new EventEmitter<string>();

  reverseFeeTypes: Observable<TranSubType[]>;
  reading: Observable<boolean>;
  form: Observable<FormGroup>;

  constructor(private modalService: NgbModal,
              private adminTransferState: AdminTransferState) {
  }

  ngOnInit() {
    this.reverseFeeTypes = this.adminTransferState.getReverseFeeSubTypes();
    this.reading = this.adminTransferState.getReadingFeeSubTypes().pipe(debounceTime(defaultDebounceTime));
    this.form = this.reverseFeeTypes.pipe(
      map(reverseFeeTypes => {
        return new FormGroup({
          comment: new FormControl(),
          reverseFeeType: new FormControl(this.getInitialReverseFeeType(reverseFeeTypes), Validators.required),
        });
      }));
  }

  getInitialReverseFeeType(reverseFeeTypes: TranSubType[]): string | null {
    if (this.transaction.sub_type) {
      const reverseType = reverseFeeTypes.find(it => it.type.includes(this.transaction.sub_type.type));
      return reverseType ? reverseType.type : null;
    }
    return null;
  }

  open(content, form: FormGroup) {
    this.modalService.open(content).result.then(() => {
      this.submitted.next(form.value);
    }, (reason) => {
    });
  }
}

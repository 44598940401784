import { Pipe, PipeTransform } from '@angular/core';
import * as currencyCodes from 'currency-codes';

@Pipe({
  name: 'currencyFullName'
})
export class CurrencyFullNamePipe implements PipeTransform {

  transform(value: string): string {
    return currencyCodes.code(value.toUpperCase()).currency;
  }

}

<button class="btn bg-purple-ionfi" (click)="open(content)"><fa-icon class="text-white" [classes]="['fa-fw']" icon="plus"></fa-icon></button>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{'shared.add-user-modal: Add new User' | translate}}</h4>
  </div>
  <form [formGroup]="formGroup" class="custom-select_arrow-ionfi" (validSubmit)="c()" role="form">
    <div class="modal-body">
      <div class="form-group">
        <label>{{'shared.add-user-modal: Email' | translate}}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="email">
      </div>
      <ng-container *ngIf="isIonfi">
        <div class="form-group custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="includeInMailingLists"
                 formControlName="includeInMailingLists">
          <label class="custom-control-label"
                 for="includeInMailingLists">{{'shared.add-user-modal: Include in Mailing Lists' | translate}}</label>
        </div>
        <div class="form-group custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="isPaymentSupervisor"
                 formControlName="isPaymentSupervisor">
          <label class="custom-control-label"
                 for="isPaymentSupervisor">{{'shared.add-user-modal: Payment Supervisor' | translate}}</label>
        </div>
      </ng-container>
      <div class="form-group">
        <label>{{'shared.add-user-modal: First Name' | translate }}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="firstName">
      </div>
      <div class="form-group">
        <label>{{'shared.add-user-modal: Last Name' | translate}}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="lastName">
      </div>
      <div class="form-group form-group-date d-flex flex-column">
        <label>{{'shared.add-user-modal: Date of Birth' | translate}}</label>
        <mat-form-field floatLabel="never">
          <input formControlName="dateOfBirth" matInput [matDatepicker]="dateOfBirth"
                 placeholder="{{'shared.add-user-modal: Choose a date' | translate}}" [readonly]="true">
          <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
          <mat-datepicker #dateOfBirth></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-group">
        <label>{{'shared.add-user-modal: Office phone number' | translate}}</label>
        <input class="form-control" formControlName="officePhoneNumber">
      </div>
      <div class="form-group">
        <label>{{'shared.add-user-modal: Cellphone number' | translate}}</label>
        <input class="form-control" formControlName="cellphoneNumber">
      </div>
      <div class="form-group">
        <label>{{'shared.add-user-modal: Position in company' | translate}}</label>
        <input class="form-control" formControlName="positionInCompany">
      </div>
      <div class="form-group">
        <label>{{'shared.add-user-modal: Type' | translate}}</label><span class="text-purple-ionfi">*</span>
        <select class="form-control" type="select" formControlName="type">
          <option [ngValue]="null">{{'shared.add-user-modal: Select' | translate}}</option>
          <option *ngFor="let type of (types | async)" [ngValue]="type">{{type.type}}</option>
        </select>
        <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
      </div>
      <div class="form-group">
        <label>{{'shared.add-user-modal: Scanner IP address' | translate}}</label>
        <input class="form-control" formControlName="scannerIp">
      </div>
      <div class="form-group">
        <label>{{'shared.add-user-modal: Scanner Port' | translate}}</label>
        <input class="form-control" type="number" formControlName="scannerPort" placeholder="5660">
      </div>
      <div *ngIf="canChangeScanThroughBrowser" class="form-group custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="scanThroughBrowser"
               formControlName="scanThroughBrowser">
        <label class="custom-control-label"
               for="scanThroughBrowser">{{'shared.add-user-modal: Scan Through Browser' | translate}}</label>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="close" aria-label="Close" (click)="d()" class="btn cancel-button">{{'shared.update-bank-modal: Cancel' | translate}}</button>
      <button type="button" type="submit" class="btn purple-button">{{'shared.add-user-modal: Add' | translate}}</button>
    </div>
  </form>
</ng-template>


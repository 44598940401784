<div class="header-fields">
  <div class="nested-menu" *ngIf="currentProfile | async as u">
    <a class="list-group-item text-white text-center" (click)="addExpandClass('profile')">
      <span class="align-self-center"><fa-icon [classes]="['fa-fw']" icon="user-tie"></fa-icon>
        <span *ngIf="u.user">{{u.user.first_name}} {{u.user.last_name}}</span>
        <span *ngIf="u.selfRegisteredUser">{{u.selfRegisteredUser.first_name}} {{u.selfRegisteredUser.last_name}}</span>
        <span *ngIf="u.person">{{u.person.first_name}} {{u.person.last_name}}</span>
        <span *ngIf="u.organisation">{{u.organisation.name}}</span>
      </span>
    </a>
    <li class="nested" [class.expand]="showMenu === 'profile'">
      <ul class="submenu">
        <li>
          <a class="text-dark-9-opacity" [routerLink]="['/profile/home']">
            <span><fa-icon [classes]="['fa-fw']" icon="user-tie"></fa-icon> {{'shared.sidebar-user: My Profile' | translate}}</span>
          </a>
        </li>
        <li>
          <a class="text-dark-9-opacity" href="javascript:void(0)" (click)="logout()">
            <span><fa-icon [classes]="['fa-fw']" icon="sign-out-alt"></fa-icon> {{'shared.sidebar-user: Logout' | translate}}</span>
          </a>
        </li>
      </ul>
    </li>
  </div>
</div>

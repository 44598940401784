import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'shared-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {

  @Input()
  disabled = false;

  @Input()
  purple = true;

  @Input()
  tooltipText: string;

  @Input()
  class = 'text-purple-ionfi';

  @Output()
  confirmed = new EventEmitter<boolean>();

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
  }

  open(content) {
    this.modalService.open(content).result.then(() => {
      this.confirmed.next(true);
    }, (reason) => {
      // do nothing
    });
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {nanoid} from "nanoid";

@Component({
  selector: 'shared-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss'],
})
export class FilesUploadComponent implements OnInit {

  @Input()
  disabled = false;

  @Output()
  uploadFiles: EventEmitter<{ files: File[] }> = new EventEmitter();

  uploadId: string;

  constructor() {
  }

  ngOnInit() {
    this.uploadId = nanoid(5);
  }

  public fileUpload(event) {
    const filesUploaded = <FileList>event.target.files;
    let files = [];
    for (let i = 0; i < filesUploaded.length; i++) {
      files.push(filesUploaded.item(i));
    }
    event.srcElement.value = null;
    this.uploadFiles.emit({files: files});

  }

  public async dropped(_files: NgxFileDropEntry[]) {
    const filePromises = [];
    for (const droppedFile of _files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      filePromises.push(new Promise(resolve => {
        fileEntry.file((file) => {
          resolve(file);
        });
      }));
    }
    const files = await Promise.all(filePromises);
    this.uploadFiles.emit({files: files});
  }

  openFileChooseDialog() {
    document.getElementById(this.uploadId).click();
  }
}

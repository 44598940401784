import {Injectable} from '@angular/core';
import {nanoid} from 'nanoid';
import {ScanningResult} from '@shared/models/scanning.result';
import {ScannerInfo} from '@shared/models/scanner.info';
import {ApiService} from '@app/core-module/services/api.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, Observer, take} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ScanningMessage} from '@shared/models/scanning.message';
import {notEmpty} from '@shared/utils/utils';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class LocalScanningCheckService {

  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  public scanOne(ipAddress: string, port: number): Observable<ScanningMessage> {
    return fromPromise(this.localScan(ipAddress, port)).pipe(
      switchMap((scanningResult: ScanningResult) => this.apiService.checkDuplicateLocallyScannedRdc(scanningResult))
    );
  }

  private async localScan(ipAddress: string, port: number): Promise<ScanningResult> {
    const scanner = new Scanner(ipAddress, port, this.http, this.apiService);
    await scanner.setNonBatchScanning();
    const result = await scanner.DCCScan();
    return await this.getScanningResult(scanner, result);
  }

  public scanBatch(ipAddress: string, port: number): Observable<ScanningMessage> {
    return Observable.create(async (observer: Observer<ScanningMessage>) => {
      const scanner = new Scanner(ipAddress, port, this.http, this.apiService);
      let result;
      await scanner.setBatchScanning();
      let timeout = null;
      let stop = false;
      while (!stop) {
        do {
          result = await scanner.DCCScan();
          let setTimeOut = false;
          if (result['Result'] === -212) {
            observer.next(<ScanningMessage>{
              information: 'Hopper is empty. Please insert checks!',
              failedToScan: true
            });
            setTimeOut = true;
          } else if (result['Result'] === -220) {
            observer.next(<ScanningMessage>{
              information: 'Paper jam!',
              failedToScan: true
            });
            setTimeOut = true;
          } else if (result['Result'] === -216) {
            observer.next(<ScanningMessage>{
              information: 'Unable to read a check!',
              failedToScan: true
            });
            setTimeOut = true;
          } else if (result['Result'] < 0) {
            observer.next(<ScanningMessage>{
              information: 'Unexpected problem with the scanner!',
              failedToScan: true
            });
            setTimeOut = true;
          }
          if (setTimeOut && !timeout) {
            timeout = setTimeout(() => {
              observer.complete();
              stop = true;
            }, 10000);
          }
        } while (result['Result'] < 0 && !stop);
        if (result['Result'] === 0) {
          clearTimeout(timeout);
          timeout = null;
          const promise = new Promise<void>((resolve, reject) => {
            fromPromise(this.getScanningResult(scanner, result)).pipe(
              switchMap((scanningResult: ScanningResult) => {
                return this.apiService.checkDuplicateLocallyScannedRdcBatch(scanningResult);
              })
            ).subscribe((scanningMessage: ScanningMessage) => {
              observer.next(scanningMessage);
              resolve();
            });
          });
          await promise;
        } else {
          stop = true;
          observer.error(new Error('Scanning failed with invalid result'));
        }
      }
    });


  }

  public async getScannerInfo(ipAddress: string, port: number): Promise<ScannerInfo> {
    const scanner = new Scanner(ipAddress, port, this.http, this.apiService);
    return await scanner.getScannerInfo();
  }

  private async getScanningResult(scanner: Scanner, result): Promise<ScanningResult> {
    const scanningResult = new ScanningResult();
    const fld2: string = result['MicrFields']['Fld2'];
    const fld3: string = result['MicrFields']['Fld3'];
    const fld4: string = result['MicrFields']['Fld4'];
    const fld5: string = result['MicrFields']['Fld5'];
    const fld7: string = result['MicrFields']['Fld7'];
    if (notEmpty(fld2) && notEmpty(fld3) && notEmpty(fld5) && notEmpty(fld7)) {
      // this is Treasury Check
      scanningResult.checkNumber = fld3.substring(0, fld3.length - 1);
      scanningResult.checkRoutingAba = fld5;
      scanningResult.checkAccountNumber = fld7.substring(0, fld7.length - 1);
    } else if (!notEmpty(fld2) && notEmpty(fld3) && !notEmpty(fld4) && notEmpty(fld5) && !notEmpty(fld7)) {
      // Treasury Check from California
      scanningResult.checkRoutingAba = fld5;
      scanningResult.checkNumber = fld3.substring(0, fld3.length - 1);
    } else {
      // all other types of checks
      scanningResult.checkNumber = fld2;
      if (!notEmpty(scanningResult.checkNumber)) {
        scanningResult.checkNumber = fld7;
      }
      if (!notEmpty(scanningResult.checkNumber)) {
        scanningResult.checkNumber = fld4;
      }
      scanningResult.checkRoutingAba = fld5;
      scanningResult.checkAccountNumber = fld3;
    }

    if (scanningResult.checkAccountNumber) {
      scanningResult.checkAccountNumber = scanningResult.checkAccountNumber.split('=').join('-');
    }
    if (!notEmpty(scanningResult.checkNumber) || !notEmpty(scanningResult.checkAccountNumber)
      || !notEmpty(scanningResult.checkAccountNumber)) {
      // logger.warn('properties missing in the scanning result');
      // logger.warn(JSON.stringify(result));
    }

    const id = nanoid(48);

    const fgsPromise = scanner.downloadImageAndSendToServer('fgs', id, 'jpg');
    const fbwPromise = scanner.downloadImageAndSendToServer('fbw', id, 'tiff');
    const rgsPromise = scanner.downloadImageAndSendToServer('rgs', id, 'jpg');
    const rbwPromise = scanner.downloadImageAndSendToServer('rbw', id, 'tiff');

    await Promise.all([fgsPromise, fbwPromise, rgsPromise, rbwPromise]);

    scanningResult.front_jpg_filename = id + '-fgs.jpg';
    scanningResult.back_jpg_filename = id + '-rgs.jpg';
    scanningResult.front_tiff_filename = id + '-fbw.tiff';
    scanningResult.back_tiff_filename = id + '-rbw.tiff';
    return scanningResult;
  }

  eject(ipAddress: string, port: number): Observable<void> {
    const scanner = new Scanner(ipAddress, port, this.http, this.apiService);
    return fromPromise(scanner.BUICEjectDocument());
  }

}

const CFG_MISC_SCANBATCH_ENABLE = 160;
const CFG_RO_DOCS_IN_TRACK = 211;
const CFG_RO_SCANBATCH_ABLE = 206;
const CFG_RO_PRINT_CARTRIDGE_LOADED = 207;

class Scanner {

  constructor(private ipAddress: string,
              private port: number,
              private http: HttpClient,
              private apiService: ApiService) {

  }

  private PROTOCOL = 'https://';

  private async sendRequest(command: number, params = {}): Promise<Object> {
    const json = {
      Function: 'DCCCommand',
      FunctionCall: command,
    };
    return new Promise<Object>(((resolve, reject) => {
      this.http.post(this.PROTOCOL + this.ipAddress + ':' + this.port + '/anything', {...json, ...params}
      ).subscribe(response => {
        resolve(response);
      }, error => {
        reject(error);
      });

    }));
  }

  async getScannerInfo(): Promise<ScannerInfo> {
    const info$ = this.BUICGetScannerInfo();
    const batchAvailable$ = this.isBatchScanningAvailable();
    const numBatchAvailable$ = this.getMaxDocumentsPerBatch();
    const printCartridgeLoaded$ = this.isPrintCartridgeLoaded();
    const info = await info$;
    [info.batchAvailable, info.numBatchAvailable, info.printCartridgeLoaded] = await Promise.all(
      [batchAvailable$, numBatchAvailable$, printCartridgeLoaded$]);
    return info;
  }

  async BUICGetScannerInfo(): Promise<ScannerInfo> {
    let result = await this.sendRequest(5, {Adapter: 0, Target: 0});
    const info: ScannerInfo = new ScannerInfo();
    if (!info.isConnected()) {
      // try to re-init
      await this.BUICExit();
      await this.BUICInit();
      result = await this.sendRequest(5, {Adapter: 0, Target: 0});
      Object.assign(info, result);
    }
    return info;
  }

  async BUICInit(): Promise<boolean> {
    const result = await this.sendRequest(1);
    return result['Result'] === -114 || result['Result'] === 1;
  }

  async BUICExit(): Promise<boolean> {
    const result = await this.sendRequest(2);
    return result['Result'] >= 0;
  }

  /**
   * True if the scanner is initialized and there is check in.
   */
  async BUICStatus(): Promise<boolean> {
    const result = await this.sendRequest(3);
    return result['Result'] > 0;
  }

  async DCCScan(): Promise<Object> {
    return await this.sendRequest(13);
  }

  async BUICSetParm(param: number, value: number): Promise<boolean> {
    const result = await this.sendRequest(7, {Param: param, Value: value});
    return result['Result'] === -114 || result['Result'] === 1;
  }

  async BUICGetParm(param: number): Promise<number> {
    const result = await this.sendRequest(8, {Param: param});
    return result['Result'];
  }

  async BUICEjectDocument(): Promise<void> {
    await this.sendRequest(9);
  }

  async setNonBatchScanning(): Promise<boolean> {
    return await this.BUICSetParm(CFG_MISC_SCANBATCH_ENABLE, 0);
  }

  async setBatchScanning(): Promise<boolean> {
    return await this.BUICSetParm(CFG_MISC_SCANBATCH_ENABLE, 1);
  }

  async isBatchScanningAvailable(): Promise<boolean> {
    const result = await this.BUICGetParm(CFG_RO_SCANBATCH_ABLE);
    return result === 1;
  }

  async isPrintCartridgeLoaded(): Promise<boolean> {
    const result = await this.BUICGetParm(CFG_RO_PRINT_CARTRIDGE_LOADED);
    return result === 1;
  }

  async getMaxDocumentsPerBatch(): Promise<number> {
    return await this.BUICGetParm(CFG_RO_DOCS_IN_TRACK);
  }

  downloadImageAndSendToServer(fileType: string, fileNameId: string, extension: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http.post<HttpResponse<any>>(this.PROTOCOL + this.ipAddress + ':' + this.port + '/DCCCommand/' + fileType, {}
      ).pipe(take(1)) // Unsubscribe after the first emitted value
      .subscribe(response => {
        const blob = new Blob([response.body]);
        const formData = new FormData();
        formData.append('file', blob, `${fileNameId}-${fileType}.${extension}`);
        const fileName = `${fileNameId}-${fileType}.${extension}`;
        this.apiService.uploadRdcLocallyScannedImage(fileName, formData)
          .subscribe(() => {
            resolve();
          }, (errorPost) => {
            reject(errorPost);
          });
      }, error => {
        reject(error);
      });
    });
  }

}


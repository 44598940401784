import {Component, Input, OnInit} from "@angular/core";
import {ToggleGraphsService} from "@app/shared-module/services/toggle-graphs.service";

@Component({
  selector: 'toggle-graphs-icon',
  templateUrl: './toggle-graphs-icon.component.html',
  styleUrls: ['./toggle-graphs-icon.component.scss']
})

export class ToggleGraphsIconComponent implements OnInit {

  showGraphs: boolean;

  constructor(private toggleGraphsService: ToggleGraphsService) {
  }

  ngOnInit() {
  }

  toggleGraphs() {
    this.showGraphs = !this.showGraphs;
    this.toggleGraphsService.toggleGraphs$.next(this.showGraphs);
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserTypesState} from '@app/core-module/states/user-types.state';
import {User} from '@shared/models/user';
import {UsersType} from '@shared/models/users-type';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'shared-add-user',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss'],
})
export class AddUserModalComponent implements OnInit {

  @Output()
  public addUser = new EventEmitter<User>();

  @Input()
  public canChangeScanThroughBrowser = false;

  @Input()
  public isIonfi = false;

  types: Observable<UsersType[]>;

  formGroup: FormGroup;

  constructor(private modalService: NgbModal,
              private userTypesState: UserTypesState) {

  }

  ngOnInit() {
    this.types = this.userTypesState.getUserTypes();
    this.formGroup = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      firstName: new FormControl('', [
        Validators.required
      ]),
      lastName: new FormControl('', [
        Validators.required
      ]),
      dateOfBirth: new FormControl(),
      officePhoneNumber: new FormControl(),
      cellphoneNumber: new FormControl(),
      positionInCompany: new FormControl(),
      type: new FormControl(null, [
        Validators.required,
      ]),
      scannerIp: new FormControl(),
      scannerPort: new FormControl(),
      includeInMailingLists: new FormControl(false),
      isPaymentSupervisor: new FormControl(false)
    });
    if (this.canChangeScanThroughBrowser) {
      this.formGroup.addControl('scanThroughBrowser', new FormControl(false));
    }
  }

  open(content) {
    this.modalService.open(content).result.then(() => {
      this.addUser.emit(<User>{
        first_name: this.formGroup.value['firstName'],
        last_name: this.formGroup.value['lastName'],
        email: this.formGroup.value['email'],
        scanner_ip: this.formGroup.value['scannerIp'],
        scanner_port: this.formGroup.value['scannerPort'],
        users_type_id: this.formGroup.value['type'].id,
        scan_through_browser: this.formGroup.get('scanThroughBrowser') ? this.formGroup.value['scanThroughBrowser'] : undefined,
        date_of_birth: this.formGroup.value['dateOfBirth'] ?  moment(this.formGroup.value['dateOfBirth']).format('YYYY-MM-DD') : null,
        position_in_company: this.formGroup.value['positionInCompany'],
        office_phone_number: this.formGroup.value['officePhoneNumber'],
        cellphone_number: this.formGroup.value['cellphoneNumber'],
        include_in_mailing_lists: this.formGroup.value['includeInMailingLists'],
        is_payment_supervisor: this.formGroup.value['isPaymentSupervisor']
      });
      this.formGroup.reset();
    }, (reason) => {
      // do nothing
    });
  }
}

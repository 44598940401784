import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FinancialInstitution} from "@shared/models/financial-institution";
import {AbstractBankModalComponent} from "@app/shared-module/components/abstract.bank.modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'shared-update-bank-modal',
  templateUrl: './update-bank-modal.component.html',
  styleUrls: ['./update-bank-modal.component.scss']
})
export class UpdateBankModalComponent extends AbstractBankModalComponent implements OnInit {


  @Input()
  public bank: FinancialInstitution;

  @Output()
  public updateFI = new EventEmitter<FinancialInstitution>();

  constructor(private modalService: NgbModal) {
    super();
  }

  ngOnInit() {
    this.initForm(this.bank);
  }

  open(content) {
    this.modalService.open(content).result.then(() => {
      const newBank = Object.assign({}, this.bank);
      this.addFormValues(newBank);
      this.updateFI.emit(newBank);
    }, (reason) => {
      // do nothing
    });

  }
}

import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Account} from '@shared/models/account';

export function notRequiredEmailValidator(control: AbstractControl): ValidationErrors {
  return !control.value ? null : Validators.email(control);
}

export function WireFormEntityRequired(control: AbstractControl): ValidationErrors {
  return control.value && (control.value.hasOwnProperty('id_number') ||  control.value.hasOwnProperty('bank_number')) ? null : {required: 'This field is required'};
}

export function validateBankAccountNumber(): ValidatorFn {
  return (control: FormGroup): { [key: string]: any } | null => {
    const numberType = control.get(`bank_number_type`);
    const number = control.get(`bank_number`);
    if (numberType.value === 'ABA_ROUTING_NUMBER') {

      if (number.value && number.value.length !== 9) {
        number.setErrors({'accountNumber': true});
        return {'accountNumber': true};
      }
    }
    return null;
  };
}

export function validateSupportDocuments(accounts: Account[]): ValidatorFn {
  return (control: FormGroup): { [key: string]: any } | null => {
    const accountId = Number(control.get('account_id').value);
    if (isNaN(accountId)) {
      return null;
    }
    const account = accounts.find(it => it.id === accountId);
    if (!account) {
      return null;
    }
    const amount = control.get(`domestic_amount`);
    const documents = control.get(`documents`);

    if (amount.value > account.threshold_mandatory_documents && (!documents.value || !documents.value.length) && account.threshold_mandatory_documents > 0) {
        documents.setErrors({'requiredDocuments': true});
        return {'requiredDocuments': true};
    }
    return null;
  };
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionDefinition, SubQuestion} from '@shared/models/question-definition';
import moment from 'moment';

@Component({
  selector: 'shared-answer-view',
  templateUrl: './answer-view.component.html',
  styleUrls: ['./answer-view.component.scss']
})
export class AnswerViewComponent implements OnInit {

  @Input()
  question: QuestionDefinition | SubQuestion;

  @Output()
  downloadPart = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  downloadFile(part: string) {
    this.downloadPart.emit(part);
  }

  trimTimestampFromFilename(filename: string): string {
    let result = filename.slice(filename.indexOf('-') + 1);
    if (result.length > 20) {
      result = result.substring(0, 17) + '...';
    }
    return result;
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    return moment(dateString).format('MM/DD/YYYY');
  }

}

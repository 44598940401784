import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Transaction} from '@shared/models/transaction';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {FinancialInstitution} from '@shared/models/financial-institution';
import {CurrentBankState} from '@app/core-module/states/current.bank.state';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {PAGE_SIZE} from '@shared/utils/constants';
import {getAccountLink} from '@app/shared-module/utils/account.utils';
import {Observable} from 'rxjs';
import {Account} from "@shared/models/account";

@Component({
  selector: 'transactions-table',
  templateUrl: './transactions-table.component.html',
  styleUrls: ['./transactions-table.component.scss']
})
export class TransactionsTableComponent implements OnInit {

  PAGE_SIZE = PAGE_SIZE;

  @Input()
  showActionButtons = false;

  @Input()
  transactions: Transaction[];

  @Input()
  enableScrollbar = true;

  @Input()
  enableDescriptionWireFormLink = true;

  @Input()
  externalPaging = false;

  @Input()
  externalSorting = false;

  @Input()
  count: number;

  @Input()
  tableClass = 'table-calc-vh';

  @Input()
  isSegregatedAccountTable = false;

  @Input()
  isIonfiTable = false;

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onPage = new EventEmitter<{ count: number; pageSize: number; limit: number; offset: number }>();

  @Output()
  onSort = new EventEmitter<{ newValue: string }>();

  @Output()
  feeReturnEmitted = new EventEmitter<{
    transaction: Transaction,
    formValue: { comment: string, reverseFeeType: string }
  }>();

  currentFI: Observable<FinancialInstitution>;

  isAdmin: boolean;


  constructor(private alertsService: AlertsService,
              private currentBankState: CurrentBankState,
              private router: Router,
              private translateService: TranslateService) {
    this.currentFI = this.currentBankState.getCurrentBank();
  }

  ngOnInit() {
    this.isAdmin = this.router.url.includes('/admin');
  }

  returnFeeSubmit(transaction: Transaction, formValue: { comment: string, reverseFeeType: string }) {
    this.feeReturnEmitted.emit({transaction, formValue});
  }

  emitCloseModal() {
    this.closeModal.emit();
  }

  getAccountLink(transaction: Transaction): string {
    return getAccountLink(transaction, this.isAdmin, this.isSegregatedAccountTable, this.isIonfiTable);
  }
  canSeeAccountLink(account: Account, currentFi: FinancialInstitution) {
    return (this.isAdmin || account.fi_id === currentFi.id);
  }
  isDisplayingMoreAccounts() {
    const accounts: Account[] = [];
    this.transactions.forEach(tr => {
      if (!tr.account) {
        return;
      }
      if (!accounts.find(a => a.id === tr.account.id)) {
        accounts.push(tr.account);
      }
    });
    return accounts.length > 1;
  }
}

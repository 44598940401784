import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {ApiService} from '@app/core-module/services/api.service';

@Component({
  selector: 'input-currency',
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.scss']
})
export class InputCurrencyComponent implements OnInit {

  @Input() labelText: string;
  @Input() importantAsteriks: string;
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() fiFormInput: boolean;

  currenciesObservable: Observable<{ value: string, currencyName: string }[]>;

  constructor(private apiService: ApiService) {
    this.currenciesObservable = this.apiService.getCurrencies();
  }

  ngOnInit() {
  }

}

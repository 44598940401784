import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'entityNumberType'
})
export class EntityNumberTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any): string {
    switch (value) {
      case 'ABA_ROUTING_NUMBER': return this.translateService.instant('shared-module.entity-number-type-pipe: ABA Routing Number');
      case 'DDA_NUMBER': return this.translateService.instant('shared-module.entity-number-type-pipe: DDA Number');
      case 'SWIFT_CODE': return this.translateService.instant('shared-module.entity-number-type-pipe: SWIFT Code');
      case 'SORT_CODE': return this.translateService.instant('shared-module.entity-number-type-pipe: Sort Code');
      case 'BANK_CODE': return this.translateService.instant('shared-module.entity-number-type-pipe: Bank Code');
      case 'OTHER': return this.translateService.instant('shared-module.entity-number-type-pipe: Other');
      case 'Passport Number': return this.translateService.instant('shared-module.entity-number-type-pipe: Passport Number');
      case 'Tax Identification Number': return this.translateService.instant('shared-module.entity-number-type-pipe: Tax Identification Number');
      case 'Driver’s License Number': return this.translateService.instant('shared-module.entity-number-type-pipe: Driver’s License Number');
      case 'Alien Registration Number': return this.translateService.instant('shared-module.entity-number-type-pipe: Alien Registration Number');
      case 'Corporate Identification': return this.translateService.instant('shared-module.entity-number-type-pipe: Corporate Identification');
      case 'Other Identification': return this.translateService.instant('shared-module.entity-number-type-pipe: Other Identification');
      default: return value;
    }
  }

}

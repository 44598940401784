<ngx-datatable class="bootstrap table-calc-vh"
               [reorderable]="false"
               [columnMode]="'force'"
               [sorts]="[{prop: 'created_at',
                                    dir: 'asc'
                                }]"
               [rows]="filteredRDCs"
               [scrollbarH]="true"
               [headerHeight]="50"
               [footerHeight]="50"
               [rowHeight]="'auto'"
               [limit]="PAGE_SIZE"
               [messages]="numberOfRecords ? (numberOfRecords | tableMessages:false) : (filteredRDCs | tableMessages:true)">

  <ngx-datatable-column *ngIf="!isAdmin" [sortable]="false" headerClass="pl-22" cellClass="pl-22">
    <ng-template let-rdc="row" ngx-datatable-cell-template>
      <ngb-carousel>
        <ng-template ngbSlide>
          <a class="text-purple-ionfi" target="_blank"
             [href]="imagesDomain + '/scanimage/' + rdc.front_jpg_filename">
            <img class="img-fluid"
                 [src]="imagesDomain + '/scanimage/' + rdc.front_jpg_filename"/>
          </a>
        </ng-template>
        <ng-template ngbSlide>
          <a class="text-purple-ionfi" target="_blank" [href]="imagesDomain + '/scanimage/' + rdc.back_jpg_filename">
            <img class="img-fluid"
                 [src]="imagesDomain + '/scanimage/' + rdc.back_jpg_filename"/>
          </a>
        </ng-template>
      </ngb-carousel>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.processing-rdcs-table: Date Created' | translate}}" prop="created_at">
    <ng-template let-rdc="row" ngx-datatable-cell-template>
      <span class="table-item-text">{{ rdc.created_at | date: 'short' }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="isAdmin" name="FI" prop="fi_name">
    <ng-template let-rdc="row" ngx-datatable-cell-template>
      <a class="text-purple-ionfi" [routerLink]="'/admin/fi/' + rdc.fi.id">{{rdc.fi.name}}</a>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.processing-rdcs-table: Check#' | translate}}" prop="check_number">
    <ng-template let-rdc="row" ngx-datatable-cell-template>
      <a class="text-purple-ionfi-with-transparency" [routerLink]="isAdmin ? '/admin/rdc/rdc-activity/' + rdc.id : url.includes('scanning-queue') ? '/fi/rdc-dashboard/scanning-queue/' + rdc.id : '/fi/rdc-dashboard/scanning-queue/' + rdc.id">
        {{ rdc.check_number }}
      </a>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.processing-rdcs-table: Routing ABA' | translate}}" prop="check_routing_aba">
    <ng-template let-rdc="row" ngx-datatable-cell-template>
      <span class="table-item-text">{{ rdc.check_routing_aba }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.processing-rdcs-table: Account#' | translate}}" prop="check_acc_no">
    <ng-template let-rdc="row" ngx-datatable-cell-template>
      <span class="table-item-text">{{ rdc.check_acc_no }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="showStatusColumn" name="{{'shared.rdcs-table: Status' | translate}}" prop="statusCode.status" headerClass="text-center" cellClass="d-flex justify-content-center">
    <ng-template let-rdc="row" ngx-datatable-cell-template>
      <span [ngStyle]="{'color' : rdc.statusCode.text_color, 'background-color' : rdc.statusCode.background_color}" *ngIf="rdc.statusCode.status === 'Not Processed'" class="table-item-text type-design-circle">Not Processed</span>
      <span [ngStyle]="{'color' : rdc.statusCode.text_color, 'background-color' : rdc.statusCode.background_color}" *ngIf="rdc.statusCode.status === 'Excel Populated'" class="table-item-text type-design-circle">Excel Populated</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.processing-rdcs-table: Suggested Info' | translate}}" [sortable]="false" headerClass="text-center" cellClass="text-center">
    <ng-template let-rdc="row" ngx-datatable-cell-template>
      <div class="text-green"
           *ngIf="!rdc.payor && rdc.has_payor_suggestion">{{ 'shared.processing-rdcs-table: Payor' | translate }}</div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="{{'shared.processing-rdcs-table: Missing Info' | translate}}" [sortable]="false" headerClass="text-center" cellClass="text-center">
    <ng-template let-rdc="row" ngx-datatable-cell-template>
        <div class="text-danger" *ngIf="!rdc.check_number">{{ 'shared.processing-rdcs-table: Check#' | translate }}</div>
      <div class="text-danger" *ngIf="!rdc.check_routing_aba">{{ 'shared.processing-rdcs-table: Routing ABA' | translate }}</div>
      <div class="text-danger" *ngIf="!rdc.check_acc_no">{{ 'shared.processing-rdcs-table: Account#' | translate }}</div>
      <div class="text-danger"
           *ngIf="!rdc.payor && !rdc.has_payor_suggestion">{{ 'shared.processing-rdcs-table: Payor' | translate }}</div>
      <div class="text-danger" *ngIf="!rdc.payeePerson">{{ 'shared.processing-rdcs-table: Payee' | translate }}</div>
      <div class="text-danger" *ngIf="!rdc.check_issue_date">{{ 'shared.processing-rdcs-table: Issue Date' | translate }}</div>
      <div class="text-danger" *ngIf="!rdc.check_amount">{{ 'shared.processing-rdcs-table: Amount' | translate }}</div>
      <ng-container *ngIf="rdc.check_amount >= 3000">
        <ng-container *ngIf="rdc.payor">
          <div class="text-danger"
               *ngIf="!rdc.payor.address">{{ 'shared.processing-rdcs-table: Payor Address' | translate }}</div>
          <div class="text-danger" *ngIf="!rdc.payor.city">{{ 'shared.processing-rdcs-table: Payor City' | translate }}</div>
          <div class="text-danger" *ngIf="!rdc.payor.zip">{{ 'shared.processing-rdcs-table: Payor ZIP' | translate }}</div>
          <div class="text-danger"
               *ngIf="!rdc.payor.country">{{ 'shared.processing-rdcs-table: Payor Country' | translate }}</div>
        </ng-container>
        <ng-container *ngIf="rdc.payeePerson">
          <div class="text-danger"
               *ngIf="!rdc.payeePerson.address">{{ 'shared.processing-rdcs-table: Payee Address' | translate }}</div>
          <div class="text-danger"
               *ngIf="!rdc.payeePerson.city">{{ 'shared.processing-rdcs-table: Payee City' | translate }}</div>
          <div class="text-danger"
               *ngIf="!rdc.payeePerson.zip">{{ 'shared.processing-rdcs-table: Payee ZIP' | translate }}</div>
          <div class="text-danger"
               *ngIf="!rdc.payeePerson.country">{{ 'shared.processing-rdcs-table: Payee Country' | translate }}</div>
        </ng-container>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="showActionButtons" [sortable]="false" [width]="1"
  headerClass="text-center" cellClass="d-flex justify-content-end pr-3">
    <ng-template let-rdc="row" ngx-datatable-cell-template>
        <shared-confirm-delete (confirmed)="delete(rdc)" class="table-item-text"></shared-confirm-delete>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {QuestionDefinition} from '@shared/models/question-definition';
import {AnswerForBlock, FileInput} from '@shared/models/non-db/questions-interfaces';


export interface QuestionAnswerItem {
  formGroup: FormGroup;
  questionDefinition: QuestionDefinition;
  files: QuestionFile[];
}

export interface QuestionFile {
  sub_id: string;
  files: File[];
}

export interface QuestionFormData {
  blocks: AnswerForBlock[];
  files: File[];
}

export function createContactFormGroup(type: string, name: string, email: string, phone: string, mandatoryValidators) {
  return new FormGroup({
    type: new FormControl(type, mandatoryValidators),
    name: new FormControl(name, mandatoryValidators),
    email: new FormControl(email, [...mandatoryValidators, Validators.email]),
    phone: new FormControl(phone, mandatoryValidators)
  });
}

export function createAddressFormGroup(street: string, city: string, state: string, country: string, zip: string, mandatoryValidators) {
  return new FormGroup({
    street: new FormControl(street, mandatoryValidators),
    city: new FormControl(city, mandatoryValidators),
    state: new FormControl(state),
    country: new FormControl(country, mandatoryValidators),
    zip: new FormControl(zip, mandatoryValidators)
  });
}

export function createFormGroupFromDefinition(question: QuestionDefinition): FormGroup {
  const formGroup = new FormGroup({});
  const formArray = new FormArray([]);
  const mandatoryValidators = question.mandatory ? [Validators.required] : [];
  if (question.type_of_question === 'check') {
    question.offered_answers.forEach(answer => formArray.push(new FormControl(false, [Validators.required])));
  } else if (question.type_of_question === 'contact') {
    formArray.push(createContactFormGroup(null, null, null, null, mandatoryValidators));
  } else if (question.type_of_question === 'address') {
    formArray.push(createAddressFormGroup(null, null, null, null, null, mandatoryValidators));
  } else if (question.type_of_question === 'slider') {
    formArray.push(new FormControl(false, mandatoryValidators));
  } else if (question.type_of_question !== 'file') {
    formArray.push(new FormControl(null, mandatoryValidators));
  } else if (question.type_of_question === 'file' && question.mandatory) {
    formArray.setValidators(moreThanOneFileValidator());
  }
  formGroup.addControl('main', formArray);

  question.sub_questions.forEach((sub_question) => {
    const subFormArray = new FormArray([]);
    const subFormMandatoryValidators = sub_question.mandatory ? [Validators.required] : [];
    if (sub_question.type_of_question === 'check') {
      sub_question.offered_answers.forEach(answer => subFormArray.push(new FormControl(false, subFormMandatoryValidators)));
    } else if (sub_question.type_of_question === 'contact') {
      subFormArray.push(createContactFormGroup(null, null, null, null, subFormMandatoryValidators));
    } else if (sub_question.type_of_question === 'address') {
      subFormArray.push(createAddressFormGroup(null, null, null, null, null, subFormMandatoryValidators));
    } else if (sub_question.type_of_question === 'slider') {
      subFormArray.push(new FormControl(false, subFormMandatoryValidators));
    } else if (sub_question.type_of_question !== 'file') {
      subFormArray.push(new FormControl(null, subFormMandatoryValidators));
    } else if (sub_question.type_of_question === 'file' && sub_question.mandatory) {
      subFormArray.setValidators(moreThanOneFileValidator());
    }
    formGroup.addControl(String(sub_question.id), subFormArray);
  });
  return formGroup;
}

export function createFormGroupFromDefinitionWithInitialData(question: QuestionDefinition): FormGroup {
  const formGroup = new FormGroup({});
  const formArray = new FormArray([]);
  const mandatoryValidators = question.mandatory ? [Validators.required] : [];
  if (question.type_of_question === 'check') {
    question.offered_answers.forEach(answer => formArray.push(
      new FormControl(question.answer.text_answer.find(it => it === answer), [Validators.required])));
  } else if (question.type_of_question === 'contact') {
    if (question.answer.contact_answer) {
      question.answer.contact_answer.forEach(it => {
        formArray.push(createContactFormGroup(it.type, it.name, it.email, it.phone, mandatoryValidators));
      });
    } else {
      formArray.push(createContactFormGroup(null, null, null, null, mandatoryValidators));
    }
  } else if (question.type_of_question === 'address') {
    if (question.answer.address_answer.length) {
      question.answer.address_answer.forEach(it => {
        formArray.push(createAddressFormGroup(it.street, it.city, it.state, it.country, it.zip, mandatoryValidators));
      });
    } else {
      formArray.push(createAddressFormGroup(null, null, null, null, null, mandatoryValidators));
    }
  } else if (question.type_of_question === 'slider') {
    formArray.push(new FormControl(question.answer.text_answer.includes('true'), mandatoryValidators));
  } else if (question.type_of_question === 'file' && question.mandatory) {
    // form inputs should not be mandatory on edit
  } else {
    question.answer.text_answer.forEach(it => {
      formArray.push(new FormControl(it, mandatoryValidators));
    });
  }
  formGroup.addControl('main', formArray);

  question.sub_questions.forEach((sub_question) => {
    const subFormArray = new FormArray([]);
    const subFormMandatoryValidators = sub_question.mandatory ? [Validators.required] : [];
    if (sub_question.type_of_question === 'check') {
      sub_question.offered_answers.forEach(answer => subFormArray.push(
        new FormControl(sub_question.answer.text_answer.find(it => it === answer), subFormMandatoryValidators)));
    } else if (sub_question.type_of_question === 'contact') {
      if (sub_question.answer.contact_answer.length) {
        sub_question.answer.contact_answer.forEach(it => {
          subFormArray.push(createContactFormGroup(it.type, it.name, it.email, it.phone, subFormMandatoryValidators));
        });
      } else {
        subFormArray.push(createContactFormGroup(null, null, null, null, subFormMandatoryValidators));
      }
    } else if (sub_question.type_of_question === 'address') {
      if (sub_question.answer.address_answer.length) {
        sub_question.answer.address_answer.forEach(it => {
          subFormArray.push(createAddressFormGroup(it.street, it.city, it.state, it.country, it.zip, subFormMandatoryValidators));
        });
      } else {
        subFormArray.push(createAddressFormGroup(null, null, null, null, null, subFormMandatoryValidators));
      }
    } else if (sub_question.type_of_question === 'slider') {
      subFormArray.push(new FormControl(sub_question.answer.text_answer.includes('true'), subFormMandatoryValidators));
    } else if (sub_question.type_of_question === 'file' && sub_question.mandatory) {
      // form inputs should not be mandatory on edit
    } else {
      if (sub_question.answer.text_answer.length) {
        sub_question.answer.text_answer.forEach(it => {
          subFormArray.push(new FormControl(it, subFormMandatoryValidators));
        });
      } else {
        subFormArray.push(new FormControl(null, subFormMandatoryValidators));
      }
    }
    formGroup.addControl(sub_question.id, subFormArray);
  });
  return formGroup;
}

export function createFormFieldNameForFile(index: number, sub_id: string, filename: string) {
  return index + '_' + sub_id + '-' + filename;
}

export function parseFormDataForSubmit(formGroups: FormGroup[], questionFiles: QuestionFile[][]): QuestionFormData {
  const formData = new FormData();

  const result: QuestionFormData = {
    blocks: [],
    files: []
  };
  const fileMaps: FileInput[] = [];

  questionFiles.forEach((block, index) => {
    block.forEach(value => {
      value.files.forEach(file => {
        fileMaps.push({
          filename: createFormFieldNameForFile(index, value.sub_id, file.name),
          originalname: file.name
        });
        result.files.push(file);
      });
    });
  });

  questionFiles.forEach((block, index) => {
    block.forEach(value => {
      value.files.forEach((file: File) => {
        formData.append('files', file, createFormFieldNameForFile(index, value.sub_id, file.name));
      });
    });
  });

  for (let i = 0; i < formGroups.length; i++) {
    const objectForAppend = {};
    for (const field in formGroups[i].controls) {
      const control = formGroups[i].get(field);
      const formArrayControl = <FormArray>control;
      const array = [];
      for (const arrayControl in formArrayControl.controls) {
        const controlValue = formArrayControl.get(arrayControl).value;
        const fileMapWithThisValue = fileMaps.find(it => it.filename === controlValue);
        if (fileMapWithThisValue) {
          array.push(fileMapWithThisValue.originalname);
        } else {
          array.push(controlValue);
        }
      }
      objectForAppend[field] = array;
    }
    result.blocks.push(objectForAppend);
  }

  return result;
}

export function allFormGroupsValid(formGroups: FormGroup[]) {
  let valid = true;
  formGroups.forEach((group: FormGroup) => valid = valid && group.valid);
  return valid;
}

function moreThanOneFileValidator() {
  return (c: AbstractControl): { [key: string]: any } => {
    if (c.value.length > 0) {
      return null;
    }
    return {'minLengthArray': {valid: false}};
  };
}

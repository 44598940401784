import {DbModel} from "./db.model";
import {FinancialInstitution} from "./financial-institution";
import {IdType} from "./id-type";
import {KycProfile} from "./kyc-profile";
import {RDC} from "./rdc";
import {WireTransferForm} from "./wire-transfer-form";
import {ENTITY_TYPES_TYPE, PERSON_TYPES_TYPE} from "../utils/statuses.utils";
import {Account} from "./account";

export class Person extends DbModel {
    fi_id: number;
    kyc_profile_id: number;
    first_name: string;
    last_name: string;
    email: string;
    id_type_id: number;
    id_number: string;
    country: string;
    state: string;
    date_of_birth: string;
    country_of_birth: string;
    residency: string;
    citizenship: string;
    master: boolean;
    segregate_account: boolean

    // from wire transfer person
    name: string;
    address: string;
    city: string;
    zip: string;
    phone_number: string;
    account_number_a_client: string;
    isFI: boolean;
    person_type: PERSON_TYPES_TYPE;
    entityType: ENTITY_TYPES_TYPE;

    // from organisation
    org_type_id: number;
    date_of_incorporation: string;
    country_of_incorporation: string;

    // generated fields
    dda_number: string;
    fi: FinancialInstitution;
    kycProfile: KycProfile;
    id_type: IdType;
    // hasPendingWFs: number;
    rdcs: RDC[];
    wires_as_beneficiary: WireTransferForm[];
    wires_as_ultimate_beneficiary: WireTransferForm[];
    wires_as_originator: WireTransferForm[];
    account: Account;

    // backend generated fields
    isEditable: boolean;

    // frontend generated fields
    full_name: string;
    numberOfRecords: number;
}

export class PersonWithAddressLines extends Person {
    address_line_1: string;
    address_line_2: string;
    address_line_3: string;
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ApiService} from '@app/core-module/services/api.service';
import {IdTypesState} from '@app/core-module/states/id-types.state';
import {IdType} from '@shared/models/id-type';
import {Person} from '@shared/models/person';
import {environment} from '@env/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {Profile} from '@shared/models/profile';
import {AlertsService} from '@app/core-module/services/alerts.service';
import moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {OrgType} from '@shared/models/org-type';
import {OrgTypesState} from '@app/core-module/states/org-types.state';
import {ENTITY_TYPES, PERSON_TYPES, PERSON_TYPES_TYPE} from '@shared/utils/statuses.utils';
import {FinancialInstitution} from '@shared/models/financial-institution';
import {CurrentBankState} from '@app/core-module/states/current.bank.state';

@Component({
  selector: 'shared-add-person-modal',
  templateUrl: './add-person-modal.component.html',
  styleUrls: ['./add-person-modal.component.scss']
})

export class AddPersonModalComponent implements OnInit {

  @Input()
  initialDataPerson: Person;

  @Input()
  profile: Profile;

  @Input()
  testInput: number;

  @Input()
  rdcToDisplayImage;

  @Input()
  disabled = false;

  @Input()
  tooltip = 'Edit';

  @Input()
  allowedPersonTypes: PERSON_TYPES_TYPE[];

  @Output()
  public addPerson = new EventEmitter<Person>();

  person_type: string[];
  types: Observable<IdType[]>;
  orgTypes: Observable<OrgType[]>;
  entityType = ENTITY_TYPES;

  formGroup: FormGroup;

  imagesDomain: string;
  createSegregateAccountToggle = true;
  fi: Observable<FinancialInstitution>;

  constructor(private modalService: NgbModal,
              private idTypesState: IdTypesState,
              private orgTypesState: OrgTypesState,
              private alertsService: AlertsService,
              private apiService: ApiService,
              private translateService: TranslateService,
              private currentBankState: CurrentBankState, ) {
    this.orgTypes = orgTypesState.getOrgTypes();
    this.types = idTypesState.getIdTypes();

    this.imagesDomain = environment.domain;
    this.fi = this.currentBankState.getCurrentBank();
  }

  ngOnInit() {
    this.person_type = (this.allowedPersonTypes as string[]) || PERSON_TYPES;
    this.formGroup = new FormGroup({
      firstName: new FormControl(this.initialDataPerson ? this.initialDataPerson.first_name : ''),
      lastName: new FormControl(this.initialDataPerson && this.initialDataPerson.last_name ? this.initialDataPerson.last_name :
        (this.initialDataPerson && this.initialDataPerson.name ? this.initialDataPerson.name : '')),
      email: new FormControl({
        value: this.initialDataPerson ? this.initialDataPerson.email : '',
        disabled: this.profile != null
      }, this.customEmailValidator),
      idType: new FormControl(this.initialDataPerson ? this.initialDataPerson.id_type_id : null, [
        Validators.required,
      ]),
      idNumber: new FormControl(this.initialDataPerson ? this.initialDataPerson.id_number : '', [
        Validators.required,
      ]),
      address: new FormControl(this.initialDataPerson ? this.initialDataPerson.address : '', [
        Validators.required,
      ]),
      city: new FormControl(this.initialDataPerson ? this.initialDataPerson.city : '', [
        Validators.required,
      ]),
      zip: new FormControl(this.initialDataPerson ? this.initialDataPerson.zip : '', [
        Validators.required,
      ]),
      country: new FormControl(this.initialDataPerson ? this.initialDataPerson.country : null),
      state: new FormControl(this.initialDataPerson ? this.initialDataPerson.state : ''),
      dateOfBirth: new FormControl(this.initialDataPerson && this.initialDataPerson.date_of_birth ?
        moment(this.initialDataPerson.date_of_birth, 'YYYY-MM-DD').toDate() : null),
      countryOfBirth: new FormControl(this.initialDataPerson ? this.initialDataPerson.country_of_birth : ''),
      residency: new FormControl(this.initialDataPerson ? this.initialDataPerson.residency : ''),
      citizenship: new FormControl(this.initialDataPerson ? this.initialDataPerson.citizenship : ''),
      personType: new FormControl(this.initialDataPerson ? this.initialDataPerson.person_type : '', Validators.required),
      entityType: new FormControl({
        value: this.initialDataPerson ? this.initialDataPerson.entityType : '',
        disabled: this.initialDataPerson && this.initialDataPerson.entityType != null
      }, Validators.required),
      orgType: new FormControl(this.initialDataPerson ? this.initialDataPerson.org_type_id : null),
      dateOfIncorporation: new FormControl(this.initialDataPerson && this.initialDataPerson.date_of_incorporation ?
        moment(this.initialDataPerson.date_of_incorporation, 'YYYY-MM-DD').toDate() : null),
      countryOfIncorporation: new FormControl(this.initialDataPerson ? this.initialDataPerson.country_of_incorporation : null),
      phoneNumber: new FormControl(this.initialDataPerson ? this.initialDataPerson.phone_number : null),
      accountNumberAClient: new FormControl(this.initialDataPerson ? this.initialDataPerson.account_number_a_client : null),
      name: new FormControl(this.initialDataPerson ? this.initialDataPerson.name : ''),
    });
    if (this.initialDataPerson) {
      if (this.initialDataPerson.entityType === 'LEGAL_ENTITY') {
        this.formGroup.controls['name'].setValidators(Validators.required);
        if (this.initialDataPerson.person_type !== 'NON_CUSTOMER') {
          this.formGroup.controls['orgType'].setValidators(Validators.required);
          this.formGroup.controls['dateOfIncorporation'].setValidators(Validators.required);
          this.formGroup.controls['countryOfIncorporation'].setValidators(Validators.required);
        }
      }
      if (this.initialDataPerson.entityType === 'INDIVIDUAL') {
        this.formGroup.controls['firstName'].setValidators(Validators.required);
        this.formGroup.controls['lastName'].setValidators(Validators.required);
        if (this.initialDataPerson.person_type !== 'NON_CUSTOMER') {
          this.formGroup.controls['dateOfBirth'].setValidators(Validators.required);
          this.formGroup.controls['residency'].setValidators(Validators.required);
          this.formGroup.controls['citizenship'].setValidators(Validators.required);
        }
      }
      this.createSegregateAccountToggle = this.initialDataPerson.segregate_account;
    }

    this.formGroup.get('entityType').valueChanges
      .subscribe(value => {
        if (value === 'LEGAL_ENTITY') {
          this.formGroup.get('name').setValidators(Validators.required);
          if (this.formGroup.get('personType').value === 'NON_CUSTOMER') {
            this.formGroup.get('orgType').clearValidators();
            this.formGroup.get('dateOfIncorporation').clearValidators();
            this.formGroup.get('countryOfIncorporation').clearValidators();
          } else {
            this.formGroup.get('orgType').setValidators(Validators.required);
            this.formGroup.get('dateOfIncorporation').setValidators(Validators.required);
            this.formGroup.get('countryOfIncorporation').setValidators(Validators.required);
          }
          this.formGroup.get('firstName').clearValidators();
          this.formGroup.get('lastName').clearValidators();
          this.formGroup.get('residency').clearValidators();
          this.formGroup.get('citizenship').clearValidators();
          this.formGroup.get('dateOfBirth').clearValidators();
        }
        if (value === 'INDIVIDUAL') {
          this.formGroup.get('firstName').setValidators(Validators.required);
          this.formGroup.get('lastName').setValidators(Validators.required);
          if (this.formGroup.get('personType').value === 'NON_CUSTOMER') {
            this.formGroup.get('residency').clearValidators();
            this.formGroup.get('citizenship').clearValidators();
            this.formGroup.get('dateOfBirth').clearValidators();
          } else {
            this.formGroup.get('residency').setValidators(Validators.required);
            this.formGroup.get('citizenship').setValidators(Validators.required);
            this.formGroup.get('dateOfBirth').setValidators(Validators.required);
          }
          this.formGroup.get('orgType').clearValidators();
          this.formGroup.get('dateOfIncorporation').clearValidators();
          this.formGroup.get('countryOfIncorporation').clearValidators();
          this.formGroup.get('name').clearValidators();
        }
      });

    this.formGroup.get('personType').valueChanges
      .subscribe(value => {
        if (value === 'NON_CUSTOMER') {
          if (this.formGroup.get('entityType').value === 'LEGAL_ENTITY') {
            this.formGroup.get('orgType').clearValidators();
            this.formGroup.get('dateOfIncorporation').clearValidators();
            this.formGroup.get('countryOfIncorporation').clearValidators();
          }
          if (this.formGroup.get('entityType').value === 'INDIVIDUAL') {
            this.formGroup.get('residency').clearValidators();
            this.formGroup.get('citizenship').clearValidators();
            this.formGroup.get('dateOfBirth').clearValidators();
          }
        } else {
          if (this.formGroup.get('entityType').value === 'LEGAL_ENTITY') {
            this.formGroup.get('orgType').setValidators(Validators.required);
            this.formGroup.get('dateOfIncorporation').setValidators(Validators.required);
            this.formGroup.get('countryOfIncorporation').setValidators(Validators.required);
          }
          if (this.formGroup.get('entityType').value === 'INDIVIDUAL') {
            this.formGroup.get('residency').setValidators(Validators.required);
            this.formGroup.get('citizenship').setValidators(Validators.required);
            this.formGroup.get('dateOfBirth').setValidators(Validators.required);
          }
        }
      });
  }

  async open(content) {
    try {
      await this.modalService.open(content).result;
      this.types.pipe(
        first()
      ).subscribe(types => {
        this.addPerson.emit(<Person>{
          id: this.initialDataPerson ? this.initialDataPerson.id : null,
          first_name: this.formGroup.value['firstName'],
          last_name: this.formGroup.value['lastName'],
          email: this.formGroup.controls['email'].value, // use controls['email'].value instead value['email'] in case the control is disabled
          id_number: this.formGroup.value['idNumber'].id_number ?
            this.formGroup.value['idNumber'].id_number : this.formGroup.value['idNumber'], // in the first case it is selected from autocomplete, in the second it is typed
          id_type_id: this.formGroup.value['idType'],
          id_type: types.find(it => it.id === this.formGroup.value['idType']),
          address: this.formGroup.value['address'],
          city: this.formGroup.value['city'],
          zip: this.formGroup.value['zip'],
          country: this.formGroup.value['country'],
          state: this.formGroup.value['state'],
          date_of_birth: this.formGroup.value['dateOfBirth'] != null ? moment(this.formGroup.value['dateOfBirth']).format('YYYY-MM-DD') : null,
          country_of_birth: this.formGroup.value['countryOfBirth'],
          residency: this.formGroup.value['residency'],
          citizenship: this.formGroup.value['citizenship'],
          person_type: this.formGroup.value['personType'],
          entityType: this.formGroup.controls['entityType'].value, // use controls['entityType'].value instead value['entityType'] in case the control is disabled
          org_type_id: this.formGroup.value['orgType'],
          date_of_incorporation: this.formGroup.value['dateOfIncorporation'] != null ? moment(this.formGroup.value['dateOfIncorporation']).format('YYYY-MM-DD') : null,
          country_of_incorporation: this.formGroup.value['countryOfIncorporation'],
          phone_number: this.formGroup.value['phoneNumber'],
          account_number_a_client: this.formGroup.value['accountNumberAClient'],
          name: this.formGroup.value['name'],
          segregate_account: this.createSegregateAccountToggle
        });
        if (!this.initialDataPerson) {
          this.formGroup.reset();
        }
      });
    } catch (e) {
      // do nothing, the modal is just closed
    }
  }

  customEmailValidator(control: AbstractControl): ValidationErrors {
    return !control.value ? null : Validators.email(control);
  }

  toggleSegregateAccount() {
    this.createSegregateAccountToggle = !this.createSegregateAccountToggle;
  }
}

<div *ngIf="transaction.originatorAccount">
  {{'shared.transactions-account-details-description: Account' | translate}}:
  <span *ngIf="currentFI | async as fi">
    <a class="text-14" *ngIf="canSeeAccountLink(transaction.originatorAccount, fi)"
      [routerLink]="getAccountLink(transaction)"
      (click)="emitCloseModal()">
      {{ transaction.originatorAccount.acc_number }}
    </a>
    <span class="text-14" *ngIf="!canSeeAccountLink(transaction.originatorAccount, fi)">
      {{ transaction.originatorAccount.acc_number }}
    </span>
  </span>
  <span>({{transaction.originatorAccount.acc_name}})</span>
</div>
<div *ngIf="transaction.rdc">
  {{'shared.transactions-account-details-description: Check#' | translate}}:
  <a class="text-14" [routerLink]="isAdmin ? '/admin/rdc/rdc-activity/' + transaction.rdc.id : '/fi/rdc-dashboard/rdc-activity/' + transaction.rdc.id"
     (click)="emitCloseModal()">
    {{ transaction.rdc.check_number }}
  </a>
</div>
<div *ngIf="transaction.wireTransferForm">
  {{'shared.transactions-account-details-description: Wire Transfer Form' | translate}}:
  <a class="text-14" *ngIf="enableDescriptionWireFormLink"
     [routerLink]="getrouterLinkForWireTransferForm(transaction.wireTransferForm)"
     (click)="emitCloseModal()">
    {{ transaction.wireTransferForm.domestic_amount | currency }}
  </a>
  <span *ngIf="!enableDescriptionWireFormLink">{{ transaction.wireTransferForm.domestic_amount | currency }}</span>
</div>
<div [innerHTML]="transaction.description"></div>

export interface URLFragment {
  name: string;
  value: string;
}

export class UrlFragmentsParser {
  public urlFragments: URLFragment[] = [];


  constructor(fragment: string) {
    if (fragment && fragment.length > 0) {
      this.urlFragments = this.extractsFragmentsFromString(fragment);
    } else {
      this.clearAllURLFragments();
    }
  }

  private getIdsOfExistingFragments(fragment: URLFragment) {
    const ids: number[] = [];
    for (let urlFrgCounter = 0; urlFrgCounter < this.urlFragments.length; urlFrgCounter++) {
      const urlFrg = this.urlFragments[urlFrgCounter];
      if (urlFrg.name === fragment.name) {
        ids.push(urlFrgCounter);
      }
    }
    return ids;
  }

  private clearURLFragment(fragment: URLFragment) {
    let ids = this.getIdsOfExistingFragments(fragment);
    while (ids.length > 0) {
      this.urlFragments.splice(ids[0], 1);
      ids = this.getIdsOfExistingFragments(fragment);
    }
  }

  private clearAllURLFragments() {
    this.urlFragments.splice(0, this.urlFragments.length);
  }

  private extractsFragmentsFromString(fragment: string, separator: string = '&'): URLFragment[] {
    if (this.isValidFragmentString(fragment)) {
      const urlFragment = this.getNameAndValueFromFragment(fragment);
      return [urlFragment];
    }
    const fragmentsSplitted = fragment.split('&');
    if (fragmentsSplitted.length <= 0) {
      return null;
    }
    const urlFragments: URLFragment[] = [];
    for (const frg of fragmentsSplitted) {
      const urlFragment = this.getNameAndValueFromFragment(frg);
      if (urlFragment) {
        this.clearURLFragment(urlFragment);
        urlFragments.push(urlFragment);
      }
    }
    return urlFragments;
  }

  private isValidFragmentString(fragment: string) {
    if (!fragment || fragment && fragment.length <= 0) {
      return false;
    }
    const fragmentSplitted = fragment.split('=');
    if (fragmentSplitted.length !== 2) {
      return false;
    }
    if (!fragmentSplitted[0].length || !fragmentSplitted[1].length) {
      return false;
    }
    return true;
  }

  private getNameAndValueFromFragment(fragment: string): URLFragment {
    if (!this.isValidFragmentString(fragment)) {
      return null;
    }
    const fragmentSplitted = fragment.split('=');
    return {
      name: fragmentSplitted[0],
      value: fragmentSplitted[1],
    };
  }

  public getValidFragment() {
    const fragmentsFull: string[] = [];
    for (const fragment of this.urlFragments) {
      if (fragment) {
        fragmentsFull.push(`${fragment.name}=${fragment.value}`);
      }
    }
    return fragmentsFull.filter(it => it).join('&');
  }
}

<div class="card-body container-fluid">
  <form [formGroup]="formGroup" [ngModel]="onModelChanged()" ngDefaultControl [ngModelOptions]="{standalone: true}">
    <div class="row form-group checkbox-filter form-group-date d-flex flex-column">
      <div>
        <div class="form-group form-group-date d-flex flex-column">
          <h6
            class="weight-500 text-dark-9-opacity">{{ 'shared.wire-transfer-forms-filter: Date Range'| translate }}</h6>
          <label for="createdFrom"
                 class="mb-2 text-dark-7-opacity font-weight-normal">{{ 'shared.wire-transfer-forms-filter: From'| translate }}</label>
          <mat-form-field floatLabel="never" class="text-14">
            <input id="createdFrom" formControlName="createdFrom" matInput [matDatepicker]="createdFrom"
                   placeholder="{{'shared.wire-transfer-forms-filter: Choose a date'| translate}}" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="createdFrom"></mat-datepicker-toggle>
            <mat-datepicker #createdFrom disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-group form-group-date d-flex flex-column">
          <label for="createdTo"
                 class="mb-2 text-dark-7-opacity font-weight-normal">{{ 'shared.wire-transfer-forms-filter: To'| translate }}</label>
          <mat-form-field floatLabel="never" class="text-14">
            <input id="createdTo" formControlName="createdTo" matInput [matDatepicker]="createdTo"
                   placeholder="{{'shared.wire-transfer-forms-filter: Choose a date'| translate}}" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="createdTo"></mat-datepicker-toggle>
            <mat-datepicker #createdTo disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="isIonfi">
        <div class="form-group">
          <label for="fiName" class="weight-500 text-dark-9-opacity text-16"
                 *ngIf="!isIncoming">{{ 'shared.wire-transfer-forms-filter: Sender'| translate }}</label>
          <label for="fiName" class="weight-500 text-dark-9-opacity text-16"
                 *ngIf="isIncoming">{{ 'shared.wire-transfer-forms-filter: Financial Institution'| translate }}</label>
          <input id="fiName" class="form-control" formControlName="fiName"/>
        </div>
        <div class="form-group">
          <label for="fiAcct" class="weight-500 text-dark-9-opacity text-16"
                 *ngIf="!isIncoming">{{ 'shared.wire-transfer-forms-filter: Sender Acct'| translate }}</label>
          <label for="fiAcct" class="weight-500 text-dark-9-opacity text-16"
                 *ngIf="isIncoming">{{ 'shared.wire-transfer-forms-filter: FI Acct'| translate }}</label>
          <input class="form-control" id="fiAcct" formControlName="fiAcct"/>
        </div>
      </div>
      <div class="mb-3">
        <div class="form-group">
          <h6 class="weight-500 text-dark-9-opacity">{{ 'shared.wire-transfer-forms-filter: Amount'| translate }}</h6>
          <label for="amountFrom"
                 class="mb-2 text-dark-7-opacity font-weight-normal">{{ 'shared.wire-transfer-forms-filter: From'| translate }}</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input id="amountFrom" class="form-control" formControlName="amountFrom" type="number"/>
          </div>
        </div>
        <div class="form-group">
          <label for="amountTo"
                 class="mb-2 text-dark-7-opacity font-weight-normal">{{ 'shared.wire-transfer-forms-filter: To'| translate }}</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input id="amountTo" class="form-control" formControlName="amountTo" type="number"/>
          </div>
        </div>
      </div>

      <div *ngIf="wfStatuses | async as wireStatuses" formArrayName="wireStatus" class="form-group wire-statuses">
        <h6 class="weight-500 text-dark-9-opacity">{{ 'admin.customers-wire-transfer-entity: Status' | translate }}</h6>
        <div *ngFor="let checkedStatus of wireStatusFormArray.controls; let i = index">
          <label class="custom-checkbox">
            <input type="checkbox" formControlName="{{i}}"/>
            <span class="checkmark"></span>
            <label [ngStyle]="{'background-color': wireStatuses[i].background_color, 'color': wireStatuses[i].text_color}"
                   class="pl-3 type-design-circle">{{ translateService.currentLang === 'en' ? wireStatuses[i].status : wireStatuses[i].status_es }}</label>
          </label>
        </div>
      </div>
    </div>
  </form>
</div>

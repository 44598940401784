<ng-container *ngIf="transferMessageEntity else notTransferMessageEntity">
  <div [ngClass]="showCard ? 'card mt-3 h-100 border-10' : ''">
    <div [ngClass]="showCard ? 'card-header border-bottom-0' : ''">
      <ng-container *ngIf="wireTransferEntity">
        <span [ngClass]="showConfirmed ? wireTransferEntity.kycProfile.on_boarded_by_id ? 'text-success' : 'text-danger' : ''">
          {{ headerTitle }}</span>
      </ng-container>
      <ng-container *ngIf="!wireTransferEntity">{{ headerTitle }}</ng-container>
    </div>
    <div [ngClass]="showCard ? 'card-body' : ''">
      <div *ngIf="!wireTransferEntity">
        <fa-icon *ngIf="entityType === 'person'" [classes]="['fas-fa']" icon="user-tag"></fa-icon>
        <fa-icon *ngIf="entityType === 'bank'" [classes]="['fa-fw']" icon="building"></fa-icon>
        <b>{{ entityType === 'person' ? (transferMessageEntity | personName | titlecase) : (transferMessageEntity['bank_name'] | titlecase) }}</b>
      </div>
      <div *ngIf="wireTransferEntity">
        <a [routerLink]="[getWFEntityUrl(), entityType === 'person' ? wireTransferEntity.kyc_profile_id : wireTransferEntity.id]">
          <fa-icon *ngIf="entityType === 'person'" [classes]="['fas-fa']" icon="user-tag"></fa-icon>
          <fa-icon *ngIf="entityType === 'bank'" [classes]="['fa-fw']" icon="building"></fa-icon>
          {{ entityType === 'person' ? (wireTransferEntity | masterPersonName | async) : wireTransferEntity['bank_name'] }}</a>
      </div>
      <shared-wire-transfer-entity-address
        [entity]="(wireTransferEntity || transferMessageEntity)"></shared-wire-transfer-entity-address>
      <b>{{ entityType === 'person' ? (wireTransferEntity || transferMessageEntity).id_type.label : ((wireTransferEntity || transferMessageEntity).bank_number_type | entityNumberType) }}</b>:
      <span>
        {{ (wireTransferEntity || transferMessageEntity)[entityType === 'person' ? 'id_number' : 'bank_number'].replace('//FW', '') }}
      </span>
    </div>
  </div>
</ng-container>
<ng-template #notTransferMessageEntity>
  <div [ngClass]="showCard ? 'card mt-3 h-100 border-10' : ''">

    <div *ngIf="isSegregateAccountEntity else notSegregateAccountEntity">
      <div [ngClass]="{'card-header border-bottom-0': showCard}">
        <div>{{ headerTitle }}</div>
      </div>
      <div [ngClass]="{'card-body' : showCard}">
        <ng-container *ngIf="wireTransferEntity">
          <div *ngIf="!isWireTransferFormDetails">
            <hr *ngIf="showCard">
            <div class="pb-2 pt-2">
              <fa-icon [classes]="['fa-fw']" class='icon-space margin-auto' icon="user"></fa-icon>
              <a [routerLink]="[getWFEntityUrl(), wireTransferEntity.kyc_profile_id]">
                {{ wireTransferEntity | personName }}
              </a>
            </div>
            <shared-wire-transfer-entity-address [entity]="wireTransferEntity"></shared-wire-transfer-entity-address>
            <b *ngIf="wireTransferEntity">{{ wireTransferEntity.id_type.label }}</b>
            : <span>{{ wireTransferEntity.id_number }}</span>
          </div>
          <hr>
          <div *ngIf="wireTransferEntity.account" class="mt-2">
            <b>{{'shared.wire-transfer-entity-details: Segregated Account' | translate}}</b>
            <br>
            <a [routerLink]="getWFAccountLink(wireTransferEntity.account.id)">
              {{ wireTransferEntity.account.acc_number }} ({{ wireTransferEntity.account.acc_name }})
            </a>
          </div>
          <div *ngIf="showFundingAccountInfo && !isWireTransferFormDetails && wireTransferEntity.account" class="mt-2">
            <b>{{'shared.wire-transfer-entity-details: Funding Account' | translate}}</b>
            <br>
            <a *ngIf="wireTransferEntity.account.fundingAccount"
               [routerLink]="getWFAccountLink(wireTransferEntity.account.fundingAccount.id)">
              {{ wireTransferEntity.account.fundingAccount.acc_number }} ({{ wireTransferEntity.account.fundingAccount.acc_name }})
            </a>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-template #notSegregateAccountEntity>
      <div *ngIf="wireTransferEntity && wireTransferEntity.kycProfile" [ngClass]="showCard ? 'card-header border-bottom-0' : ''">
        <span [ngClass]="showConfirmed && wireTransferEntity.person_type === 'FULL_PROFILE' ? wireTransferEntity.kycProfile.on_boarded_by_id ? 'text-success' : 'text-danger' : ''">
          {{ headerTitle }}
        </span>
      </div>
      <div *ngIf="!(wireTransferEntity && wireTransferEntity.kycProfile)" [ngClass]="showCard ? 'card-header border-bottom-0' : ''">
        {{ headerTitle }}
      </div>

      <div [ngClass]="showCard ? 'card-body' : ''">
        <ng-container *ngIf="wireTransferEntity">
          <div>
            <a [routerLink]="[getWFEntityUrl(), entityType === 'person' ? wireTransferEntity.kyc_profile_id : wireTransferEntity.id]">
              <fa-icon *ngIf="entityType === 'person'" [classes]="['fas-fa']" icon="user-tag"></fa-icon>
              <fa-icon *ngIf="entityType === 'bank'" [classes]="['fa-fw']" icon="building"></fa-icon>
              {{ entityType === 'person' ? (wireTransferEntity | personName) : wireTransferEntity.bank_name }}
            </a>
          </div>
          <shared-wire-transfer-entity-address [entity]="wireTransferEntity"></shared-wire-transfer-entity-address>
          <b *ngIf="entityType === 'person'">{{ wireTransferEntity.id_type.label }}</b>
          <b *ngIf="entityType === 'bank'">{{ wireTransferEntity.bank_number_type | entityNumberType }}</b>
          : <span>{{ entityType === 'person' ? wireTransferEntity.id_number : wireTransferEntity.bank_number }}</span>
          <div *ngIf="wireTransferEntity.dda_number">
            <b>{{ 'shared.wire-transfer-entity-details: DDA Number' | translate }}: </b><span>{{ wireTransferEntity.dda_number }}</span>
          </div>

<!--          <div class="mt-5">-->
<!--            <div *ngFor="let document of documents">-->
<!--              <a href="javascript:void(0)" (click)="download(wireTransferEntity.kyc_profile_id, document)">-->
<!--                <fa-icon [classes]="['fa-fw']" icon="download"></fa-icon>-->
<!--              </a>-->
<!--              <span class="text-muted font-italic">{{document.original_filename}}</span>-->
<!--            </div>-->
<!--          </div>-->
          <div *ngIf="showFundingAccountInfo && !isWireTransferFormDetails && wireTransferEntity.account && wireTransferEntity.account.fundingAccount" class="mt-2">
            <b>{{'shared.wire-transfer-entity-details: Funding Account' | translate}}</b>
            <br>
            <a [routerLink]="getWFAccountLink(wireTransferEntity.account.fundingAccount.id)">
              {{ wireTransferEntity.account.fundingAccount.acc_number }} ({{ wireTransferEntity.account.fundingAccount.acc_name }})
            </a>
          </div>
          <div *ngIf="showSegregatedAccountInfo && wireTransferEntity.segregate_account && wireTransferEntity.account" class="mt-2">
            <b>{{'shared.wire-transfer-entity-details: Segregated Account' | translate}}</b>
            <br>
            <a [routerLink]="getWFAccountLink(wireTransferEntity.account.id)">
              {{ wireTransferEntity.account.acc_number }} ({{ wireTransferEntity.account.acc_name }})
            </a>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
</ng-template>

<button class="btn btn-sm edit-icon" (click)="open(content)" *ngIf="!disabled">
  <span [class]="'ico icon-trashcan ' + class" ngbTooltip="{{ 'shared.confirm-delete: Delete' | translate }}"></span>
</button>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'shared.confirm-delete: Delete Confirmation' | translate }}
    </h4>
  </div>
  <div class="modal-body fit-content">
    {{ 'shared.confirm-delete: Are you sure that you want to delete?' | translate }}
  </div>
  <div class="modal-footer">
    <button class="btn cancel-button" (click)="d()">
      {{ 'shared.confirm-delete: Cancel' | translate }}
    </button>
    <button class="btn purple-button" (click)="c()">{{ 'shared.confirm-delete: Delete' | translate }}</button>
  </div>
</ng-template>

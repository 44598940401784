import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { tablesLimit } from "@app/shared-module/utils/utils";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "shared-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"]
})
export class TableComponent implements OnInit, AfterViewInit {

  @Input()
  title: string;

  @Input()
  isFiltered: boolean;

  @Input()
  limitMessageType: "SHOWING_LATEST_N" | "SHOWING_FIRST_N";

  @Input()
  numElements: number;

  @Input()
  showFilter: boolean;

  @Input() filterForm: FormGroup;

  @Input()
  hideHeader = false;

  @Output()
  onResetFilter: EventEmitter<void> = new EventEmitter();

  filterClosed = true;

  numElementsLimit;

  @Input()
  headerTopContentLeft: boolean = false;

  constructor() {
    this.numElementsLimit = tablesLimit;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  }

  ngOnInit() {
  }

  resetFilter() {
    this.setFilterClosed(true);
    this.onResetFilter.next();
    this.filterForm.reset();
  }

  toggleFilter() {
    this.setFilterClosed(!this.filterClosed);
  }

  private setFilterClosed(value: boolean) {
    this.filterClosed = value;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    })
  }
}


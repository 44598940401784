import {Balance} from '@shared/models/balance';
import * as _ from 'lodash';
import {CurrencyPipe} from "@angular/common";
import {Angular5Csv} from "angular5-csv/dist/Angular5-csv";

export function groupAndSumAvailableBalance(balances: Balance[], currencyPipe: CurrencyPipe) {
  if (!balances.length) {
    return '-';
  }
  const groupedBalances = _.groupBy(balances, 'acc_currency');
  let availableBalance: any = Object.keys(groupedBalances).map(key => {
    return groupedBalances[key].reduce((a, b) => a + (b.available_balance || 0), 0);
  });
  if (availableBalance.every(value => value === 0)) {
    availableBalance = '-';
  } else {
    availableBalance = availableBalance.map((value, index) => currencyPipe.transform(value, Object.keys(groupedBalances)[index]) ).join(' | ');
  }
  return availableBalance;
}

export function groupAndSumCurrentBalance(balances: Balance[], currencyPipe: CurrencyPipe) {
  if (!balances.length) {
    return '-';
  }
  const groupedBalances = _.groupBy(balances, 'acc_currency');
  let currentBalances: any = Object.keys(groupedBalances).map(key => {
    return groupedBalances[key].reduce((a, b) => a + (b.current_balance || 0), 0) || 0;
  });
  if (currentBalances.every(value => value === 0)) {
    currentBalances = '-';
  } else {
    currentBalances = currentBalances.map((value, index) => {
      return currencyPipe.transform(value, Object.keys(groupedBalances)[index]);
    } ).join(' | ');
  }
  return currentBalances;
}

export function downloadToCsv(balances: Balance[], filename: string) {
  const columns = [];
  columns.push('Number');
  columns.push('Name');
  columns.push('Type');
  columns.push('Upstream Bank');
  columns.push('Current Balance');
  columns.push('Available Balance');
  const json: any[] = [[columns]];
  for (const balance of balances) {
    const columnsData = [];
    columnsData.push(balance.acc_number);
    columnsData.push(balance.acc_name);
    columnsData.push(balance.acc_type);
    columnsData.push(balance.upstreamBank ? balance.upstreamBank.name : '');
    columnsData.push(balance.current_balance);
    columnsData.push(balance.available_balance);
    json.push([columnsData]);
  }
  new Angular5Csv(json, filename);
}

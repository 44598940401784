<div class="login-page" [@routerTransition]>
  <div class="row justify-content-center h-100 m-0">

    <div class="col-6 p-0 align-self-center left-bg">
      <div class="col-12">
        <img src="./assets/images/stats-graphs-ion%200.svg" width="150px" class="user-avatar"/>
        <div class="mt-4 text-theme header-font">{{ 'root.forgot-password: Sign Up' | translate }}</div>
      </div>
    </div>

  <div class="col-6 p-0">

    <div class="row justify-content-end bg-theme m-0">
      <div class="col-auto p-3">
        <span class="mr-2"><img src="assets/flags/americanFlag.svg" height="14px" width="20px"/></span><a
        class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('en')">English</a>
      </div>
      <div class="col-auto p-3">
        <span class="mr-2"><img src="assets/flags/spanishFlag.svg" height="14px" width="20px"/></span><a
        class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('es')">Spanish</a>
      </div>
    </div>

    <div class="full-height bg-theme">
      <div class="col-12">
      <form role="form" [formGroup]="formGroup">
        <div class="form-content">
          <div class="form-group">
            <div class="text-left text-theme">{{ 'root.sign-up: Email' | translate }}</div>
            <input #email type="text" class="form-control rounded input-lg"
                   formControlName="email">
          </div>
          <div class="form-group">
            <div class="text-left text-theme">{{ 'root.sign-up: Password' | translate }}</div>
            <input #password type="password" class="form-control rounded input-lg"
                   formControlName="password">
          </div>
          <div class="form-group">
            <div class="text-left text-theme">{{ 'root.sign-up: Verify Password' | translate }}</div>
            <input #verify type="password" class="form-control rounded input-lg"
                   formControlName="verify">
            <div [hidden]="!formGroup.get('verify').touched || !formGroup.get('verify').hasError('MatchPassword')"
                 class="text-danger">{{ 'root.sign-up: Password Don\'t Match' | translate }}
            </div>
          </div>
          <div class="form-group">
            <div class="text-left text-theme">{{ 'root.sign-up: First Name' | translate }}</div>
            <input #first_name type="Text" class="form-control rounded input-lg"
                   formControlName="first_name">
          </div>
          <div class="form-group">
            <div class="text-left text-theme">{{ 'root.sign-up: Last Name' | translate }}</div>
            <input #last_name type="Text" class="form-control rounded input-lg"
                   formControlName="last_name">
          </div>
          <div class="form-group">
            <div class="text-left text-theme">{{ 'root.sign-up: Phone (Optional)' | translate }}</div>
            <input #phone type="Text" class="form-control rounded input-lg"
                   formControlName="phone">
          </div>
        </div>
        <a class="btn rounded-btn" [routerLink]="'/auth/login'"><fa-icon [classes]="['fa-fw']" icon="angle-left"></fa-icon>{{ 'root.apply: Login' | translate }}</a>
        <button class="btn rounded-btn" [disabled]="!formGroup.valid" (click)="signUp()">{{ 'root.sign-up: Sign Up' | translate }}</button>
      </form>
      </div>
    </div>
  </div>
</div>
</div>

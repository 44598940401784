import {Pipe, PipeTransform} from "@angular/core";
import {OrgTypesState} from "@app/core-module/states/org-types.state";
import {first, map} from "rxjs/operators";
import {Observable} from "rxjs";

@Pipe({
  name: "orgTypeName"
})
export class OrgTypeNamePipe implements PipeTransform {

  constructor(private orgTypeState: OrgTypesState) {

  }


  transform(id: number): Observable<string> {
    return this.orgTypeState.getOrgTypes().pipe(
      first(),
      map(orgTypes => orgTypes.find(it => it.id === id)),
      map(orgType => orgType ? orgType.type : '')
    )
  }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserTypesState} from '@app/core-module/states/user-types.state';
import {Profile} from '@shared/models/profile';
import {User} from '@shared/models/user';
import {UsersType} from '@shared/models/users-type';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription, Observable} from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'shared-update-user-modal',
  templateUrl: './update-user-modal.component.html',
  styleUrls: ['./update-user-modal.component.scss']
})
export class UpdateUserModalComponent implements OnInit, OnDestroy {

  @Output()
  public updateUser = new EventEmitter<User>();

  @Input()
  public user: User;

  @Input()
  public canChangeScanThroughBrowser = false;

  @Input()
  public isIonfi = false;

  @Input()
  public currentProfile: Profile;

  types: Observable<UsersType[]>;


  formGroup: FormGroup;

  subscription: Subscription;

  constructor(private modalService: NgbModal,
              private userTypesState: UserTypesState) {
  }

  initializeForm() {
    this.subscription = this.types.subscribe(types => {
      this.formGroup = new FormGroup({
        firstName: new FormControl(this.user.first_name, [
          Validators.required,
        ]),
        lastName: new FormControl(this.user.last_name, [
          Validators.required,
        ]),
        dateOfBirth: new FormControl(this.user.date_of_birth),
        officePhoneNumber: new FormControl(this.user.office_phone_number),
        cellphoneNumber: new FormControl(this.user.cellphone_number),
        positionInCompany: new FormControl(this.user.position_in_company),
        type: new FormControl({
          value: types.find(it => it.id === this.user.users_type_id),
          disabled: (this.currentProfile.user_id === this.user.id) && !this.user.isAdministrator
        }, [
          Validators.required,
        ]),
        scannerIp: new FormControl(this.user.scanner_ip),
        scannerPort: new FormControl(this.user.scanner_port),
        includeInMailingLists: new FormControl(this.user.include_in_mailing_lists),
        isPaymentSupervisor: new FormControl(this.user.is_payment_supervisor),
      });
      if (this.canChangeScanThroughBrowser) {
        this.formGroup.addControl('scanThroughBrowser', new FormControl(this.user.scan_through_browser));
      }
    });
  }

  ngOnInit() {
    this.types = this.userTypesState.getUserTypes();
    this.initializeForm();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  open(content) {
    this.modalService.open(content).result.then(() => {
      const newUser = Object.assign({}, this.user);
      newUser.first_name = this.formGroup.value['firstName'];
      newUser.last_name = this.formGroup.value['lastName'];
      newUser.users_type_id = this.formGroup.value['type'] ? this.formGroup.value['type'].id : this.user.users_type_id;
      newUser.scanner_ip = this.formGroup.value['scannerIp'];
      newUser.scanner_port = this.formGroup.value['scannerPort'];
      if (this.formGroup.get('scanThroughBrowser')) {
        newUser.scan_through_browser = this.formGroup.value['scanThroughBrowser'];
      }
      newUser.date_of_birth = this.formGroup.value['dateOfBirth'] ? moment(this.formGroup.value['dateOfBirth']).format('YYYY-MM-DD') : null;
      newUser.position_in_company = this.formGroup.value['positionInCompany'];
      newUser.office_phone_number = this.formGroup.value['officePhoneNumber'];
      newUser.cellphone_number = this.formGroup.value['cellphoneNumber'];
      newUser.include_in_mailing_lists = this.formGroup.value['includeInMailingLists'];
      newUser.is_payment_supervisor = this.formGroup.value['isPaymentSupervisor'];
      this.updateUser.emit(newUser);
    }, (reason) => {
      // do nothing
    });
  }

}




import {Pipe, PipeTransform} from '@angular/core';
import {parseFeeConfiguration} from "@shared/utils/fee.configuration.utils";

@Pipe({
  name: 'volumeFee'
})
export class VolumeFeePipe implements PipeTransform {

  transform(volumeFee: string | number): string {
    volumeFee = volumeFee + '';
    let configuration = parseFeeConfiguration(volumeFee);
    if (configuration.intervals.length < 1) return configuration.defaultFee + '%';

    let result = '\n';
    let intervals = configuration.intervals.sort(it => it.upTo);
    for (let interval of intervals) {
      result += ` - for amounts up to $${interval.upTo}: ${interval.fee + ''}%\n`
    }
    result += ` - for amounts greater than $${intervals[intervals.length - 1].upTo}: ${configuration.defaultFee + ''}%`;
    return result;
  }
}

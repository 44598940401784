import {Pipe, PipeTransform} from '@angular/core';
import {CountriesService} from '@app/core-module/services/countries.service';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {Country_ISO} from '@shared/models/country_iso';

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {

  countries: Observable<Country_ISO[]>;

  constructor(private countriesService: CountriesService) {
    this.countries = this.countriesService.getCountries();
  }


  transform(countryCode: string): Observable<string> {
    return this.countries.pipe(
      first(),
      map(countries => countries.find(it => it['Alpha-3 code'] === countryCode)),
      map(country => country ? country.Country : '')
    );
  }
}

import {Pipe, PipeTransform} from "@angular/core";
import {UserTypesState} from "@app/core-module/states/user-types.state";
import {first, map} from "rxjs/operators";
import {Observable} from "rxjs";

@Pipe({
  name: "userType"
})
export class UserTypePipe implements PipeTransform {

  constructor(private userTypesState: UserTypesState) {

  }


  transform(id: number): Observable<string> {
    return this.userTypesState.getUserTypes().pipe(
      first(),
      map(userTypes => userTypes.find(it => it.id === id)),
      map(userType => userType ? userType.type : '')
    )
  }
}

import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {AuthenticationService} from '@app/core-module/services/authentication.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {Profile} from '@shared/models/profile';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {defaultDebounceTime, getLogoPath, MatchPasswordValidator} from '@app/shared-module/utils/utils';
import {Observable} from 'rxjs';
import {debounceTime, filter, first} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '@app/core-module/services/language.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  animations: [routerTransition()]
})
export class SignUpComponent implements OnInit {

  private currentProfile: Observable<Profile>;
  private readingCurrentProfile: Observable<boolean>;
  formGroup: FormGroup;
  logoPath: string;

  constructor(private currentProfileState: CurrentProfileState,
              private router: Router,
              private authenticationService: AuthenticationService,
              private alertsService: AlertsService,
              private translateService: TranslateService,
              private languageService: LanguageService) {
    this.logoPath = getLogoPath();
    this.currentProfile = this.currentProfileState.getCurrentProfile();
    this.readingCurrentProfile = this.currentProfileState.getReadingCurrentProfile().pipe(debounceTime(defaultDebounceTime));

    this.currentProfile.pipe(
      filter(it => it !== null),
      first()
    ).subscribe(it => {
      if (it.id) {
        if (it.user != null && it.user.isAdminFI) { this.router.navigate(['/admin']); }
        else if (it.user != null) { this.router.navigate(['/fi']); }
        else { this.router.navigate(['/profile']); }
      }
    });

  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), MatchPasswordValidator]),
      verify: new FormControl('', [Validators.required, MatchPasswordValidator]),
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      phone: new FormControl('')
    });
  }

  setLanguage(lang: string) {
    this.languageService.setLanguage(lang);
  }

  signUp() {
    const formToSend = this.formGroup.value;
    this.authenticationService.signUp(formToSend).subscribe((res) => {
      this.alertsService.addAlert({
        type: 'success',
        message: this.translateService.instant('root.sign-up: Sign Up Successful')
      });
      this.router.navigate(['/auth/login']);
    }, (error) => this.alertsService.addAlert({type: 'danger', message: error.error.message}));
  }

}

<app-body-design>
  <div body [ngClass]="{'full-height-body-rdc' : !secondForm && !thirdForm && !fourthForm, 'full-height-body': secondForm || thirdForm || fourthForm}" class="full-height-body-rdc body-relative">
    <ng-content select="[stepper]"></ng-content>


    <div [ngClass]="{'height-unset' : thirdForm || fourthForm, 'display-none' : !batchComplete, 'height-media' : !fullWidth, 'height-media-100' : fullWidth}" class="scan-component-design align-items-center" *ngIf="readingScannerInfo">
      <div [ngClass]="{'w-100' : scanned || scanning}">
        <ng-content select="[svgImage]"></ng-content>
      <ng-content select="[button]"></ng-content>
          <ng-content select="[scanning]">
          </ng-content>
      </div>
    </div>

    <ng-content select="[navigationButton]"></ng-content>
  </div>
</app-body-design>

import {DbModel} from "./db.model";
import {SelfRegisteredUser} from "./self-registered-user";
import {User} from "./user";

export class Profile extends DbModel {
    email: string;
    password: string;
    user_id: number;
    self_registered_user_id: number;
    active: boolean;
    activation_code: string;
    tfa_enabled: boolean;
    tfa_secret: string;
    tfa_temp_secret: string;

    //generated
    user: User;
    selfRegisteredUser: SelfRegisteredUser;
}

<div class="row mx-1 d-flex align-items-center">
  <div class="col-auto text-purple-ionfi">
    <button (click)="downloadTemplate()" class="btn btn-sm bg-dark-04 text-dark-9-opacity text-14">
      <fa-icon [classes]="['fa-fw']" icon="download" class="mr-1"></fa-icon>{{'shared.input-file: Download Template' | translate}}
    </button>
  </div>
  <div class="col-auto">
    <input #inputFile
           hidden=true
           type="file"
           id="inputFile"
           [accept]="mimeType"
           [multiple]="false"
           (change)="fileChange($event)">
    <button class="btn btn-sm bg-purple-04 text-purple-ionfi text-14 text-white ld-ext-right"
            [ngClass]="{'running': working}"
            [disabled]="working"
            (click)="inputFile.click()">
      <span class="ico icon-xls-file"></span>
      <div *ngIf="working" class="ld ld-ring ld-spin"></div>
      {{buttonText}}</button>
  </div>
</div>

import {Injectable} from '@angular/core';
import {ApiService} from '@app/core-module/services/api.service';
import {RxUtilsService} from '@app/core-module/services/rx-utils.service';
import {ScannerInfo} from '@shared/models/scanner.info';
import {from, Observable} from 'rxjs';
import {LocalScanningCheckService} from '../services/local-scanning-check.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {combineLatest, distinctUntilChanged, filter} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';


@Injectable()
export class ScannerInfoState {

  private read$ = new BehaviorSubject<boolean>(true);
  private scannerInfo: Observable<ScannerInfo>;
  private reading: Observable<boolean>;

  constructor(private currentProfileState: CurrentProfileState,
              private rxUtilsService: RxUtilsService,
              private localScanningCheckService: LocalScanningCheckService,
              private apiService: ApiService) {

    const initiator = this.currentProfileState.getCurrentProfile().pipe(
      filter(it => it != null),
      combineLatest(this.read$, (profile, read) => profile)
    );

    this.scannerInfo = this.rxUtilsService.createGetValue(initiator,
      profile => {
        if (profile.user.scan_through_browser) {
          return from(this.localScanningCheckService.getScannerInfo(profile.user.scanner_ip, profile.user.scanner_port));
        } else {
          return this.apiService.getScannerInfo(profile.user);
        }
      }, null, new ScannerInfo());

    this.reading = this.rxUtilsService.createReadingInfo(initiator, this.scannerInfo);
  }

  getReading(): Observable<boolean> {
    return this.reading.pipe(distinctUntilChanged());
  }

  reload() {
    this.read$.next(true);
  }

  getScannerInfo(): Observable<ScannerInfo> {
    return this.scannerInfo;
  }
}

<button class="btn btn-sm icon-table-actions p-1" (click)="open(content)"><span ngbTooltip="{{ 'shared.confirm-delete: Edit' | translate }}" class="ico icon-pen"></span></button>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{'shared.update-bank-modal: Update Financial Institution' | translate}}</h4>
  </div>
  <form [formGroup]="formGroup" (validSubmit)="c()" role="form">
    <div class="modal-body">
      <div class="form-group">
        <label>{{'shared.update-bank-modal: Name' | translate}}</label><span class="text-purple-ionfi">*</span>
        <input class="form-control" formControlName="name">
      </div>
      <div class="form-group">
        <label>{{'shared.update-bank-modal: Address' | translate}}</label>
        <input class="form-control" formControlName="address">
      </div>
      <div class="form-group">
        <label>{{'shared.update-bank-modal: City' | translate}}</label>
        <input class="form-control" formControlName="city">
      </div>
      <div class="form-group">
        <label>{{'shared.update-bank-modal: ZIP' | translate}}</label>
        <input class="form-control" formControlName="zip">
      </div>
      <shared-inputs-country [countryControlName]="'country'" [stateControlName]="'state'"
                             [countryLabel]="'Country'" [stateLabel]="'State'" [form]="formGroup"></shared-inputs-country>
      <div class="form-group">
        <label>{{'shared.update-bank-modal: Email' | translate}}</label>
        <input class="form-control" formControlName="email">
      </div>
      <div class="form-group">
        <label>{{'shared.update-bank-modal: Website' | translate}}</label>
        <input class="form-control" formControlName="website">
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="close" aria-label="Close" (click)="d()" class="btn cancel-button">{{'shared.update-bank-modal: Cancel' | translate}}</button>
      <button type="button" type="submit" class="btn purple-button">{{'shared.update-bank-modal: Update' | translate}}</button>
    </div>
  </form>
</ng-template>

<div [@routerTransition]>
  <div *ngIf="filteredBalances as b">
    <div class="d-flex mb-3">
      <div class="current-balance">
        <div class="card d-flex justify-content-between">
          <p class="balance-text-display text-12 text-dark-0-42-opacity">{{'admin.accounts-activity-table: Current Balance' | translate}}</p>
          <p class="currency-design-display">{{ sumCurrentBalance(b) }}</p>
        </div>
      </div>
      <div class="total-balance">
        <div class="card d-flex justify-content-between">
          <p class="balance-text-display text-12 text-dark-0-42-opacity">{{'admin.accounts-activity-table: Available Balance' | translate}}</p>
          <p class="currency-design-display">{{ sumAvailableBalance(b) }}</p>
        </div>
      </div>
    </div>
    <app-body-design>
      <div body class="full-height-body-cards">
        <shared-loading-bar *ngIf="readingBalances | async"></shared-loading-bar>
        <shared-table [title]="" [numElements]="b.length" limitMessageType="SHOWING_LATEST_N"
                      [showFilter]="showFilter"
                      [filterForm]="filterFormGroup"
                      [isFiltered]="isFiltered"
                      (onResetFilter)="resetForm()">
          <div buttons>
            <button class="btn bg-purple-ionfi btn-sm mx-1 px-1" (click)="downloadToCsv(b)">
              <fa-icon [classes]="['fa-fw']" icon="table"></fa-icon>
              {{'fi.accounts-activity: Download as CSV' | translate}}
            </button>
            <button [ngClass]="{'running': downloadingPdf}" class="btn bg-purple-ionfi btn-sm ld-ext-right mx-1 px-1"
                    (click)="downloadToPdf(b)"
                    [disabled]="downloadingPdf">
              <fa-icon [classes]="['fa-fw']" icon="file-pdf"></fa-icon>
              {{'fi.accounts-activity: Download as PDF' | translate}}
              <div class="ld ld-ring ld-spin"></div>
            </button>
          </div>
          <ngx-datatable ngx-table class="bootstrap table-calc-vh-fi-accActivity"
                         [reorderable]="false"
                         [columnMode]="'force'"
                         [sorts]="[{prop: 'fi_name',
                                      dir: 'asc'
                                  }]"
                         [scrollbarH]="true"
                         #table
                         [rows]="b"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="PAGE_SIZE"
                         [messages]="b | tableMessages">

            <ngx-datatable-column name="{{'admin.accounts-activity-table: FI' | translate}}"
                                  prop="fi_name"
                                  headerClass="pl-22"
                                  cellClass="pl-22">
              <ng-template let-balance="row" ngx-datatable-cell-template>
                <a class="text-purple-ionfi text-14"
                   [routerLink]="'/admin/fi/' + balance.fi_id">{{ balance.fi_name }}</a>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{'admin.accounts-activity-table: Number' | translate}}"
                                  prop="acc_number"
                                  headerClass="pl-22"
                                  cellClass="pl-22">
              <ng-template let-balance="row" ngx-datatable-cell-template>
                <a class="text-purple-ionfi text-14"
                   [routerLink]=" isIonfi ? '/admin/accounts/accounts-activity/ionfi/' + balance.id :
                    (isSegregated ? '/admin/accounts/accounts-activity/segregated/' + balance.id :
                    '/admin/accounts/accounts-activity/fi/' + balance.id)">{{ balance.acc_number }}</a>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{'admin.accounts-activity-table: Name' | translate}}" prop="acc_name">
              <ng-template let-balance="row" ngx-datatable-cell-template>
                {{ balance.acc_name }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{'admin.accounts-activity-table: Type' | translate}}" prop="acc_type">
              <ng-template let-balance="row" ngx-datatable-cell-template>
                {{ balance.acc_type }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="showUpstreamBankColumn" name="{{'admin.accounts-activity-table: Upstream Bank' | translate}}" prop="upstreamBankName">
              <ng-template let-balance="row" ngx-datatable-cell-template>
                <a *ngIf="balance.upstreamBank" [routerLink]="['/admin/upstream-banks', balance.upstream_bank_id]">{{balance.upstreamBank.name}}</a>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{'admin.accounts-activity-table: Current Balance' | translate}}"
                                  prop="current_balance" headerClass="text-right" cellClass="text-right">
              <ng-template let-balance="row" ngx-datatable-cell-template>
                {{ balance.current_balance | currency:balance.acc_currency }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{'admin.accounts-activity-table: Available Balance' | translate}}"
                                  prop="available_balance" headerClass="text-right pr-4"
                                  cellClass="text-right pr-4">
              <ng-template let-balance="row" ngx-datatable-cell-template>
                {{ balance.available_balance | currency:balance.acc_currency }}
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

          <div sidebar-filter>
            <form [formGroup]="filterFormGroup">
              <div class="row">
                <div class="col checkbox-filter">
                  <div class="form-group" *ngIf="showUpstreamBankColumn">
                    <label class="weight-500 text-dark-9-opacity text-16">{{'admin.accounts-activity-table: Upstream Bank'| translate}}</label>
                    <select class="form-control" type="select" formControlName="upstreamBankID">
                      <option [ngValue]="null">{{'admin.update-account-modal: Select' | translate}}</option>
                      <option *ngFor="let upb of (upstreamBanks | async)" [ngValue]="upb.id">{{upb.name}}</option>
                    </select>
                    <fa-icon class="button-icon" [classes]="['fa-fw']" icon="angle-down"></fa-icon>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </shared-table>
      </div>
    </app-body-design>
  </div>
</div>
